import React, {memo} from 'react'
import {BrowserRouter, Route, Switch, StaticRouter} from 'react-router-dom'
import {Authorize, ErrorModal, Logout, NotFoundPage} from 'ac-brand-share-client'
import {ReduxApp} from 'ac-brand-share-client'
import {ToastContainer} from 'ac-brand-ui'

interface Props {
  store: any
  urlPrefix?: string
  locationSSR?: string
  isServer?: boolean
}

const App: React.FC<Props> = memo(({store, urlPrefix, isServer, locationSSR, children}): React.ReactElement => {
  const renderContent = () => {
    if (isServer) {
      return (
        <StaticRouter basename={urlPrefix} location={locationSSR}>
          <div className={'height-100vh'}>
            <ErrorModal />
            <ToastContainer />
            <div className={'height-100vh'}>
              {children}
              <Switch>
                <Route path={'/authorize'} component={Authorize} />
                <Route path={'/logout'} component={Logout} />
              </Switch>
            </div>
          </div>
        </StaticRouter>
      )
    } else {
      const pathname: string = window.location.pathname
      return (
        <BrowserRouter basename={urlPrefix}>
          <div className={'height-100vh'}>
            <ErrorModal />
            <ToastContainer />
            <div className={'height-100vh'}>
              {pathname === '/404' || pathname === (urlPrefix || '') + '/404' ? (
                <NotFoundPage />
              ) : (
                <>
                  {children}
                  <Switch>
                    <Route path={'/authorize'} component={Authorize} />
                    <Route path={'/logout'} component={Logout} />
                  </Switch>
                </>
              )}
            </div>
          </div>
        </BrowserRouter>
      )
    }
  }

  return <ReduxApp store={store}>{renderContent()}</ReduxApp>
})
export default App
