import React from 'react'
import {Switch, Route} from 'react-router-dom'
import LandingContainer from './landing/LandingContainer'
import LegalContainer from './legal/LegalContainer'
import TariffPageContainer from './tariff/TariffPageContainer'
import PaymentFinishPageContainer from './payment/PaymentFinishPageContainer'

import {NotFoundPage} from 'ac-brand-share-client'
import {PaymentFailurePage} from './payment/PaymentFailurePage'
import {PaymentSuccessPage} from './payment/PaymentSuccessPage'
import CalcFeePageContainer from './calcFee/CalcFeePageContainer'

interface MainLayoutProps {}

const MainLayout: React.FC<MainLayoutProps> = ({}): React.ReactElement => {
  return (
    <Switch>
      <Route exact path={'/'} component={LandingContainer} />
      <Route exact path={'/calcfee'} component={CalcFeePageContainer} />
      <Route exact path={'/tariff'} component={TariffPageContainer} />
      <Route exact path={'/payment/finish/:paymentId?'} component={PaymentFinishPageContainer} />
      <Route exact path={'/payment/failure'} component={PaymentFailurePage} />
      <Route exact path={'/payment/success'} component={PaymentSuccessPage} />
      <Route exact path={'/legal/:documentUrl?'} component={LegalContainer} />
      <Route exact path={'/legal/:documentUrl/version/:versionId?'} component={LegalContainer} />
      <Route exact path={'/404'} component={NotFoundPage} />
    </Switch>
  )
}
export default MainLayout
