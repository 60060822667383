import {apiGet, RestApi} from 'ac-brand-share-client'
import {ResponseError} from 'ac-brand-share'
import {CONFIG} from '../../../constants'
import {SubjectDto} from 'ac-brand-auth-share'

class SubjectApi extends RestApi {
  public getError(): ResponseError {
    return this.error
  }

  public async getMyExecutors(): Promise<SubjectDto[]> {
    return await this.sendRequestHandleError(async () => {
      return await apiGet(CONFIG.apiUrl + '/subject/executors/my')
    }, 'Произошла ошибка при получении исполнителей')
  }
}

export {SubjectApi}
