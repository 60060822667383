import React, {useEffect, useMemo, useState} from 'react'
import {PaymentFinishPage} from './PaymentFinishPage'
import {PaymentFinishPageManager} from './services/PaymentFinishPageManager'
import {connectComponent} from 'ac-brand-share-client'
import {ExternalPaymentDto} from 'ac-brand-auth-share'

export interface PaymentFinishPageProps {
  paymentId: string
}

export interface PaymentFinishPageState {
  externalPayment: ExternalPaymentDto
}

export const INITIAL_STATE: PaymentFinishPageState = {
  externalPayment: null,
}

const PaymentFinishPageContainer: React.FC<PaymentFinishPageProps> = (props): React.ReactElement => {
  const [state, setState] = useState<PaymentFinishPageState>({...INITIAL_STATE})

  const paymentFinishManager: PaymentFinishPageManager = useMemo(() => {
    return new PaymentFinishPageManager(setState)
  }, [])

  useEffect(() => {
    paymentFinishManager.initialize(props)
  }, [paymentFinishManager, props])

  useEffect(() => {
    paymentFinishManager.loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const status = state.externalPayment?.status
  return <PaymentFinishPage status={status} />
}

export default connectComponent(
  (state, props) => ({
    paymentId: props.match.params.paymentId,
  }),
  () => ({}),
  PaymentFinishPageContainer,
)
