import React from 'react'

export const MailSuccessSvg: React.ReactNode = (
  <svg width="256" height="256" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.5391 82.5945C44.5391 73.5192 51.8961 66.1621 60.9715 66.1621L91.2418 66.1621C92.1971 66.1621 92.9715 66.9365 92.9715 67.8918C92.9715 68.8471 92.1971 69.6216 91.2418 69.6216L60.9715 69.6216C53.8067 69.6216 47.9985 75.4298 47.9985 82.5945C47.9985 83.5498 47.2241 84.3243 46.2688 84.3243C45.3135 84.3243 44.5391 83.5498 44.5391 82.5945ZM104.647 67.8918C104.647 66.9365 105.422 66.1621 106.377 66.1621L121.512 66.1621C122.467 66.1621 123.242 66.9365 123.242 67.8918C123.242 68.8471 122.467 69.6216 121.512 69.6216L106.377 69.6216C105.422 69.6216 104.647 68.8471 104.647 67.8918ZM232.647 116.865C233.602 116.865 234.377 117.639 234.377 118.595L234.377 129.081C234.377 130.036 233.602 130.811 232.647 130.811C231.692 130.811 230.917 130.036 230.917 129.081L230.917 118.595C230.917 117.639 231.692 116.865 232.647 116.865ZM232.647 137.838C233.602 137.838 234.377 138.612 234.377 139.567L234.377 192C234.377 201.075 227.02 208.432 217.944 208.432L187.674 208.432C186.719 208.432 185.944 207.658 185.944 206.703C185.944 205.747 186.719 204.973 187.674 204.973L217.944 204.973C225.109 204.973 230.917 199.165 230.917 192L230.917 139.567C230.917 138.612 231.692 137.838 232.647 137.838Z"
      fill="#43468F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.7539 87.3513C52.7539 80.6642 58.1749 75.2432 64.862 75.2432H212.754C219.441 75.2432 224.862 80.6641 224.862 87.3513V186.811C224.862 193.498 219.441 198.919 212.754 198.919H99.4566C98.5013 198.919 97.7269 198.144 97.7269 197.189C97.7269 196.234 98.5013 195.459 99.4566 195.459H212.754C217.53 195.459 221.403 191.587 221.403 186.811V87.3513C221.403 82.5748 217.53 78.7026 212.754 78.7026H64.862C60.0855 78.7026 56.2134 82.5748 56.2134 87.3513V129.73C56.2134 130.685 55.4389 131.459 54.4836 131.459C53.5283 131.459 52.7539 130.685 52.7539 129.73V87.3513Z"
      fill="#43468F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.0907 86.6929C63.6933 85.9516 64.7827 85.8392 65.524 86.4418L138.379 145.663L211.234 86.4418C211.975 85.8392 213.064 85.9516 213.667 86.6929C214.27 87.4342 214.157 88.5236 213.416 89.1262L139.47 149.234C138.834 149.751 137.923 149.751 137.288 149.234L63.3419 89.1262C62.6006 88.5236 62.4881 87.4342 63.0907 86.6929Z"
      fill="#43468F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M214.209 173.044C213.692 173.848 212.622 174.08 211.818 173.563L162.089 141.563C161.285 141.046 161.053 139.976 161.57 139.172C162.087 138.369 163.157 138.137 163.961 138.654L213.69 170.654C214.494 171.171 214.726 172.241 214.209 173.044ZM95.7704 152.251C95.2441 151.454 95.4636 150.381 96.2608 149.854L113.207 138.665C114.004 138.139 115.077 138.358 115.603 139.155C116.13 139.953 115.91 141.026 115.113 141.552L98.167 152.741C97.3698 153.267 96.2968 153.048 95.7704 152.251Z"
      fill="#43468F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.717 205.99C63.5392 210.23 79.8025 200.84 84.042 185.018C88.2816 169.196 78.892 152.933 63.0698 148.693C47.2476 144.454 30.9844 153.843 26.7448 169.665C22.5053 185.488 31.8949 201.751 47.717 205.99ZM76.1038 170.77C76.8109 170.101 76.8275 168.981 76.1404 168.292L72.1208 164.26C71.4589 163.596 70.3885 163.58 69.7073 164.224L52.1345 180.84C51.4991 181.441 50.5153 181.473 49.8421 180.915L44.0824 176.14C43.361 175.541 42.2943 175.627 41.677 176.332L37.9113 180.633C37.27 181.366 37.3593 182.483 38.1087 183.104L50.2236 193.148C50.8968 193.706 51.8807 193.674 52.516 193.074L76.1038 170.77Z"
      fill="#3DAA3F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M94.0781 187.707C88.3535 209.072 66.3933 221.75 45.0287 216.026C44.106 215.779 43.5584 214.83 43.8056 213.907C44.0529 212.985 45.0014 212.437 45.9241 212.684C65.4432 217.914 85.5064 206.331 90.7365 186.812C95.9666 167.293 84.3831 147.229 64.8641 141.999C45.345 136.769 25.2818 148.353 20.0517 167.872C18.2878 174.455 18.436 181.094 20.1538 187.207C20.4122 188.127 19.8762 189.082 18.9565 189.34C18.0368 189.599 17.0818 189.063 16.8233 188.143C14.942 181.448 14.7809 174.176 16.7101 166.976C22.4347 145.612 44.3948 132.933 65.7594 138.658C87.124 144.382 99.8027 166.343 94.0781 187.707ZM36.7071 210.851C36.205 211.663 35.1391 211.915 34.3264 211.413C32.3842 210.213 30.5652 208.858 28.8818 207.37C28.1661 206.737 28.099 205.644 28.7318 204.928C29.3646 204.212 30.4577 204.145 31.1734 204.778C32.7108 206.137 34.3717 207.375 36.1447 208.47C36.9574 208.972 37.2092 210.038 36.7071 210.851Z"
      fill="#3DAA3F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M216.934 229.432C217.857 229.679 218.805 229.131 219.053 228.208C219.3 227.286 218.752 226.337 217.83 226.09C216.907 225.843 215.958 226.39 215.711 227.313C215.464 228.236 216.011 229.184 216.934 229.432ZM222.394 229.104C221.653 231.872 218.807 233.515 216.039 232.773C213.271 232.031 211.628 229.186 212.37 226.418C213.111 223.649 215.957 222.007 218.725 222.748C221.493 223.49 223.136 226.336 222.394 229.104Z"
      fill="#A8B2C4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M88.0709 51.269C88.9937 51.5162 89.9421 50.9686 90.1894 50.0459C90.4366 49.1231 89.889 48.1747 88.9663 47.9274C88.0435 47.6802 87.0951 48.2278 86.8478 49.1505C86.6006 50.0733 87.1482 51.0217 88.0709 51.269ZM93.531 50.9413C92.7892 53.7095 89.9438 55.3523 87.1755 54.6106C84.4073 53.8688 82.7645 51.0234 83.5062 48.2551C84.248 45.4869 87.0934 43.8441 89.8617 44.5858C92.6299 45.3276 94.2727 48.173 93.531 50.9413Z"
      fill="#A8B2C4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M220.39 58.4256C217.471 61.7986 211.947 60.3186 211.105 55.9377L210.621 53.4194L208.682 51.741C205.309 48.8212 206.789 43.2979 211.17 42.4557L213.688 41.9716L215.367 40.0328C218.287 36.6599 223.81 38.1398 224.652 42.5208L225.136 45.039L227.075 46.7174C230.448 49.6373 228.968 55.1605 224.587 56.0027L222.069 56.4868L220.39 58.4256ZM215.867 52.4109L216.351 54.9291L218.03 52.9903C218.815 52.0834 219.882 51.4671 221.06 51.2406L223.579 50.7565L221.64 49.0781C220.733 48.293 220.116 47.2255 219.89 46.0476L219.406 43.5293L217.728 45.4681C216.942 46.3751 215.875 46.9914 214.697 47.2178L212.179 47.7019L214.118 49.3803C215.024 50.1654 215.641 51.2329 215.867 52.4109Z"
      fill="#FFC107"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M132.922 33.0866C131.574 34.6433 129.025 33.9603 128.636 31.9383L128.413 30.776L127.518 30.0014C125.961 28.6538 126.644 26.1046 128.666 25.7159L129.829 25.4924L130.603 24.5976C131.951 23.0408 134.5 23.7239 134.889 25.7459L135.112 26.9081L136.007 27.6828C137.564 29.0304 136.881 31.5796 134.859 31.9683L133.696 32.1918L132.922 33.0866ZM130.834 30.3105L131.058 31.4728L131.832 30.578C132.195 30.1594 132.687 29.8749 133.231 29.7704L134.393 29.547L133.498 28.7723C133.08 28.41 132.795 27.9173 132.691 27.3736L132.467 26.2114L131.693 27.1062C131.33 27.5248 130.838 27.8092 130.294 27.9137L129.132 28.1372L130.027 28.9118C130.445 29.2742 130.73 29.7669 130.834 30.3105Z"
      fill="#FFC107"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.569 118.656C27.6782 120.84 24.1015 119.882 23.5562 117.045L23.2427 115.414L21.9872 114.327C19.803 112.436 20.7613 108.86 23.5983 108.314L25.229 108.001L26.3159 106.745C28.2066 104.561 31.7833 105.52 32.3287 108.357L32.6422 109.987L33.8977 111.074C36.0818 112.965 35.1235 116.542 32.2865 117.087L30.6558 117.4L29.569 118.656ZM26.6399 114.761L26.9534 116.392L28.0403 115.136C28.5487 114.549 29.2399 114.15 30.0027 114.003L31.6334 113.69L30.3779 112.603C29.7907 112.094 29.3916 111.403 29.2449 110.64L28.9314 109.01L27.8446 110.265C27.3362 110.852 26.6449 111.252 25.8821 111.398L24.2514 111.712L25.5069 112.799C26.0942 113.307 26.4933 113.998 26.6399 114.761Z"
      fill="#FFC107"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M147.194 233.683C145.303 235.867 141.727 234.909 141.181 232.072L140.868 230.441L139.612 229.354C137.428 227.463 138.386 223.887 141.223 223.341L142.854 223.028L143.941 221.772C145.832 219.588 149.408 220.546 149.954 223.383L150.267 225.014L151.523 226.101C153.707 227.992 152.748 231.568 149.912 232.114L148.281 232.427L147.194 233.683ZM144.265 229.788L144.578 231.419L145.665 230.163C146.174 229.576 146.865 229.177 147.628 229.03L149.258 228.717L148.003 227.63C147.416 227.121 147.017 226.43 146.87 225.667L146.556 224.037L145.47 225.292C144.961 225.879 144.27 226.278 143.507 226.425L141.876 226.739L143.132 227.825C143.719 228.334 144.118 229.025 144.265 229.788Z"
      fill="#FFC107"
    />
  </svg>
)
