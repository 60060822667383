import React from 'react'

export const WarningSvg: React.ReactNode = (
  <svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M41.7539 34.6341C42.7528 34.5562 43.7563 34.5544 44.7555 34.6288L43.2898 54.3311L41.7539 34.6341Z"
      fill="#43468F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.3781 35.177L43.2752 46.6816L44.1313 35.1739C43.5472 35.1482 42.9622 35.1493 42.3781 35.177ZM41.7078 34.0548C42.7361 33.9746 43.769 33.9728 44.7976 34.0493C44.9513 34.0607 45.0942 34.1327 45.1948 34.2495C45.2954 34.3662 45.3454 34.5182 45.334 34.6719L43.8683 54.3742C43.8458 54.6771 43.5937 54.9116 43.2899 54.9122C42.9861 54.9127 42.7332 54.6791 42.7095 54.3762L41.1736 34.6793C41.1617 34.5256 41.2112 34.3735 41.3114 34.2564C41.4115 34.1393 41.5541 34.0668 41.7078 34.0548Z"
      fill="#43468F"
    />
    <path
      d="M44.4532 60.1419C44.4532 59.5 43.9329 58.9797 43.2911 58.9797C42.6492 58.9797 42.1289 59.5 42.1289 60.1419C42.1289 60.7837 42.6492 61.304 43.2911 61.304C43.9329 61.304 44.4532 60.7837 44.4532 60.1419Z"
      fill="#43468F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.2901 60.723C42.9692 60.723 42.709 60.4628 42.709 60.1419C42.709 59.821 42.9692 59.5608 43.2901 59.5608C43.611 59.5608 43.8712 59.821 43.8712 60.1419C43.8712 60.4628 43.611 60.723 43.2901 60.723ZM41.5469 60.1419C41.5469 61.1047 42.3274 61.8851 43.2901 61.8851C44.2529 61.8851 45.0334 61.1047 45.0334 60.1419C45.0334 59.1791 44.2529 58.3986 43.2901 58.3986C42.3274 58.3986 41.5469 59.1791 41.5469 60.1419Z"
      fill="#43468F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.79089 64.8643C8.21452 63.9912 9.45844 63.9912 9.88207 64.8643L10.1256 65.3662L10.6275 65.6097C11.5006 66.0334 11.5006 67.2773 10.6275 67.7009L10.1256 67.9444L9.88207 68.4463C9.45844 69.3195 8.21453 69.3195 7.79089 68.4463L7.54738 67.9444L7.04548 67.7009C6.17234 67.2773 6.17234 66.0334 7.04548 65.6097L7.54738 65.3662L7.79089 64.8643ZM9.07999 65.8735L8.83648 65.3716L8.59297 65.8735C8.47906 66.1083 8.28946 66.2979 8.05468 66.4118L7.55279 66.6553L8.05468 66.8988C8.28946 67.0127 8.47906 67.2023 8.59297 67.4371L8.83648 67.939L9.07999 67.4371C9.1939 67.2023 9.38351 67.0127 9.61828 66.8988L10.1202 66.6553L9.61828 66.4118C9.38351 66.2979 9.1939 66.1083 9.07999 65.8735Z"
      fill="#FFC107"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68.2245 30.8711C68.6481 29.9979 69.892 29.9979 70.3157 30.8711L70.5592 31.373L71.0611 31.6165C71.9342 32.0401 71.9342 33.284 71.0611 33.7077L70.5592 33.9512L70.3157 34.4531C69.892 35.3262 68.6481 35.3262 68.2245 34.4531L67.981 33.9512L67.4791 33.7077C66.6059 33.284 66.6059 32.0401 67.4791 31.6165L67.981 31.373L68.2245 30.8711ZM69.5136 31.8803L69.2701 31.3784L69.0266 31.8803C68.9127 32.115 68.7231 32.3047 68.4883 32.4186L67.9864 32.6621L68.4883 32.9056C68.7231 33.0195 68.9127 33.2091 69.0266 33.4439L69.2701 33.9458L69.5136 33.4439C69.6275 33.2091 69.8171 33.0195 70.0519 32.9056L70.5538 32.6621L70.0519 32.4186C69.8171 32.3047 69.6275 32.115 69.5136 31.8803Z"
      fill="#FFC107"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.744 11.9859C31.1676 11.1128 32.4116 11.1128 32.8352 11.9859L33.0787 12.4878L33.5806 12.7313C34.4537 13.155 34.4537 14.3989 33.5806 14.8225L33.0787 15.066L32.8352 15.5679C32.4116 16.4411 31.1677 16.4411 30.744 15.5679L30.5005 15.066L29.9986 14.8225C29.1255 14.3989 29.1255 13.155 29.9986 12.7313L30.5005 12.4878L30.744 11.9859ZM32.0331 12.9951L31.7896 12.4932L31.5461 12.9951C31.4322 13.2299 31.2426 13.4195 31.0078 13.5334L30.5059 13.7769L31.0078 14.0204C31.2426 14.1344 31.4322 14.324 31.5461 14.5587L31.7896 15.0606L32.0331 14.5587C32.147 14.324 32.3366 14.1344 32.5714 14.0204L33.0733 13.7769L32.5714 13.5334C32.3366 13.4195 32.147 13.2299 32.0331 12.9951Z"
      fill="#FFC107"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.6157 20.9189C40.6286 17.4324 45.6609 17.4324 47.6739 20.9189L70.9483 61.2314C72.9612 64.7179 70.4451 69.076 66.4192 69.076H19.8704C15.8445 69.076 13.3283 64.7179 15.3413 61.2314L38.6157 20.9189ZM46.6674 21.5C45.1018 18.7883 41.1878 18.7883 39.6222 21.5L16.3477 61.8125C14.7821 64.5242 16.7391 67.9138 19.8704 67.9138H66.4192C69.5504 67.9138 71.5074 64.5242 69.9418 61.8125L46.6674 21.5Z"
      fill="#43468F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.5839 18.2246C39.435 13.2945 46.5619 13.2945 49.4129 18.2246C49.5736 18.5024 49.4786 18.8579 49.2008 19.0185C48.923 19.1792 48.5676 19.0842 48.4069 18.8064C46.0033 14.6501 39.9935 14.6501 37.59 18.8064L22.249 45.3343C22.0883 45.6121 21.7329 45.7071 21.4551 45.5464C21.1773 45.3858 21.0823 45.0303 21.2429 44.7525L36.5839 18.2246ZM20.5028 47.1932C20.7806 47.3538 20.8756 47.7093 20.7149 47.9871L17.6467 53.2927C17.486 53.5705 17.1306 53.6655 16.8528 53.5048C16.575 53.3442 16.48 52.9887 16.6407 52.7109L19.7088 47.4053C19.8695 47.1275 20.225 47.0325 20.5028 47.1932ZM73.1646 60.4571C73.4424 60.2965 73.7978 60.3914 73.9585 60.6692C76.8107 65.6013 73.2445 71.7635 67.544 71.7635C67.2231 71.7635 66.9629 71.5033 66.9629 71.1824C66.9629 70.8615 67.2231 70.6013 67.544 70.6013C72.3528 70.6013 75.3549 65.4055 72.9524 61.251C72.7918 60.9732 72.8868 60.6178 73.1646 60.4571Z"
      fill="#43468F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68.595 49.7984C68.6915 49.4923 69.0178 49.3223 69.3239 49.4188L72.6046 50.4525C72.9107 50.549 73.0806 50.8753 72.9842 51.1814C72.8878 51.4875 72.5614 51.6574 72.2553 51.561L68.9746 50.5272C68.6685 50.4308 68.4986 50.1045 68.595 49.7984Z"
      fill="#A8B2C4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M71.2882 48.2436C70.9749 48.174 70.6645 48.3717 70.595 48.685L69.8499 52.0431C69.7804 52.3564 69.978 52.6667 70.2913 52.7362C70.6046 52.8057 70.915 52.6081 70.9845 52.2948L71.7296 48.9367C71.7991 48.6234 71.6015 48.3131 71.2882 48.2436Z"
      fill="#A8B2C4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7635 34.5748C16.0841 34.5888 16.3328 34.86 16.3188 35.1806L16.1694 38.6171C16.1555 38.9377 15.8842 39.1863 15.5636 39.1724C15.243 39.1584 14.9944 38.8872 15.0083 38.5666L15.1577 35.1301C15.1717 34.8095 15.4429 34.5609 15.7635 34.5748Z"
      fill="#A8B2C4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9619 36.7737C17.9479 36.4531 17.6767 36.2044 17.3561 36.2184L13.9196 36.3678C13.599 36.3817 13.3504 36.653 13.3643 36.9736C13.3783 37.2942 13.6495 37.5428 13.9701 37.5289L17.4066 37.3795C17.7272 37.3655 17.9758 37.0943 17.9619 36.7737Z"
      fill="#A8B2C4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.6592 18.0225C60.4227 18.2394 60.0551 18.2234 59.8382 17.9868L56.228 14.0484C56.0112 13.8118 56.0271 13.4442 56.2637 13.2274C56.5003 13.0105 56.8678 13.0265 57.0847 13.2631L60.6949 17.2015C60.9118 17.4381 60.8958 17.8056 60.6592 18.0225Z"
      fill="#A8B2C4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.2627 18.0222C56.4992 18.239 56.8668 18.2231 57.0837 17.9865L60.6939 14.0481C60.9107 13.8115 60.8947 13.4439 60.6582 13.2271C60.4216 13.0102 60.054 13.0262 59.8372 13.2628L56.227 17.2012C56.0101 17.4378 56.0261 17.8053 56.2627 18.0222Z"
      fill="#A8B2C4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.6229 73.5073C41.9435 73.5212 42.1921 73.7924 42.1782 74.113L42.0288 77.5495C42.0148 77.8702 41.7436 78.1188 41.423 78.1048C41.1024 78.0909 40.8538 77.8197 40.8677 77.4991L41.0171 74.0626C41.0311 73.7419 41.3023 73.4933 41.6229 73.5073Z"
      fill="#A8B2C4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.8213 75.7061C43.8073 75.3855 43.5361 75.1369 43.2155 75.1508L39.779 75.3002C39.4584 75.3142 39.2097 75.5854 39.2237 75.906C39.2376 76.2266 39.5088 76.4752 39.8295 76.4613L43.266 76.3119C43.5866 76.2979 43.8352 76.0267 43.8213 75.7061Z"
      fill="#A8B2C4"
    />
  </svg>
)
