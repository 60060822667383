import React, {memo, ReactNode, useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {Logo} from './Logo'
import {Button, ButtonLink, COLORS, Container} from 'ac-brand-ui'
import {CONFIG} from '../../constants'
import {
  AuthSubsystemIdEnum,
  CustomerModuleEnum,
  CustomerPrivilegeEnum,
  ExecutorModuleEnum,
  SubscriptionDto,
  UserDto,
} from 'ac-brand-share'
import {getAuthPageUrl} from '../../utils/getAuthPageUrl'
import {
  connectComponent,
  introActions,
  HeaderMenu as HeaderMenuCommon,
  FeedbackFormContainer,
  authLogout,
  errorActions,
} from 'ac-brand-share-client'
import {HeaderMenu} from './menu/HeaderMenu'

interface HeaderProps {
  user: UserDto
  subscription: SubscriptionDto
  enabledModules?: (ExecutorModuleEnum | CustomerModuleEnum)[] | undefined
  workspacePrivileges: CustomerPrivilegeEnum[] | undefined

  onChangeHeaderHeight?: (height: number) => void
  onTry?: (e: any) => void
  errorActions: any
}

const Header: React.FC<HeaderProps> = memo(
  ({
    user,
    subscription,
    enabledModules,
    workspacePrivileges,
    onChangeHeaderHeight,
    onTry,
    errorActions,
  }): React.ReactElement => {
    const [headerHeight, setHeaderHeight] = useState<number>(48)
    const mainHeaderRef = useRef<HTMLDivElement>(null)

    const [isOpenFeedbackForm, setIsOpenFeedbackForm] = useState<boolean>(false)

    useEffect(() => {
      setTimeout(() => {
        if (mainHeaderRef.current?.scrollHeight) {
          if (onChangeHeaderHeight) {
            onChangeHeaderHeight(mainHeaderRef.current?.scrollHeight)
          }
          setHeaderHeight(mainHeaderRef.current?.scrollHeight)
        }
      }, 100)
    }, [onChangeHeaderHeight])

    const renderFeedbackForm = (): ReactNode => {
      if (isOpenFeedbackForm) {
        return (
          <FeedbackFormContainer
            authSubsystem={AuthSubsystemIdEnum.CRM_EXECUTOR}
            onClose={() => {
              setIsOpenFeedbackForm(false)
            }}
          />
        )
      }
    }

    const renderAuthButton = (): ReactNode => {
      if (user) {
        return (
          <div className={'d-flex align-items-center'}>
            <ButtonLink
              href={CONFIG.crmSiteWeb + '/tariff'}
              size={'sm'}
              style={'text'}
              isCircleButton
              className={'header-site-button-price mr-x4'}>
              Цены
            </ButtonLink>
            <ButtonLink
              href={CONFIG.crmSiteWeb + '/calcfee'}
              size={'sm'}
              style={'text'}
              isCircleButton
              className={'header-site-button-price mr-x4'}>
              Калькулятор пошлин
            </ButtonLink>
            <ButtonLink href={CONFIG.executorCrmWeb} isCircleButton style={'outline'} size={'sm'} className={'mr-x8'}>
              Войти
            </ButtonLink>
            <HeaderMenuCommon
              id={'header-button-menu'}
              authSubsystem={AuthSubsystemIdEnum.CRM_EXECUTOR}
              imageUrl={
                user?.avatarFileName
                  ? CONFIG.cdnUrl + '/' + user.avatarFileName
                  : CONFIG.executorCrmWeb + '/img/common/' + 'avatar-placeholder.svg'
              }
              subscription={subscription}
              enabledModules={enabledModules}
              workspacePrivileges={workspacePrivileges}
              onSupport={() => {
                setIsOpenFeedbackForm(!isOpenFeedbackForm)
              }}
              iconColor={COLORS.gray600}
              headerHeight={headerHeight}
              onLogout={async () => {
                await authLogout(errorActions.setError)
                window.location.replace(CONFIG.executorAuthWeb + '/signin')
              }}
            />
            {renderFeedbackForm()}
          </div>
        )
      } else {
        return (
          <div className={'header-site-buttons'}>
            <ButtonLink
              href={CONFIG.crmSiteWeb + '/tariff'}
              size={'sm'}
              style={'text'}
              isCircleButton
              className={'mr-x4'}>
              Цены
            </ButtonLink>
            <ButtonLink
              href={CONFIG.crmSiteWeb + '/calcfee'}
              size={'sm'}
              style={'text'}
              isCircleButton
              className={'mr-x4'}>
              Калькулятор пошлин
            </ButtonLink>
            <ButtonLink
              href={getAuthPageUrl()}
              isCircleButton
              style={'outline'}
              size={'sm'}
              className={onTry ? 'mr-x4' : 'mr-x0'}>
              Войти
            </ButtonLink>
            {onTry && (
              <Button isCircleButton size={'sm'} onClick={onTry}>
                Попробовать
              </Button>
            )}
          </div>
        )
      }
    }

    const renderMenu = (): React.ReactNode => {
      if (!user) {
        return (
          <div className={'header-site-menu'}>
            <HeaderMenu headerHeight={headerHeight} hrefAuth={getAuthPageUrl()} onTry={onTry} />
          </div>
        )
      }
    }

    return (
      <header ref={mainHeaderRef} className={'header-site'}>
        <Container>
          <div className={'header-site-content'}>
            <Link to={'/'}>{Logo}</Link>
            {renderAuthButton()}
            {renderMenu()}
          </div>
        </Container>
      </header>
    )
  },
)

export default connectComponent(
  (state) => ({
    user: state.auth?.user,
    subscription: state.auth?.subscription,
    enabledModules: state.auth?.enabledModules,
    workspacePrivileges: state.auth?.workspacePrivileges,
  }),
  (dispatch: any) => ({
    introActions: introActions(dispatch),
    errorActions: errorActions(dispatch),
  }),
  Header,
)
