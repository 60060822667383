import React, {memo} from 'react'
import {
  isEven,
  numberWithSpaces,
  StringNumberMap,
  SubscriptionPeriodDto,
  TariffDto,
  TariffFeatureDto,
  TariffModuleDto,
} from 'ac-brand-share'
import {Button, COLORS, Container, Icon, Input, ThemeColor} from 'ac-brand-ui'
import {mdiCheckboxMarkedCircle, mdiCloseCircle} from '@mdi/js'
import classNames from 'classnames'
import {RUB, useIsClient} from 'ac-brand-share-client'

interface TariffPlanOptionsProps {
  tariffs: TariffDto[]
  selectedPeriod: SubscriptionPeriodDto
  selectedTariff: TariffDto | undefined
  onSetTariff: (newTariff: TariffDto) => void
  headerHeight: number

  personalFeatureCount: StringNumberMap
  onSetPersonalFeatureCount: (featureId: string, count: number) => void

  onClickButton: (newTariff: TariffDto) => Promise<void>
}

const TariffPlanOptions: React.FC<TariffPlanOptionsProps> = memo(
  ({
    tariffs,
    selectedPeriod,
    selectedTariff,
    onSetTariff,
    headerHeight,
    personalFeatureCount,
    onSetPersonalFeatureCount,
    onClickButton,
  }) => {
    const [isClient] = useIsClient()

    const renderTheadTop = (): React.ReactNode => {
      const thListPc: React.ReactElement[] = []
      const thListM: React.ReactElement[] = []

      for (let i = 0; i < (tariffs || []).length; i++) {
        const item = tariffs[i]
        thListPc.push(
          <th
            key={item.tariffId}
            className={classNames(
              'tariff-thead-pc-item',
              i === tariffs.length - 1 ? 'border-top-right-radius-x2' : '',
            )}>
            {item.name}
          </th>,
        )
        if (isClient) {
          thListM.push(
            <th
              key={item.tariffId}
              onClick={() => {
                onSetTariff(item)
              }}
              className={classNames(
                'tariff-thead-m-item text-center',
                i === 0 ? 'border-top-left-radius-x2' : undefined,
                i === tariffs.length - 1 ? 'border-top-right-radius-x2' : undefined,
                item.tariffId === selectedTariff?.tariffId ? 'active' : undefined,
              )}>
              {item.shortName}
            </th>,
          )
        }
      }

      return (
        <thead id={'tariffTheadTop'} style={{top: headerHeight}} className={'thead-top'}>
          <tr className={'tariff-thead-pc'}>
            <th className={'border-top-left-radius-x2'}></th>
            {thListPc}
          </tr>
          {isClient && <tr className={'tariff-thead-m'}>{thListM}</tr>}
        </thead>
      )
    }

    const renderTdFeatureValue = (
      key: string,
      featureByTariffItem: TariffFeatureDto,
      isPersonal: boolean,
    ): React.ReactElement => {
      let rtdListValue: React.ReactElement
      if (featureByTariffItem.isCount) {
        if (isPersonal) {
          return (
            <td key={key} className={classNames('td-feature-input')}>
              <div className={'max-w-[128px]'}>
                <Input
                  type={'number'}
                  className={'mb-0'}
                  value={personalFeatureCount[featureByTariffItem.featureId]}
                  onChange={(value) => {
                    onSetPersonalFeatureCount(featureByTariffItem.featureId, value)
                  }}
                  size={'s'}
                  required
                />
              </div>
            </td>
          )
        } else {
          if (featureByTariffItem.isEnabled) {
            rtdListValue = (
              <>{featureByTariffItem.showText ? featureByTariffItem.showText : featureByTariffItem.count}</>
            )
          } else {
            rtdListValue = <Icon path={mdiCloseCircle} size={'20px'} color={COLORS.gray300} />
          }

          return (
            <td key={key} className={'td-feature-value'}>
              {rtdListValue}
            </td>
          )
        }
      } else {
        if (featureByTariffItem.showText) {
          rtdListValue = <>{featureByTariffItem.showText}</>
        } else {
          if (featureByTariffItem.isEnabled) {
            rtdListValue = <Icon path={mdiCheckboxMarkedCircle} size={'20px'} color={COLORS.green} />
          } else {
            rtdListValue = <Icon path={mdiCloseCircle} size={'20px'} color={COLORS.gray300} />
          }
        }
        return (
          <td key={key} className={'td-feature-value'}>
            {rtdListValue}
          </td>
        )
      }
    }

    const renderTbody = (): React.ReactNode => {
      const tariffModules: TariffModuleDto[] = tariffs[0]?.tariffModules || []
      const trList: React.ReactNode[] = []
      for (const tariffModule of tariffModules || []) {
        trList.push(
          <tr key={'module-' + tariffModule.moduleId}>
            <td colSpan={tariffs?.length + 1} className={'module-name'}>
              <div className={'d-flex align-items-center justify-content-between'}>
                <p className={'mb-0 font-weight-medium mr-x4'}>{tariffModule.name}</p>
              </div>
            </td>
          </tr>,
        )
        let n: number = 0
        for (const feature of tariffModule.tariffFeatures || []) {
          const tdList: React.ReactElement[] = []

          for (let tariffItem of tariffs || []) {
            const tariffModuleByTariffItem: TariffModuleDto | undefined = tariffItem.tariffModules.find(
              (tm) => tm.moduleId === tariffModule.moduleId,
            )
            if (tariffModuleByTariffItem) {
              const featureByTariffItem = tariffModuleByTariffItem.tariffFeatures.find(
                (f) => f.featureId === feature.featureId,
              )
              if (featureByTariffItem) {
                const isPersonal = tariffItem.isPersonal
                tdList.push(
                  renderTdFeatureValue(
                    tariffItem.tariffId + '&' + tariffModuleByTariffItem.moduleId + '&' + featureByTariffItem.featureId,
                    featureByTariffItem,
                    isPersonal,
                  ),
                )
              }
            }
          }

          trList.push(
            <tr
              key={tariffModule.moduleId + feature.featureId}
              className={classNames(
                'tariff-body-tr-pc',
                n === tariffModule.tariffFeatures.length - 1 ? 'border-bottom' : '',
                !isEven(n) ? 'bg-gray-100' : 'bg-white',
              )}>
              <td>
                <div className={'d-flex align-items-start justify-content-between'}>
                  <p className={'mb-0 mr-x2'}>{feature.name}</p>
                </div>
              </td>
              {tdList}
            </tr>,
          )

          if (isClient && selectedTariff) {
            let tdSelectedFeature: React.ReactElement = <></>
            const tariffModuleBySelectedTariff = selectedTariff.tariffModules.find(
              (tm) => tm.moduleId === tariffModule.moduleId,
            )
            if (tariffModuleBySelectedTariff) {
              const featureBySelectedTariff = tariffModuleBySelectedTariff.tariffFeatures.find(
                (f) => f.featureId === feature.featureId,
              )

              if (featureBySelectedTariff) {
                const isPersonal = selectedTariff.isPersonal
                tdSelectedFeature = renderTdFeatureValue(
                  selectedTariff.tariffId +
                    '&' +
                    tariffModuleBySelectedTariff.moduleId +
                    '&' +
                    featureBySelectedTariff.featureId,
                  featureBySelectedTariff,
                  isPersonal,
                )
              }
            }

            trList.push(
              <tr
                key={'m-' + tariffModule.moduleId + feature.featureId}
                className={classNames(
                  'tariff-body-tr-m',
                  n === tariffModule.tariffFeatures.length - 1 ? 'border-bottom' : '',
                  !isEven(n) ? 'bg-gray-100' : 'bg-white',
                )}>
                <td className={'py-x3 px-x4'} colSpan={(tariffs || []).length - 1}>
                  <div className={'d-flex align-items-center justify-content-between'}>
                    <p className={'mb-0 mr-x4'}>{feature.name}</p>
                  </div>
                </td>
                {tdSelectedFeature}
              </tr>,
            )
          }
          n++
        }
      }

      return <tbody>{trList}</tbody>
    }

    const renderTheadBottom = (): React.ReactNode => {
      const thListPc: React.ReactElement[] = []

      for (let i = 0; i < (tariffs || []).length; i++) {
        const item = tariffs[i]
        const prices = item.prices || {}

        const isFree: boolean = prices[selectedPeriod.subscriptionPeriodId]?.price === 0
        const isPersonal: boolean = item.isPersonal

        let buttonStyle: any = isFree ? 'outline' : undefined
        let buttonColor: ThemeColor = isPersonal || isFree ? 'primary' : 'success'
        let buttonText: string = isFree ? 'Выбрать' : isPersonal ? 'Отправить' : 'Купить'

        const priceMap = prices[selectedPeriod.subscriptionPeriodId as string]
        let price = priceMap?.price
        let salePrice = !isFree && priceMap?.salePrice > 0 ? priceMap?.salePrice : undefined
        let priceResult = salePrice > 0 ? salePrice : price

        thListPc.push(
          <th
            key={item.tariffId}
            className={
              'py-x3 px-x6 vertical-align-bottom ' + (i === tariffs.length - 1 ? 'border-bottom-right-radius-x2' : '')
            }>
            {!isPersonal && salePrice > 0 && (
              <p className={'text-fs-14-16 text-gray-500 text-decoration-line-through font-normal mb-x1'}>
                {numberWithSpaces(price)} {RUB}
              </p>
            )}
            <p className={'mb-x2'}>
              {isPersonal ? (
                <>по запросу</>
              ) : (
                <>
                  <span className={'h4 text-primary'}>
                    {numberWithSpaces(priceResult)} {RUB}
                  </span>
                  <span className={'text-fs-14-16 text-gray-500 font-normal'}>/{selectedPeriod.name}</span>
                </>
              )}
            </p>
            <Button
              color={buttonColor}
              size={'sm'}
              style={buttonStyle}
              className={''}
              onClick={() => {
                onClickButton(item)
              }}>
              {buttonText}
            </Button>
          </th>,
        )
      }
      let thM: React.ReactElement = <></>
      if (isClient && selectedTariff) {
        const prices = selectedTariff.prices || {}
        const isFree: boolean = prices[selectedPeriod.subscriptionPeriodId]?.price === 0
        const isPersonal: boolean = selectedTariff.isPersonal

        let buttonStyle: any = isFree ? 'outline' : undefined
        let buttonColor: ThemeColor = isPersonal || isFree ? 'primary' : 'success'
        let buttonText: string = isFree ? 'Выбрать' : isPersonal ? 'Отправить' : 'Купить'

        const priceMap = prices[selectedPeriod.subscriptionPeriodId as string]
        let price = priceMap?.price
        let salePrice = !isFree && priceMap?.salePrice > 0 ? priceMap?.salePrice : undefined
        let priceResult = salePrice > 0 ? salePrice : price

        thM = (
          <th colSpan={(tariffs || []).length} className={'py-x3 border-bottom-left-radius-x2'}>
            <div className={'d-flex flex-row justify-content-between align-items-center w-100 '}>
              <div className={'d-flex flex-column'}>
                {!isPersonal && salePrice > 0 && (
                  <p className={'text-fs-14-16 text-gray-500 text-decoration-line-through font-normal mb-x1'}>
                    {numberWithSpaces(price)} {RUB}
                  </p>
                )}
                <p className={'mb-0 mr-x2'}>
                  {isPersonal ? (
                    <>по запросу</>
                  ) : (
                    <>
                      <span className={'h4 text-primary'}>
                        {numberWithSpaces(priceResult)} {RUB}
                      </span>
                      <span className={'text-fs-14-16 text-gray-500 font-normal'}>/{selectedPeriod.name}</span>
                    </>
                  )}
                </p>
              </div>
              <Button
                color={buttonColor}
                size={'sm'}
                style={buttonStyle}
                className={''}
                onClick={() => {
                  onClickButton(selectedTariff)
                }}>
                {buttonText}
              </Button>
            </div>
          </th>
        )
      }

      return (
        <thead style={{bottom: 0}} className={'thead-bottom'}>
          <tr className={'tariff-thead-b-pc'}>
            <th key={'pc-empty'} className={'cell-hide border-bottom-left-radius-x2'}></th>
            {thListPc}
          </tr>
          {isClient && <tr className={'tariff-thead-b-m'}>{thM}</tr>}
        </thead>
      )
    }

    const renderTablePlanOptions = (): React.ReactNode => {
      if (tariffs) {
        return (
          <table className={'table-plan-options w-100'}>
            {renderTheadTop()}
            {renderTbody()}
            {renderTheadBottom()}
          </table>
        )
      }
    }

    return (
      <div className={'tariff-plan-options'} id={'plan-options'}>
        <Container>
          <h2 className={'tariff-title mb-x8'}>Опции планов</h2>
          {renderTablePlanOptions()}
        </Container>
      </div>
    )
  },
)
export default TariffPlanOptions
