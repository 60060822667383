import React from 'react'

export const PaymentFailureSVG: React.ReactNode = (
  <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M97.1038 80.5444C99.4269 80.3633 101.76 80.3591 104.084 80.532L100.676 126.351L97.1038 80.5444Z"
      fill="#43468F"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M98.5577 81.8071L100.644 108.562L102.635 81.7998C101.276 81.7401 99.9159 81.7425 98.5577 81.8071ZM96.9987 79.1972C99.3902 79.0107 101.792 79.0065 104.184 79.1844C104.542 79.211 104.874 79.3785 105.108 79.65C105.342 79.9215 105.458 80.2749 105.432 80.6323L102.023 126.452C101.971 127.156 101.385 127.701 100.678 127.703C99.9716 127.704 99.3833 127.161 99.3284 126.456L95.7565 80.6495C95.7287 80.2922 95.8439 79.9385 96.0769 79.6661C96.3098 79.3937 96.6414 79.2251 96.9987 79.1972Z"
      fill="#43468F"
    />
    <path
      d="M103.378 139.865C103.378 138.372 102.168 137.162 100.676 137.162C99.1829 137.162 97.9729 138.372 97.9729 139.865C97.9729 141.358 99.1829 142.568 100.676 142.568C102.168 142.568 103.378 141.358 103.378 139.865Z"
      fill="#43468F"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M100.676 141.216C99.9293 141.216 99.3243 140.611 99.3243 139.865C99.3243 139.119 99.9293 138.514 100.676 138.514C101.422 138.514 102.027 139.119 102.027 139.865C102.027 140.611 101.422 141.216 100.676 141.216ZM96.6216 139.865C96.6216 142.104 98.4366 143.919 100.676 143.919C102.915 143.919 104.73 142.104 104.73 139.865C104.73 137.626 102.915 135.811 100.676 135.811C98.4366 135.811 96.6216 137.626 96.6216 139.865Z"
      fill="#43468F"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.1212 150.847C19.1064 148.817 21.9992 148.817 22.9844 150.847L23.5507 152.014L24.7179 152.581C26.7485 153.566 26.7485 156.459 24.7179 157.444L23.5507 158.01L22.9844 159.178C21.9992 161.208 19.1064 161.208 18.1212 159.178L17.5549 158.01L16.3877 157.444C14.3571 156.459 14.3571 153.566 16.3877 152.581L17.5549 152.014L18.1212 150.847ZM21.1191 153.194L20.5528 152.027L19.9865 153.194C19.7216 153.74 19.2806 154.181 18.7346 154.446L17.5674 155.012L18.7346 155.579C19.2806 155.844 19.7216 156.285 19.9865 156.831L20.5528 157.998L21.1191 156.831C21.384 156.285 21.8249 155.844 22.3709 155.579L23.5381 155.012L22.3709 154.446C21.8249 154.181 21.384 153.74 21.1191 153.194Z"
      fill="#FFC107"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M158.662 71.7932C159.647 69.7626 162.54 69.7626 163.525 71.7932L164.091 72.9604L165.258 73.5267C167.289 74.5119 167.289 77.4047 165.258 78.3899L164.091 78.9562L163.525 80.1234C162.54 82.154 159.647 82.154 158.662 80.1234L158.095 78.9562L156.928 78.3899C154.898 77.4047 154.898 74.5119 156.928 73.5267L158.095 72.9604L158.662 71.7932ZM161.66 74.1402L161.093 72.973L160.527 74.1402C160.262 74.6861 159.821 75.1271 159.275 75.392L158.108 75.9583L159.275 76.5246C159.821 76.7895 160.262 77.2305 160.527 77.7764L161.093 78.9436L161.66 77.7764C161.925 77.2305 162.365 76.7895 162.911 76.5246L164.079 75.9583L162.911 75.392C162.365 75.1271 161.925 74.6861 161.66 74.1402Z"
      fill="#FFC107"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M71.4996 27.8743C72.4848 25.8437 75.3776 25.8437 76.3628 27.8743L76.9291 29.0415L78.0963 29.6078C80.1269 30.593 80.1269 33.4858 78.0963 34.471L76.9291 35.0373L76.3628 36.2045C75.3776 38.2351 72.4848 38.2351 71.4996 36.2045L70.9333 35.0373L69.7661 34.471C67.7355 33.4858 67.7355 30.593 69.7661 29.6078L70.9333 29.0415L71.4996 27.8743ZM74.4975 30.2213L73.9312 29.0541L73.3649 30.2213C73.1 30.7673 72.659 31.2082 72.1131 31.4731L70.9459 32.0394L72.1131 32.6057C72.659 32.8706 73.1 33.3116 73.3649 33.8576L73.9312 35.0248L74.4975 33.8576C74.7624 33.3116 75.2034 32.8706 75.7493 32.6057L76.9165 32.0394L75.7493 31.4731C75.2034 31.2082 74.7624 30.7673 74.4975 30.2213Z"
      fill="#FFC107"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M89.8051 48.6487C94.4864 40.5405 106.189 40.5405 110.871 48.6486L164.997 142.399C169.678 150.507 163.827 160.642 154.464 160.642H46.2113C36.8489 160.642 30.9973 150.507 35.6786 142.399L89.8051 48.6487ZM108.53 50C104.889 43.6937 95.7867 43.6937 92.1458 50L38.0192 143.75C34.3782 150.056 38.9294 157.939 46.2113 157.939H154.464C161.746 157.939 166.298 150.056 162.657 143.75L108.53 50Z"
      fill="#43468F"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M85.0746 42.3829C91.705 30.9175 108.279 30.9174 114.91 42.3829C115.283 43.029 115.062 43.8556 114.416 44.2292C113.77 44.6028 112.944 44.382 112.57 43.7359C106.98 34.07 93.004 34.0701 87.4142 43.7359L51.7376 105.429C51.364 106.075 50.5373 106.296 49.8913 105.922C49.2452 105.548 49.0243 104.722 49.3979 104.076L85.0746 42.3829ZM47.6766 109.752C48.3227 110.125 48.5435 110.952 48.1699 111.598L41.0346 123.937C40.661 124.583 39.8343 124.803 39.1883 124.43C38.5422 124.056 38.3213 123.23 38.6949 122.584L45.8303 110.245C46.2039 109.599 47.0305 109.378 47.6766 109.752ZM170.146 140.598C170.792 140.224 171.619 140.445 171.992 141.091C178.625 152.561 170.332 166.892 157.075 166.892C156.328 166.892 155.723 166.287 155.723 165.541C155.723 164.794 156.328 164.189 157.075 164.189C168.258 164.189 175.24 152.106 169.653 142.444C169.279 141.798 169.5 140.972 170.146 140.598Z"
      fill="#43468F"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M159.522 115.81C159.746 115.098 160.505 114.703 161.217 114.927L168.847 117.331C169.558 117.556 169.954 118.315 169.729 119.026C169.505 119.738 168.746 120.133 168.034 119.909L160.405 117.505C159.693 117.281 159.298 116.522 159.522 115.81Z"
      fill="#A8B2C4"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M165.785 112.194C165.056 112.033 164.334 112.492 164.173 113.221L162.44 121.03C162.278 121.759 162.738 122.481 163.466 122.642C164.195 122.804 164.917 122.344 165.078 121.616L166.811 113.806C166.973 113.078 166.513 112.356 165.785 112.194Z"
      fill="#A8B2C4"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M36.6612 80.4067C37.4069 80.4391 37.985 81.0698 37.9526 81.8155L37.6051 89.8073C37.5727 90.553 36.942 91.1311 36.1964 91.0987C35.4507 91.0663 34.8726 90.4356 34.905 89.6899L35.2525 81.6981C35.2849 80.9524 35.9156 80.3743 36.6612 80.4067Z"
      fill="#A8B2C4"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M41.7749 85.5203C41.7425 84.7747 41.1118 84.1965 40.3661 84.2289L32.3743 84.5764C31.6286 84.6088 31.0505 85.2395 31.0829 85.9852C31.1153 86.7308 31.746 87.3089 32.4917 87.2765L40.4835 86.9291C41.2292 86.8966 41.8073 86.2659 41.7749 85.5203Z"
      fill="#A8B2C4"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M141.066 41.9128C140.516 42.4171 139.661 42.3799 139.157 41.8298L130.761 32.6707C130.257 32.1205 130.294 31.2657 130.844 30.7614C131.394 30.2571 132.249 30.2942 132.753 30.8444L141.149 40.0035C141.654 40.5537 141.616 41.4085 141.066 41.9128Z"
      fill="#A8B2C4"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M130.845 41.9121C131.395 42.4164 132.25 42.3792 132.754 41.8291L141.15 32.6699C141.654 32.1198 141.617 31.265 141.067 30.7606C140.517 30.2563 139.662 30.2935 139.158 30.8437L130.762 40.0028C130.258 40.5529 130.295 41.4078 130.845 41.9121Z"
      fill="#A8B2C4"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M96.7965 170.947C97.5421 170.98 98.1203 171.61 98.0879 172.356L97.7404 180.348C97.708 181.093 97.0772 181.672 96.3316 181.639C95.586 181.607 95.0078 180.976 95.0402 180.23L95.3877 172.239C95.4201 171.493 96.0509 170.915 96.7965 170.947Z"
      fill="#A8B2C4"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M101.91 176.061C101.877 175.315 101.247 174.737 100.501 174.769L92.5093 175.117C91.7637 175.149 91.1855 175.78 91.2179 176.526C91.2503 177.271 91.8811 177.849 92.6267 177.817L100.619 177.47C101.364 177.437 101.942 176.806 101.91 176.061Z"
      fill="#A8B2C4"
    />
  </svg>
)
