import React, {useEffect} from 'react'
import {connectComponent, errorActions, isClient} from 'ac-brand-share-client'
import {Legal} from './Legal'
import {DocumentDto} from 'ac-brand-share'
import {LegalService} from './services/LegalService'
import {useHistory} from 'react-router-dom'

interface LegalContainerProps {
  errorActions: any
  documentUrl: string
  versionId: string
  documents: DocumentDto[]
}

export const LegalCTX = React.createContext(null)

const LegalContainer: React.FC<LegalContainerProps> = ({
  errorActions,
  documentUrl,
  versionId,
  documents,
}): React.ReactElement => {
  const legalService: LegalService = new LegalService(documents, errorActions.setError)

  const location = useHistory()

  useEffect(() => {
    if (isClient) {
      window.scrollTo(0, 0)
    }
  }, [location.length])

  return (
    <LegalCTX.Provider value={legalService}>
      <Legal versionId={versionId} documents={documents} document={legalService.getDocument(documentUrl)} />
    </LegalCTX.Provider>
  )
}

export default connectComponent(
  (state, props) => ({
    documentUrl: props.match.params.documentUrl,
    versionId: props.match.params.versionId,
    documents: state.legal.documents,
  }),
  (dispatch: any) => ({
    errorActions: errorActions(dispatch),
  }),
  LegalContainer,
)
