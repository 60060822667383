import {CalcFeeState} from './types/CalcFeeState'
import {BaseAction} from '../utils/BaseAction'
import {CalcFeeActionsTypes} from './types'

const INITIAL_STATE: CalcFeeState = {
  steps: [],
  discountCategories: [],
}

export default function calcFeeReducers(state: CalcFeeState = INITIAL_STATE, action: BaseAction): CalcFeeState {
  switch (action.type) {
    case CalcFeeActionsTypes.SET_STEPS_DISCOUNT_CATEGORIES:
      return {
        ...state,
        steps: action.payload.steps,
        discountCategories: action.payload.discountCategories,
      }

    default: {
      return {
        ...state,
      }
    }
  }
}
