import {apiGet, RestApi} from 'ac-brand-share-client'
import {AuthSubsystemIdEnum, ResponseError, SubscriptionDBDto, TariffAndPeriodsDto, WalletDto} from 'ac-brand-share'
import {CONFIG} from '../../../constants'

class TariffApi extends RestApi {
  public getError(): ResponseError {
    return this.error
  }

  public async findTariffsAndPeriods(): Promise<TariffAndPeriodsDto> {
    return await this.sendRequestHandleError(async () => {
      return await apiGet(CONFIG.apiUrl + '/billing/tariffs-and-periods/' + AuthSubsystemIdEnum.CRM_EXECUTOR)
    }, 'Произошла ошибка при получении тарифов')
  }

  public async findMyWallet(): Promise<WalletDto> {
    return await this.sendRequestHandleError(async () => {
      return await apiGet(CONFIG.apiUrl + '/billing/wallet/')
    }, 'Произошла ошибка при получении бонусов')
  }

  public async createDemo(): Promise<void> {
    await this.sendRequestHandleError(async () => {
      await apiGet(CONFIG.apiUrl + '/subscription/create-demo')
    }, 'Произошла ошибка при получении пробного доступа')
  }

  public async isHasDemo(): Promise<boolean> {
    return await this.sendRequestHandleError(async () => {
      return await apiGet(CONFIG.apiUrl + '/subscription/is-has-demo')
    }, 'Произошла ошибка при получении информации о пробной подписке')
  }

  public async findNextSubscription(): Promise<SubscriptionDBDto> {
    return await this.sendRequestHandleError(async () => {
      return await apiGet(CONFIG.apiUrl + '/subscription/find-next')
    }, 'Произошла ошибка при получении информации о подписке')
  }
}

export {TariffApi}
