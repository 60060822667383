import React, {memo, ReactNode, useContext, useMemo} from 'react'
import {DocumentDto} from 'ac-brand-share'
import {Card, Container, Divider} from 'ac-brand-ui'
import {Breadcrumbs} from 'ac-brand-ui'
import {NavLink, Link} from 'react-router-dom'
import {LegalService} from './services/LegalService'
import {LegalCTX} from './LegalContainer'
import moment from 'moment'
import {Footer} from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import {BreadcrumbDTO} from 'ac-brand-ui'
import {HtmlView} from 'ac-brand-share-client'

declare var URL_PREFIX: string

interface LegalProps {
  documents: DocumentDto[]
  document: DocumentDto
  versionId: string
}

const Legal: React.FC<LegalProps> = memo(({documents, document, versionId}): React.ReactElement => {
  const legalService: LegalService = useContext(LegalCTX)

  const getVersion = useMemo(() => {
    if (document) {
      return legalService.getVersion(document, versionId)
    }
  }, [document, legalService, versionId])

  const renderBreadCrumb = () => {
    const links: BreadcrumbDTO[] = [
      {breadcrumbId: 'main', link: URL_PREFIX + '/', name: 'Главная', type: 'text'},
      {breadcrumbId: 'legal', link: URL_PREFIX + '/legal', name: 'Юридические документы', type: 'text'},
    ]

    if (document) {
      links.push({
        breadcrumbId: 'document',
        link: URL_PREFIX + '/legal/' + document.url,
        name: document.name,
        type: 'text',
      })
    }
    return (
      <Breadcrumbs
        className={'mb-x4 bg-primary flex-wrap'}
        activeLink={URL_PREFIX + '/legal/' + (document ? document.url : '')}
        links={links}
      />
    )
  }

  const renderMenu = (className: string) => {
    const links: ReactNode[] = documents.map((d) => (
      <NavLink
        activeClassName={'active'}
        key={d.documentId}
        className={'item-text subtitle1'}
        to={{
          pathname: '/legal/' + d.url,
        }}
        replace={true}>
        {d.name}
      </NavLink>
    ))

    return (
      <Card className={'legal-menu ' + className}>
        <h3 className={'mb-x6'}>Юридические документы</h3>
        {links}
      </Card>
    )
  }

  const renderDocument = () => {
    const versions: ReactNode[] = document.versions.map((v, i) => (
      <Link
        key={v.documentVersionId}
        className={'version'}
        to={{pathname: '/legal/' + document.url + '/version/' + v.documentVersionId}}>
        {v.name + ' от ' + moment(v.publicDate).format('DD.MM.YYYY') + (i === 0 ? ' (Текущая)' : '')}
      </Link>
    ))
    return (
      <div className={'legal-document'}>
        <HtmlView className={'document-text subtitle1'} html={getVersion.content} />
        {versions}
      </div>
    )
  }

  const renderDocumentList = () => {
    const documentItems: ReactNode[] = documents.map((d) => (
      <Link key={d.documentId} className={'document-link'} to={{pathname: '/legal/' + d.url}}>
        {d.name}
      </Link>
    ))
    return (
      <div className={'list container'}>
        {renderBreadCrumb()}
        <h1 className={'text-primary'}>Юридические документы</h1>
        <Divider className={'mb-x6'} />
        {documentItems}
      </div>
    )
  }

  const renderDocumentContent = () => {
    return (
      <div className={'document-container'}>
        <div className={'left-col'}>
          {renderBreadCrumb()}
          <h1 className={'text-primary document-name'}>{document.name}</h1>
          <Divider className={'mb-x6'} />
          {renderMenu('xs')}
          {renderDocument()}
        </div>
        {renderMenu('xl')}
      </div>
    )
  }

  const renderContent = () => {
    return <div className={'legal-container'}>{document ? renderDocumentContent() : renderDocumentList()}</div>
  }
  if (documents) {
    return (
      <div className={'content h-100'}>
        <Header />
        <div className={'d-flex-1'}>
          <Container>{renderContent()}</Container>
        </div>
        <Footer />
      </div>
    )
  } else {
    return null
  }
})

export {Legal}
