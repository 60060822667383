import React from 'react'
import {TextView} from 'ac-brand-share-client'
import {getItemByKey, getSubjectShortName} from 'ac-brand-share'
import {Select} from 'ac-brand-ui'
import {SubjectDto} from 'ac-brand-auth-share'

interface ISelectIsAllLkSubjectProps {
  isAllLkSubject: boolean
  subjects: SubjectDto[]
  subjectId: string | undefined
  setSubjectId: (newSubjectId: string) => void
}

const SelectIsAllLkSubject: React.FC<ISelectIsAllLkSubjectProps> = ({
  isAllLkSubject,
  subjects,
  subjectId,
  setSubjectId,
}): React.ReactElement => {
  if (!isAllLkSubject) {
    return (
      <TextView
        id={'subject'}
        isSubscriptionWrapper={false}
        label={'Личный кабинет организации'}
        value={getSubjectShortName(getItemByKey<SubjectDto>('subjectId', subjects, subjectId as string) as any)}
        valueInForm={undefined}
        isCopyText
      />
    )
  } else {
    return (
      <Select
        isClearable
        id={'subject'}
        label={'Личный кабинет организации'}
        name={'Личный кабинет организации'}
        isSquareSelect
        value={subjectId as string}
        disabled={!isAllLkSubject}
        required
        options={
          subjects &&
          subjects.map((c) => ({
            value: c.subjectId as string,
            label: getSubjectShortName(c as any),
          }))
        }
        onChange={(value) => {
          setSubjectId(value)
        }}
      />
    )
  }
}

export {SelectIsAllLkSubject}
