import React from 'react'

export const PaymentPendingSVG: React.ReactNode = (
  <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.1351 102.027C35.1351 66.2032 64.1761 37.1622 100 37.1622C135.824 37.1622 164.865 66.2032 164.865 102.027C164.865 137.851 135.824 166.892 100 166.892C64.1761 166.892 35.1351 137.851 35.1351 102.027ZM100 39.8649C65.6688 39.8649 37.8378 67.6958 37.8378 102.027C37.8378 136.358 65.6688 164.189 100 164.189C134.331 164.189 162.162 136.358 162.162 102.027C162.162 67.6958 134.331 39.8649 100 39.8649Z"
      fill="#43468F"
    />
    <path
      d="M47.9491 80.4758C52.112 70.0959 59.2833 61.1978 68.5416 54.9245C77.7999 48.6511 88.7223 45.2891 99.9058 45.2703L100 101.351L47.9491 80.4758Z"
      fill="#43468F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.9035 43.919C100.65 43.9178 101.256 44.5218 101.257 45.2681L101.351 101.349C101.352 101.798 101.13 102.218 100.758 102.47C100.386 102.722 99.9137 102.773 99.497 102.606L47.446 81.73C46.7533 81.4522 46.417 80.6655 46.6948 79.9728C50.9581 69.3428 58.3021 60.2303 67.7835 53.8058C77.2649 47.3813 88.4506 43.9382 99.9035 43.919Z"
      fill="#43468F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M100 33.1081C97.0813 33.1081 94.2057 33.2896 91.3836 33.6418C90.643 33.7342 89.9677 33.2088 89.8753 32.4682C89.7828 31.7276 90.3083 31.0523 91.0489 30.9599C93.9818 30.5939 96.9692 30.4054 100 30.4054C100.746 30.4054 101.351 31.0104 101.351 31.7567C101.351 32.5031 100.746 33.1081 100 33.1081ZM85.4382 33.2553C85.6063 33.9824 85.1531 34.7082 84.426 34.8763C53.8645 41.941 31.0811 69.3511 31.0811 102.085C31.0811 102.831 30.476 103.436 29.7297 103.436C28.9834 103.436 28.3784 102.831 28.3784 102.085C28.3784 68.0666 52.0558 39.5852 83.8172 32.243C84.5444 32.0749 85.2701 32.5281 85.4382 33.2553ZM162.579 70.0326C163.25 69.7051 164.059 69.9833 164.387 70.654C169.021 80.1458 171.622 90.8133 171.622 102.085C171.622 106.478 171.226 110.781 170.469 114.958C170.336 115.692 169.633 116.18 168.899 116.047C168.164 115.914 167.677 115.211 167.81 114.476C168.538 110.457 168.919 106.316 168.919 102.085C168.919 91.2331 166.416 80.9704 161.958 71.8398C161.63 71.1692 161.909 70.3601 162.579 70.0326Z"
      fill="#43468F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M140.491 61.3667C141.019 61.8944 141.019 62.75 140.491 63.2778L134.519 69.25C133.992 69.7777 133.136 69.7777 132.608 69.25C132.08 68.7222 132.08 67.8666 132.608 67.3389L138.58 61.3667C139.108 60.8389 139.964 60.8389 140.491 61.3667Z"
      fill="#43468F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M157.77 102.027C157.77 102.773 157.165 103.378 156.419 103.378L147.973 103.378C147.227 103.378 146.622 102.773 146.622 102.027C146.622 101.281 147.227 100.676 147.973 100.676L156.419 100.676C157.165 100.676 157.77 101.281 157.77 102.027Z"
      fill="#43468F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M100 147.973C100.746 147.973 101.351 148.578 101.351 149.324V157.77C101.351 158.517 100.746 159.122 100 159.122C99.2537 159.122 98.6487 158.517 98.6487 157.77V149.324C98.6487 148.578 99.2537 147.973 100 147.973Z"
      fill="#43468F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.3919 134.466C67.9196 134.994 67.9196 135.85 67.3919 136.377L61.4197 142.35C60.892 142.877 60.0363 142.877 59.5086 142.35C58.9809 141.822 58.9809 140.966 59.5086 140.438L65.4808 134.466C66.0085 133.938 66.8642 133.938 67.3919 134.466Z"
      fill="#43468F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M132.609 134.467C133.136 133.939 133.992 133.939 134.52 134.467L140.492 140.439C141.02 140.967 141.02 141.822 140.492 142.35C139.964 142.878 139.108 142.878 138.581 142.35L132.609 136.378C132.081 135.85 132.081 134.994 132.609 134.467Z"
      fill="#43468F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.7162 102.027C53.7162 102.773 53.1112 103.378 52.3648 103.378L43.9189 103.378C43.1726 103.378 42.5675 102.773 42.5675 102.027C42.5675 101.281 43.1726 100.676 43.9189 100.676L52.3648 100.676C53.1112 100.676 53.7162 101.281 53.7162 102.027Z"
      fill="#43468F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M125.338 179.73C124.405 179.73 123.649 180.486 123.649 181.419C123.649 182.352 124.405 183.108 125.338 183.108C126.271 183.108 127.027 182.352 127.027 181.419C127.027 180.486 126.271 179.73 125.338 179.73ZM120.27 181.419C120.27 178.62 122.539 176.351 125.338 176.351C128.137 176.351 130.405 178.62 130.405 181.419C130.405 184.218 128.137 186.486 125.338 186.486C122.539 186.486 120.27 184.218 120.27 181.419Z"
      fill="#A8B2C4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9189 84.4595C18.1726 84.4595 17.5676 85.0645 17.5676 85.8108C17.5676 86.5572 18.1726 87.1622 18.9189 87.1622C19.6653 87.1622 20.2703 86.5572 20.2703 85.8108C20.2703 85.0645 19.6653 84.4595 18.9189 84.4595ZM14.8649 85.8108C14.8649 83.5718 16.6799 81.7568 18.9189 81.7568C21.1579 81.7568 22.973 83.5718 22.973 85.8108C22.973 88.0498 21.1579 89.8649 18.9189 89.8649C16.6799 89.8649 14.8649 88.0498 14.8649 85.8108Z"
      fill="#A8B2C4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M116.216 14.8649C115.47 14.8649 114.865 15.4699 114.865 16.2162C114.865 16.9626 115.47 17.5676 116.216 17.5676C116.963 17.5676 117.568 16.9626 117.568 16.2162C117.568 15.4699 116.963 14.8649 116.216 14.8649ZM112.162 16.2162C112.162 13.9772 113.977 12.1622 116.216 12.1622C118.455 12.1622 120.27 13.9772 120.27 16.2162C120.27 18.4552 118.455 20.2703 116.216 20.2703C113.977 20.2703 112.162 18.4552 112.162 16.2162Z"
      fill="#A8B2C4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.1213 129.226C19.1065 127.195 21.9993 127.195 22.9845 129.226L23.5508 130.393L24.718 130.959C26.7486 131.944 26.7486 134.837 24.718 135.822L23.5508 136.389L22.9845 137.556C21.9993 139.586 19.1065 139.586 18.1213 137.556L17.555 136.389L16.3878 135.822C14.3572 134.837 14.3572 131.944 16.3878 130.959L17.555 130.393L18.1213 129.226ZM21.1192 131.573L20.5529 130.405L19.9866 131.573C19.7217 132.119 19.2808 132.56 18.7348 132.824L17.5676 133.391L18.7348 133.957C19.2808 134.222 19.7217 134.663 19.9866 135.209L20.5529 136.376L21.1192 135.209C21.3841 134.663 21.8251 134.222 22.371 133.957L23.5382 133.391L22.371 132.824C21.8251 132.56 21.3841 132.119 21.1192 131.573Z"
      fill="#FFC107"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M88.8075 169.329C89.5097 167.881 91.5715 167.881 92.2736 169.329L92.6773 170.161L93.5092 170.564C94.9564 171.266 94.9564 173.328 93.5092 174.03L92.6773 174.434L92.2736 175.266C91.5715 176.713 89.5097 176.713 88.8075 175.266L88.4038 174.434L87.5719 174.03C86.1247 173.328 86.1247 171.266 87.5719 170.564L88.4038 170.161L88.8075 169.329ZM90.9442 171.001L90.5406 170.17L90.1369 171.001C89.9481 171.391 89.6339 171.705 89.2447 171.894L88.4128 172.297L89.2447 172.701C89.6339 172.89 89.9481 173.204 90.1369 173.593L90.5406 174.425L90.9442 173.593C91.133 173.204 91.4473 172.89 91.8364 172.701L92.6683 172.297L91.8364 171.894C91.4473 171.705 91.133 171.391 90.9442 171.001Z"
      fill="#FFC107"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M182.311 107.604C183.296 105.573 186.189 105.573 187.174 107.604L187.74 108.771L188.907 109.337C190.938 110.323 190.938 113.216 188.907 114.201L187.74 114.767L187.174 115.934C186.189 117.965 183.296 117.965 182.311 115.934L181.744 114.767L180.577 114.201C178.546 113.216 178.546 110.323 180.577 109.337L181.744 108.771L182.311 107.604ZM185.308 109.951L184.742 108.784L184.176 109.951C183.911 110.497 183.47 110.938 182.924 111.203L181.757 111.769L182.924 112.335C183.47 112.6 183.911 113.041 184.176 113.587L184.742 114.754L185.308 113.587C185.573 113.041 186.014 112.6 186.56 112.335L187.727 111.769L186.56 111.203C186.014 110.938 185.573 110.497 185.308 109.951Z"
      fill="#FFC107"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M71.4997 6.25266C72.4849 4.2221 75.3777 4.22209 76.3629 6.25266L76.9292 7.41986L78.0964 7.98617C80.1269 8.97136 80.1269 11.8642 78.0964 12.8494L76.9292 13.4157L76.3629 14.5829C75.3777 16.6134 72.4849 16.6135 71.4997 14.5829L70.9333 13.4157L69.7661 12.8494C67.7356 11.8642 67.7356 8.97137 69.7661 7.98617L70.9333 7.41986L71.4997 6.25266ZM74.4976 8.59964L73.9313 7.43244L73.365 8.59964C73.1001 9.14562 72.6591 9.58656 72.1131 9.85147L70.9459 10.4178L72.1131 10.9841C72.6591 11.249 73.1001 11.6899 73.365 12.2359L73.9313 13.4031L74.4976 12.2359C74.7625 11.6899 75.2034 11.249 75.7494 10.9841L76.9166 10.4178L75.7494 9.85147C75.2034 9.58656 74.7625 9.14562 74.4976 8.59964Z"
      fill="#FFC107"
    />
  </svg>
)
