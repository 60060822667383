import React from 'react'

export const WhiteLogoSvg: React.ReactNode = (
  <svg width="140" height="32" viewBox="0 0 140 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.8888 1.77778C9.03413 1.77778 2.66663 8.14528 2.66663 16C2.66663 22.0139 6.39931 27.156 11.6743 29.2359V23.0199C11.6743 22.9727 11.693 22.9275 11.7264 22.8941L17.7003 16.9252C17.8123 16.8133 18.0037 16.8926 18.0037 17.051V19.5255C18.0037 19.7218 18.1629 19.881 18.3593 19.881H24.3725C27.7142 19.881 29.4046 18.7026 29.4046 16.2672C29.4046 13.7925 27.6355 12.4963 24.3725 12.4963H18.3593C18.1629 12.4963 18.0037 12.6555 18.0037 12.8518V15.4654C18.0037 15.6237 17.8123 15.7031 17.7003 15.5911L11.7264 9.62224C11.693 9.5889 11.6743 9.54366 11.6743 9.49648V8.1382C11.6743 7.94183 11.8335 7.78264 12.0298 7.78264H24.7263C26.2152 7.78264 27.57 7.92348 28.7814 8.19712C26.2393 4.33049 21.8622 1.77778 16.8888 1.77778ZM12.1417 19.801C12.0286 19.9134 11.8363 19.8317 11.8386 19.6724L11.9379 12.7842C11.9402 12.6273 12.1297 12.55 12.241 12.6606L15.5292 15.9293C15.6691 16.0683 15.6691 16.2946 15.5292 16.4336L12.1417 19.801Z"
      fill="white"
    />
    <path
      d="M28.3892 24.3691C27.5386 24.4928 26.6185 24.5554 25.6305 24.5554H18.3593C18.1629 24.5554 18.0037 24.7146 18.0037 24.9109V30.1792C22.27 29.8484 26.0084 27.6351 28.3892 24.3691Z"
      fill="white"
    />
    <path
      d="M45.852 21.668C47.56 21.668 48.974 21.052 49.898 19.918L48.442 18.574C47.784 19.344 46.958 19.736 45.978 19.736C44.144 19.736 42.842 18.448 42.842 16.6C42.842 14.752 44.144 13.464 45.978 13.464C46.958 13.464 47.784 13.856 48.442 14.612L49.898 13.268C48.974 12.148 47.56 11.532 45.866 11.532C42.814 11.532 40.546 13.646 40.546 16.6C40.546 19.554 42.814 21.668 45.852 21.668ZM61.1096 21.5L58.9116 18.35C60.1856 17.804 60.9276 16.726 60.9276 15.256C60.9276 13.058 59.2896 11.7 56.6716 11.7H52.4296V21.5H54.6976V18.77H56.6716H56.7836L58.6736 21.5H61.1096ZM58.6316 15.256C58.6316 16.32 57.9316 16.964 56.5456 16.964H54.6976V13.548H56.5456C57.9316 13.548 58.6316 14.178 58.6316 15.256ZM74.7705 21.5L74.7425 11.7H72.8805L69.2685 17.79L65.6005 11.7H63.7245V21.5H65.8525V15.746L68.7225 20.464H69.7445L72.6285 15.62L72.6425 21.5H74.7705ZM82.3376 11.7H78.0956V21.5H80.3636V18.798H82.3376C84.9556 18.798 86.5936 17.44 86.5936 15.256C86.5936 13.058 84.9556 11.7 82.3376 11.7ZM82.2116 16.95H80.3636V13.548H82.2116C83.5976 13.548 84.2976 14.178 84.2976 15.256C84.2976 16.32 83.5976 16.95 82.2116 16.95ZM96.0426 21.5H98.4226L94.0406 11.7H91.8006L87.4326 21.5H89.7566L90.6246 19.4H95.1746L96.0426 21.5ZM91.3526 17.678L92.9066 13.926L94.4606 17.678H91.3526ZM101.977 21.5H104.245V13.548H107.381V11.7H98.8412V13.548H101.977V21.5ZM111.855 19.68V17.412H116.405V15.648H111.855V13.52H117.007V11.7H109.601V21.5H117.189V19.68H111.855ZM126.742 11.7V17.65L121.87 11.7H119.994V21.5H122.234V15.55L127.12 21.5H128.982V11.7H126.742ZM134.331 21.5H136.599V13.548H139.735V11.7H131.195V13.548H134.331V21.5Z"
      fill="white"
    />
  </svg>
)
