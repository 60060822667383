import {PaymentFinishPage} from './PaymentFinishPage'
import {PaymentStatusEnum} from 'ac-brand-auth-share'
import React from 'react'

interface PaymentSuccessPageProps {}

const PaymentFailurePage: React.FC<PaymentSuccessPageProps> = ({}) => {
  return <PaymentFinishPage status={PaymentStatusEnum.canceled} />
}

export {PaymentFailurePage}
