import React, {useEffect, useMemo, useState} from 'react'
import {TariffPageManager} from './services/TariffPageManager'
import {TariffPage} from './TariffPage'
import {InvoiceDto, ResponseError, SubscriptionDto, TariffAndPeriodsDto, UserDto, WalletDto} from 'ac-brand-share'
import {connectComponent} from 'ac-brand-share-client'
import {ExternalPaymentDto, PaymentTypeEnum, SubjectDto} from 'ac-brand-auth-share'

export interface TariffPageProps {
  user: UserDto
}

export interface TariffPageState {
  subjects: SubjectDto[]
  tariffsAndPeriods: TariffAndPeriodsDto
  enabledPaymentTypes: PaymentTypeEnum[]
  selectedPeriodId: string
  selectedTariffId: string
  externalPayment: ExternalPaymentDto
  invoice: InvoiceDto
  error: ResponseError
  isOpenTariffSelectModal
  isHasDemo: boolean
  isOpenConfirmDemoModal: boolean
  isOpenNextSubscriptionModal: boolean
  isHasNextSubscription: boolean
  wallet: WalletDto
  isAutoPaymentBonus: boolean
  nextSubscription?: SubscriptionDto
}

export const INITIAL_STATE: TariffPageState = {
  subjects: null,
  tariffsAndPeriods: null,
  enabledPaymentTypes: null,
  selectedPeriodId: null,
  selectedTariffId: null,
  externalPayment: null,
  invoice: null,
  error: null,
  isOpenTariffSelectModal: false,
  isHasDemo: null,
  isOpenConfirmDemoModal: false,
  isHasNextSubscription: false,
  isOpenNextSubscriptionModal: false,
  wallet: null,
  isAutoPaymentBonus: null,
  nextSubscription: null,
}

const TariffPageContainer: React.FC<TariffPageProps> = (props): React.ReactElement => {
  const [state, setState] = useState<TariffPageState>({...INITIAL_STATE})

  const tariffPageManager: TariffPageManager = useMemo(() => {
    return new TariffPageManager(setState)
  }, [])

  useEffect(() => {
    tariffPageManager.initialize(props)
  }, [tariffPageManager, props])

  useEffect(() => {
    tariffPageManager.loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <TariffPage state={state} tariffPageManager={tariffPageManager} />
}

export default connectComponent(
  (state) => ({
    user: state.auth.user,
  }),
  () => ({}),
  TariffPageContainer,
)
