import {apiGet, apiPost, RestApi} from 'ac-brand-share-client'
import {DiscountCategoryDto, FeeCalcDataDto, FeeCalcResultDto, ResponseError, StepDto} from 'ac-brand-share'
import {CONFIG} from '../../../constants'

class FeeApi extends RestApi {
  public getError(): ResponseError {
    return this.error
  }

  public async getFeeSteps(): Promise<StepDto[]> {
    return await this.sendRequestHandleError(async () => {
      return await apiGet(CONFIG.apiUrl + '/fee/steps')
    }, 'Произошла ошибка получения информации для калькулятора пошлин.')
  }

  public async getDiscountCategories(): Promise<DiscountCategoryDto[]> {
    return await this.sendRequestHandleError(async () => {
      return await apiGet(CONFIG.apiUrl + '/fee/discount-categories')
    }, 'Произошла ошибка получения льготных категорий.')
  }

  public async feeCalc(data: FeeCalcDataDto): Promise<FeeCalcResultDto> {
    return await this.sendRequestHandleError(async () => {
      return await apiPost(CONFIG.apiUrl + '/fee/calc', data)
    }, 'Произошла ошибка при расчете стоимости пошлины.')
  }
}

export {FeeApi}
