import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Button, ButtonIcon, ButtonLink, Icon, THEME_COLORS} from 'ac-brand-ui'
import {mdiMenu} from '@mdi/js'
import {CONFIG} from '../../../constants'

interface HeaderMenuProps {
  className?: string
  headerHeight: number
  hrefAuth: string
  onTry?: (e: any) => void
}

const HeaderMenu: React.FC<HeaderMenuProps> = ({className, headerHeight, hrefAuth, onTry}): React.ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleClickOutside = useCallback((e) => {
    const contentBlock = document.getElementsByClassName('hdm-content')[0]
    const path = (e.composedPath && e.composedPath()) || e.path
    if (path && !path.includes(contentBlock)) {
      const button = document.getElementsByClassName('hdm-button')[0]
      if (!path.includes(button)) {
        setIsOpen(false)
      }
    }
  }, [])

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)

    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [handleClickOutside])

  const renderMenuButton: React.ReactElement = useMemo(() => {
    return (
      <div className={className ? className : ''}>
        <ButtonIcon
          className={'hdm-button'}
          icon={<Icon path={mdiMenu} size={'20px'} color={THEME_COLORS.primary} />}
          title={'Открыть'}
          onClick={() => {
            setIsOpen(!isOpen)
          }}
        />
      </div>
    )
  }, [className, isOpen])

  const renderMenuContent: React.ReactElement = useMemo(() => {
    if (isOpen) {
      return (
        <div
          style={{
            top: headerHeight,
          }}
          className={'hdm-content'}>
          <ButtonLink
            href={CONFIG.crmSiteWeb + '/tariff'}
            size={'md'}
            style={'text'}
            isCircleButton
            className={'mb-x4'}>
            Цены
          </ButtonLink>
          <ButtonLink
            href={CONFIG.crmSiteWeb + '/calcfee'}
            size={'md'}
            style={'text'}
            isCircleButton
            className={'mb-x4'}>
            Калькулятор пошлин
          </ButtonLink>
          <ButtonLink href={hrefAuth} style={'outline'} isCircleButton size={'md'} className={onTry ? 'mb-x4' : 'mb-0'}>
            Войти
          </ButtonLink>
          {onTry && (
            <Button className={'main-info-button'} isCircleButton size={'md'} onClick={onTry}>
              Попробовать
            </Button>
          )}
        </div>
      )
    } else {
      return null
    }
  }, [headerHeight, hrefAuth, onTry, isOpen])

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <>
        {renderMenuButton}
        {renderMenuContent}
      </>
    )
  }, [renderMenuButton, renderMenuContent])

  return <div className={'header-site-menu-block'}>{renderContent}</div>
}

export {HeaderMenu}
