import {apiGet, apiPost, RestApi} from 'ac-brand-share-client'
import {InvoiceDto, PaymentPeriodDto, ResponseError, SubscriptionDto} from 'ac-brand-share'
import {CONFIG} from '../../../constants'
import {ExternalPaymentDto, PaymentTypeEnum} from 'ac-brand-auth-share'

class PaymentApi extends RestApi {
  public getError(): ResponseError {
    return this.error
  }

  public async getEnabledPaymentTypes(): Promise<PaymentTypeEnum[]> {
    return await this.sendRequestHandleError(async () => {
      return await apiGet(CONFIG.apiUrl + '/payment/payment-types')
    }, 'Произошла ошибка при получении разрешенных способов оплаты')
  }

  public async pay(payment: PaymentPeriodDto): Promise<ExternalPaymentDto> {
    return await this.sendRequestHandleError(async () => {
      return await apiPost(CONFIG.apiUrl + '/payment/pay', payment)
    }, 'Произошла ошибка при оплате')
  }

  public async createInvoice(invoice: InvoiceDto): Promise<InvoiceDto> {
    return await this.sendRequestHandleError(async () => {
      return await apiPost(CONFIG.apiUrl + '/invoice/create', invoice)
    }, 'Произошла ошибка при создании счета')
  }

  public async refresh(paymentId: string): Promise<ExternalPaymentDto> {
    return await this.sendRequestHandleError(async () => {
      return await apiGet(CONFIG.apiUrl + '/payment/refresh/' + paymentId)
    }, 'Произошла ошибка при проверки оплаты')
  }

  public async getNextSubscription(): Promise<SubscriptionDto> {
    return await this.sendRequestHandleError(async () => {
      return await apiGet(CONFIG.apiUrl + '/subscription/find-next')
    }, 'Произошла ошибка при получении подписок')
  }
}

export {PaymentApi}
