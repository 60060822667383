import React, {memo} from 'react'
import {FormLegalLinks, ModalWrapper} from 'ac-brand-share-client'
import {AuthSubsystemIdEnum} from 'ac-brand-share'

interface ConfirmDemoModalProps {
  onClose: () => void
  onSubmit: () => Promise<void>
}

const ConfirmDemoModal: React.FC<ConfirmDemoModalProps> = memo(({onSubmit, onClose}): React.ReactElement => {
  return (
    <ModalWrapper
      size={'sm'}
      closeText={'Отменить'}
      header={'Активация тарифного плана'}
      onClose={onClose}
      onSubmit={onSubmit}
      submitText={'Активировать'}>
      <p className={'mb-x6 subtitle2 font-weight-normal'}>
        Вы действительно хотите активировать <span className={'font-weight-bold'}>на 14 дней</span> тариф:
      </p>
      <h2 className={'mb-x6'}>Пробный</h2>
      <p className={'caption font-weight-normal text-gray-700 mb-0'}>
        Нажимая на кнопку «Активировать», вы соглашаетесь{' '}
        <FormLegalLinks authSubsystem={AuthSubsystemIdEnum.CRM_EXECUTOR} />.
      </p>
    </ModalWrapper>
  )
})

export {ConfirmDemoModal}
