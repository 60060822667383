import React, {memo, useCallback, useMemo} from 'react'
import {Button, Checkbox, Col, Divider, HelpPopover} from 'ac-brand-ui'
import {isEmpty, numberWithSpaces, round, WalletDto} from 'ac-brand-share'
import {CONFIG} from '../../../constants'
import {RUB} from 'ac-brand-share-client'

interface TariffCardProps {
  name: string
  description: string
  fullPriceMonth: number | 'Бесплатно'
  commentPrice?: string
  isDemoTariff?: boolean
  priceYear?: number
  salePrice?: number
  onPay: () => Promise<void>
  wallet: WalletDto
  isAutoPaymentBonus?: boolean
  setIsAutoPaymentBonus?: (value: boolean) => void
}

const TariffCard: React.FC<TariffCardProps> = memo(
  ({
    name,
    description,
    fullPriceMonth,
    commentPrice,
    isDemoTariff,
    priceYear,
    salePrice,
    onPay,
    wallet,
    isAutoPaymentBonus,
    setIsAutoPaymentBonus,
  }): React.ReactElement => {
    const getRenderIsAutoPayment = useCallback(
      (bonusAmount: number) => {
        if (!isEmpty(bonusAmount)) {
          return (
            <div className={'d-flex flex-row mb-x6'}>
              <HelpPopover
                place={'auto'}
                popoverNameToLS={null}
                content={
                  <>
                    <p className={'subtitle2 mb-x3'}>Списание бонусных баллов </p>
                    <p className={'caption mb-x2'}>
                      Вы видите скидку на тариф, так как у вас есть бонусные баллы и в личном кабинете установлена
                      отметка «Списывать при оплате тарифа»
                    </p>
                    <a
                      href={CONFIG.executorAuthWeb + '/tariff-navigation/wallet'}
                      className={'caption pt-link-color text-info mb-0'}>
                      Подробней…
                    </a>
                  </>
                }>
                <Checkbox
                  id={'isAutoPayment' + name}
                  name={'isAutoPayment' + name}
                  type={'checkbox'}
                  checked={!!isAutoPaymentBonus}
                  label={`Списать ${bonusAmount.toString()} бонусов`}
                  onChange={(value: boolean) => {
                    setIsAutoPaymentBonus(value)
                  }}
                />
              </HelpPopover>
            </div>
          )
        } else {
          return null
        }
      },
      [isAutoPaymentBonus, name, setIsAutoPaymentBonus],
    )

    const renderPriceBlock: React.ReactElement = useMemo(() => {
      let bonusAmount: number = null
      let newSalePrice: number = salePrice
      if (!isDemoTariff && wallet && wallet?.amount > 0) {
        bonusAmount = round(((salePrice ? salePrice : Number(fullPriceMonth)) * wallet.maxPercentPayment) / 100)
        if (bonusAmount > wallet.amount) {
          bonusAmount = wallet.amount
        }
        if (isAutoPaymentBonus) {
          newSalePrice = (newSalePrice ? newSalePrice : Number(fullPriceMonth)) - bonusAmount
        }
      }

      return (
        <>
          {!isDemoTariff && newSalePrice && (
            <p className={'caption cross-out-price mb-x1'}>
              {fullPriceMonth === 'Бесплатно' ? fullPriceMonth : numberWithSpaces(fullPriceMonth as number)} {RUB}
            </p>
          )}
          <div className={`tariff-price ${newSalePrice ? ' sale-price' : ''}`}>
            {newSalePrice
              ? numberWithSpaces(newSalePrice)
              : fullPriceMonth === 'Бесплатно'
              ? fullPriceMonth
              : numberWithSpaces(fullPriceMonth as number)}{' '}
            {!isDemoTariff && RUB}
          </div>
          {commentPrice && <p className={'caption text-center text-gray-500 mb-x6'}>{commentPrice}</p>}
          {!isDemoTariff && (
            <p className={'caption text-center text-gray-500 mb-x3 ' + (isDemoTariff ? 'mt-n-x5 ' : '')}>
              {numberWithSpaces(priceYear)} {RUB}/год
            </p>
          )}
          {getRenderIsAutoPayment(bonusAmount)}
        </>
      )
    }, [
      salePrice,
      isDemoTariff,
      wallet,
      fullPriceMonth,
      commentPrice,
      priceYear,
      getRenderIsAutoPayment,
      isAutoPaymentBonus,
    ])

    return (
      <Col lg={3} md={4} sm={6} xs={12} className={'mb-x6'}>
        <div className={'tariff-card'}>
          <div>
            <p className={'tariff-name mb-x6'}>{name}</p>
            <Divider className={'mb-x6'} />
            <p className={'subtitle1 font-weight-normal text-gray-700 text-center mb-x6'}>{description}</p>
          </div>
          <div className={'d-flex flex-column align-items-center'}>
            {renderPriceBlock}
            <Button
              color={isDemoTariff ? 'primary' : 'success'}
              size={'sm'}
              className={'mb-x6 w-75'}
              onClick={async () => {
                await onPay()
              }}>
              {isDemoTariff ? 'Выбрать' : 'Купить'}
            </Button>
            <a
              href={'/'}
              onClick={(e) => {
                let planOptionsContainer = document.getElementById('plan-options')
                e.preventDefault() // Stop Page Reloading
                planOptionsContainer && planOptionsContainer.scrollIntoView({behavior: 'smooth'})
              }}
              className={'caption text-link'}>
              Опции плана
            </a>
          </div>
        </div>
      </Col>
    )
  },
)

export {TariffCard}
