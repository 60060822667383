import {INITIAL_STATE, PaymentFinishPageProps, PaymentFinishPageState} from '../PaymentFinishPageContainer'
import {PaymentApi} from './PaymentApi'
import {ExternalPaymentDto} from 'ac-brand-auth-share'

class PaymentFinishPageManager {
  private setState: (state: PaymentFinishPageState) => void
  private state: PaymentFinishPageState
  private props: PaymentFinishPageProps
  private paymentApi: PaymentApi

  constructor(setState: (state: PaymentFinishPageState) => void) {
    this.setState = setState
    this.state = {...INITIAL_STATE}
    this.paymentApi = new PaymentApi()
  }

  private setLocalState(newState: PaymentFinishPageState, isDispatch = true): void {
    this.state = {...this.state, ...newState}
    if (isDispatch) {
      this.dispatchState()
    }
  }

  private dispatchState(): void {
    this.setState({...this.state})
  }

  public initialize(props: PaymentFinishPageProps): void {
    this.props = props
  }

  public async loadData(): Promise<void> {
    const {paymentId} = this.props
    const payment: ExternalPaymentDto = await this.paymentApi.refresh(paymentId)
    const newState = {...this.state}
    newState.externalPayment = payment
    this.setLocalState(newState)
  }
}

export {PaymentFinishPageManager}
