import React from 'react'

export const PaymentSuccessSVG: React.ReactNode = (
  <svg width="201" height="200" viewBox="0 0 201 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M100.662 158.784C133.127 158.784 159.446 132.465 159.446 100C159.446 67.5346 133.127 41.2162 100.662 41.2162C68.1967 41.2162 41.8783 67.5346 41.8783 100C41.8783 132.465 68.1967 158.784 100.662 158.784ZM138.899 87.0076C139.474 86.532 139.555 85.6802 139.079 85.105L130.468 74.6901C129.993 74.1149 129.141 74.0341 128.566 74.5097L93.3337 103.639C92.7966 104.083 92.0099 104.045 91.5171 103.553L79.9761 92.0117C79.4484 91.484 78.5928 91.484 78.065 92.0117L68.5095 101.567C67.9818 102.095 67.9818 102.951 68.5095 103.478L90.4812 125.45C90.974 125.943 91.7607 125.98 92.2978 125.536L138.899 87.0076Z"
      fill="#3DAA3F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M139.018 77.3143C139.606 76.8544 140.455 76.958 140.915 77.5458L146.861 85.1439C147.764 86.2973 147.584 87.9599 146.454 88.8929L104.362 123.677C103.786 124.152 102.935 124.071 102.459 123.496C101.984 122.921 102.065 122.069 102.64 121.593L144.733 86.8095L138.787 79.2114C138.327 78.6236 138.431 77.7743 139.018 77.3143ZM100.015 125.516C100.49 126.091 100.409 126.943 99.8337 127.419L97.0029 129.758C96.4275 130.233 95.5758 130.152 95.1003 129.577C94.6249 129.002 94.7059 128.15 95.2812 127.674L98.112 125.335C98.6873 124.86 99.5391 124.941 100.015 125.516Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.054 31.9918C52.0119 31.9918 49.5458 34.4579 49.5458 37.5C49.5458 40.5421 52.0119 43.0082 55.054 43.0082C58.0961 43.0082 60.5622 40.5421 60.5622 37.5C60.5622 34.4579 58.0961 31.9918 55.054 31.9918ZM46.608 37.5C46.608 32.8355 50.3894 29.0541 55.054 29.0541C59.7185 29.0541 63.4999 32.8355 63.4999 37.5C63.4999 42.1646 59.7185 45.946 55.054 45.946C50.3894 45.946 46.608 42.1646 46.608 37.5Z"
      fill="#A8B2C4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.4189 137.838C31.6726 137.838 31.0676 138.443 31.0676 139.189C31.0676 139.936 31.6726 140.541 32.4189 140.541C33.1653 140.541 33.7703 139.936 33.7703 139.189C33.7703 138.443 33.1653 137.838 32.4189 137.838ZM28.3649 139.189C28.3649 136.95 30.1799 135.135 32.4189 135.135C34.6579 135.135 36.473 136.95 36.473 139.189C36.473 141.428 34.6579 143.243 32.4189 143.243C30.1799 143.243 28.3649 141.428 28.3649 139.189Z"
      fill="#A8B2C4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M183.095 127.027C182.348 127.027 181.743 127.632 181.743 128.378C181.743 129.125 182.348 129.73 183.095 129.73C183.841 129.73 184.446 129.125 184.446 128.378C184.446 127.632 183.841 127.027 183.095 127.027ZM179.041 128.378C179.041 126.139 180.856 124.324 183.095 124.324C185.334 124.324 187.149 126.139 187.149 128.378C187.149 130.617 185.334 132.432 183.095 132.432C180.856 132.432 179.041 130.617 179.041 128.378Z"
      fill="#A8B2C4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M141.165 47.5944C141.634 47.0136 142.484 46.9227 143.065 47.3914C146.314 50.0132 149.315 52.9298 152.027 56.1005C152.512 56.6676 152.446 57.5206 151.879 58.0058C151.312 58.4909 150.459 58.4245 149.973 57.8573C147.369 54.8127 144.487 52.0121 141.368 49.4947C140.787 49.026 140.696 48.1752 141.165 47.5944ZM155.411 62.7198C156.036 62.3118 156.873 62.4877 157.281 63.1127C161.54 69.637 164.703 76.9436 166.506 84.7703C166.674 85.4976 166.22 86.223 165.493 86.3906C164.765 86.5581 164.04 86.1044 163.872 85.3771C162.142 77.8663 159.107 70.8536 155.018 64.5901C154.61 63.9651 154.786 63.1278 155.411 62.7198ZM166.878 98.6487C167.625 98.6487 168.23 99.2537 168.23 100C168.23 128.161 151.003 152.294 126.517 162.444C122.618 164.06 118.535 165.322 114.308 166.189C113.577 166.339 112.863 165.868 112.713 165.136C112.563 164.405 113.034 163.691 113.765 163.541C117.822 162.709 121.741 161.498 125.483 159.947C148.993 150.202 165.527 127.03 165.527 100C165.527 99.2537 166.132 98.6487 166.878 98.6487ZM109.1 165.697C109.18 166.44 108.642 167.105 107.9 167.184C105.522 167.438 103.107 167.568 100.662 167.568C99.9158 167.568 99.3108 166.963 99.3108 166.216C99.3108 165.47 99.9158 164.865 100.662 164.865C103.011 164.865 105.33 164.74 107.614 164.497C108.356 164.418 109.021 164.955 109.1 165.697Z"
      fill="#A8B2C4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M117.144 9.02816C119.012 7.76225 121.518 9.20865 121.356 11.4598L120.972 16.7886L123.969 21.2117C125.234 23.0802 123.788 25.5855 121.537 25.4234L116.208 25.0397L111.785 28.0364C109.917 29.3023 107.411 27.8559 107.573 25.6048L107.957 20.2759L104.96 15.8529C103.694 13.9844 105.141 11.4791 107.392 11.6412L112.721 12.0248L117.144 9.02816ZM118.276 16.5945L118.66 11.2657L114.237 14.2624C113.734 14.6028 113.132 14.7642 112.527 14.7206L107.198 14.3369L110.195 18.76C110.535 19.2624 110.696 19.8647 110.653 20.47L110.269 25.7989L114.692 22.8022C115.195 22.4618 115.797 22.3004 116.402 22.344L121.731 22.7276L118.734 18.3046C118.394 17.8022 118.233 17.1998 118.276 16.5945Z"
      fill="#FFC107"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M152.96 160.7C153.945 158.669 156.838 158.669 157.823 160.7L160.156 165.506L164.962 167.839C166.993 168.824 166.993 171.717 164.962 172.702L160.156 175.034L157.823 179.841C156.838 181.871 153.945 181.871 152.96 179.841L150.628 175.034L145.821 172.702C143.791 171.717 143.791 168.824 145.821 167.839L150.628 165.506L152.96 160.7ZM157.724 166.686L155.392 161.88L153.06 166.686C152.795 167.232 152.354 167.673 151.808 167.938L147.001 170.27L151.808 172.602C152.354 172.867 152.795 173.308 153.06 173.854L155.392 178.661L157.724 173.854C157.989 173.308 158.43 172.867 158.976 172.602L163.783 170.27L158.976 167.938C158.43 167.673 157.989 167.232 157.724 166.686Z"
      fill="#FFC107"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.2845 164.754C53.2697 162.723 56.1625 162.723 57.1477 164.754L57.714 165.921L58.8812 166.487C60.9118 167.473 60.9118 170.365 58.8812 171.351L57.714 171.917L57.1477 173.084C56.1625 175.115 53.2697 175.115 52.2845 173.084L51.7182 171.917L50.551 171.351C48.5204 170.365 48.5204 167.473 50.551 166.487L51.7182 165.921L52.2845 164.754ZM55.2824 167.101L54.7161 165.934L54.1498 167.101C53.8849 167.647 53.444 168.088 52.898 168.353L51.7308 168.919L52.898 169.485C53.444 169.75 53.8849 170.191 54.1498 170.737L54.7161 171.904L55.2824 170.737C55.5473 170.191 55.9883 169.75 56.5342 169.485L57.7014 168.919L56.5342 168.353C55.9883 168.088 55.5473 167.647 55.2824 167.101Z"
      fill="#FFC107"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6687 58.5908C10.5107 57.8613 10.974 57.142 11.7034 56.984C24.3158 54.2529 33.5135 61.4547 38.3379 67.8446C38.7876 68.4403 38.6693 69.2877 38.0737 69.7374C37.478 70.1871 36.6306 70.0688 36.1809 69.4732C31.6813 63.5134 23.4363 57.2087 12.2754 59.6255C11.546 59.7835 10.8266 59.3202 10.6687 58.5908Z"
      fill="#FFC107"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M154.9 40.8732C154.154 40.8727 153.55 40.2672 153.55 39.5209C153.553 35.6939 154.799 32.7011 156.761 30.4947C158.706 28.3082 161.287 26.9663 163.875 26.2895C164.998 25.9956 166.138 25.8229 167.249 25.7634C167.329 25.4226 167.419 25.0812 167.518 24.7407C168.88 20.0561 172.186 15.0116 178.093 14.1249C178.831 14.0142 179.519 14.5227 179.63 15.2607C179.741 15.9988 179.232 16.6869 178.494 16.7977C174.109 17.4559 171.346 21.257 170.113 25.4954C170.078 25.6154 170.045 25.7354 170.012 25.8552C170.464 25.9118 170.902 25.989 171.322 26.0861C173.432 26.5733 175.504 27.6688 176.222 29.6115C176.903 31.4562 176.331 33.6563 175.1 35.0994C174.462 35.8475 173.595 36.4582 172.535 36.7047C171.459 36.9548 170.297 36.803 169.142 36.2088C168.315 35.7834 167.777 35.0234 167.433 34.2436C167.084 33.451 166.876 32.5111 166.78 31.5173C166.691 30.5899 166.695 29.5709 166.795 28.5071C166.06 28.5779 165.307 28.7084 164.559 28.9042C162.372 29.4761 160.299 30.5836 158.781 32.2908C157.28 33.9783 156.255 36.3236 156.253 39.5228C156.252 40.2691 155.647 40.8737 154.9 40.8732ZM169.51 28.5173C169.402 29.5113 169.392 30.4442 169.47 31.2585C169.548 32.0633 169.707 32.7012 169.906 33.1526C170.111 33.6168 170.304 33.7671 170.378 33.8054C171.026 34.1385 171.535 34.1623 171.923 34.0722C172.326 33.9784 172.713 33.7332 173.044 33.3454C173.751 32.5168 173.974 31.326 173.686 30.5481C173.451 29.9116 172.541 29.1413 170.714 28.7196C170.334 28.6319 169.931 28.5639 169.51 28.5173Z"
      fill="#FFC107"
    />
  </svg>
)
