import {LegalState} from './types/LegalState'
import {BaseAction} from '../utils/BaseAction'
import {LegalActionsTypes} from './types'

const INITIAL_STATE: LegalState = {
  documents: [],
}

export default function legalReducers(state: LegalState = INITIAL_STATE, action: BaseAction): LegalState {
  switch (action.type) {
    case LegalActionsTypes.SET_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
      }

    default: {
      return {
        ...state,
      }
    }
  }
}
