import React from 'react'

export const EmptyNotificationSvg: React.ReactNode = (
  <svg width="87" height="86" viewBox="0 0 87 86" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5985 56.7988C18.7589 56.5209 19.1143 56.4256 19.3923 56.5861L28.6372 61.9237C28.9151 62.0841 29.0103 62.4395 28.8498 62.7174C27.2155 65.5483 23.5957 66.5182 20.7648 64.8838C17.934 63.2494 16.9641 59.6296 18.5985 56.7988ZM19.3448 57.9007C18.4166 60.0816 19.2413 62.6622 21.3459 63.8773C23.4506 65.0924 26.0978 64.5163 27.5224 62.622L19.3448 57.9007Z"
      fill="#43468F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.3761 32.397C34.9583 24.4603 45.1069 21.741 53.0436 26.3233C60.9802 30.9055 63.6995 41.0541 59.1173 48.9907L54.5694 56.8679C52.331 60.7449 51.3943 65.2372 51.8968 69.6857L52.3177 73.4118C52.4941 74.9738 50.8179 76.0716 49.4566 75.2856L12.4344 53.9109C11.0731 53.1249 11.1857 51.1243 12.6266 50.4962L16.064 48.9976C20.1677 47.2085 23.5899 44.1511 25.8282 40.2741L30.3761 32.397ZM52.4625 27.3297C45.0817 23.0684 35.6439 25.5973 31.3826 32.9781L26.8347 40.8552C24.6609 44.6204 21.4293 47.6532 17.5528 49.5848L27.7422 55.4676C28.0202 55.6281 28.1154 55.9835 27.9549 56.2614C27.7945 56.5393 27.4391 56.6346 27.1611 56.4741L16.2596 50.1801L13.0911 51.5615C12.5244 51.8085 12.4801 52.5953 13.0155 52.9044L50.0377 74.2792C50.573 74.5883 51.2323 74.1565 51.1629 73.5423L50.742 69.8161C50.2116 65.1207 51.2003 60.379 53.5629 56.2868L58.1108 48.4096C62.3721 41.0288 59.8433 31.591 52.4625 27.3297Z"
      fill="#43468F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.8236 30.9049C53.039 30.667 53.4065 30.6487 53.6444 30.8641C56.5475 33.4921 58.0998 37.2552 58.049 41.1126C58.0448 41.4335 57.7812 41.6902 57.4603 41.686C57.1394 41.6818 56.8827 41.4182 56.8869 41.0973C56.9336 37.5581 55.509 34.1197 52.8644 31.7257C52.6265 31.5103 52.6082 31.1429 52.8236 30.9049ZM57.2483 43.4175C57.5608 43.4907 57.7547 43.8033 57.6815 44.1158C57.372 45.4373 56.8648 46.7382 56.1499 47.9764C55.9894 48.2543 55.6341 48.3496 55.3561 48.1891C55.0782 48.0286 54.983 47.6732 55.1434 47.3953C55.8008 46.2567 56.2662 45.0623 56.55 43.8507C56.6232 43.5383 56.9359 43.3443 57.2483 43.4175Z"
      fill="#43468F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.494 24.3675C49.8521 22.0153 52.8598 21.2094 55.2119 22.5674C57.5641 23.9254 58.37 26.9331 57.012 29.2853C56.8515 29.5632 56.4962 29.6584 56.2182 29.498C55.9403 29.3375 55.8451 28.9821 56.0055 28.7042C57.0426 26.9079 56.4272 24.611 54.6309 23.5739C52.8345 22.5368 50.5376 23.1522 49.5005 24.9485C49.34 25.2265 48.9847 25.3217 48.7067 25.1612C48.4288 25.0008 48.3336 24.6454 48.494 24.3675Z"
      fill="#43468F"
    />
    <path
      d="M46.0976 13.184C46.4179 13.2037 46.6936 12.96 46.7133 12.6397C46.733 12.3194 46.4893 12.0438 46.169 12.024C35.9135 11.3927 25.8237 16.2627 20.4764 25.5246C20.3159 25.8025 20.4111 26.1579 20.6891 26.3183C20.967 26.4788 21.3224 26.3836 21.4828 26.1057C26.5932 17.2543 36.2479 12.5776 46.0976 13.184Z"
      fill="#FFC107"
    />
    <path
      d="M68.8641 25.1271C68.6869 24.8595 68.3263 24.7863 68.0588 24.9636C67.7912 25.1408 67.718 25.5014 67.8953 25.7689C73.3452 33.9958 74.1225 44.6953 69.0122 53.5467C68.8517 53.8246 68.9469 54.18 69.2248 54.3405C69.5028 54.5009 69.8582 54.4057 70.0186 54.1278C75.366 44.8659 74.5387 33.6929 68.8641 25.1271Z"
      fill="#FFC107"
    />
    <path
      d="M45.8335 19.4806C46.1536 19.5042 46.4322 19.2638 46.4558 18.9438C46.4793 18.6237 46.239 18.3451 45.919 18.3215C38.011 17.7386 30.2684 21.3315 26.2292 28.3278C26.0687 28.6057 26.1639 28.9611 26.4419 29.1215C26.7198 29.282 27.0752 29.1868 27.2356 28.9088C31.0282 22.3398 38.3209 18.9267 45.8335 19.4806Z"
      fill="#FFC107"
    />
    <path
      d="M63.5352 28.4923C63.3547 28.2269 62.9933 28.1581 62.728 28.3385C62.4626 28.519 62.3937 28.8804 62.5742 29.1458C66.8101 35.3749 67.5006 43.3972 63.708 49.9662C63.5476 50.2441 63.6428 50.5995 63.9207 50.76C64.1986 50.9204 64.554 50.8252 64.7145 50.5473C68.7537 43.551 67.9941 35.0493 63.5352 28.4923Z"
      fill="#FFC107"
    />
  </svg>
)
