import {DocumentDto, DocumentVersionDto, IResponseError} from 'ac-brand-share'
import {LegalApi} from './LegalApi'

export class LegalService {
  private documents: DocumentDto[]
  private legalApi: LegalApi

  constructor(documents: DocumentDto[], setError: (error: IResponseError) => void) {
    this.documents = documents
    this.legalApi = new LegalApi(setError)
  }

  public getDocument(documentUrl: string): DocumentDto {
    if (documentUrl) {
      return this.documents.find((d) => d.url === documentUrl)
    } else {
      return null
    }
  }

  public getVersion(document: DocumentDto, versionId: string): DocumentVersionDto {
    if (versionId) {
      return document.versions.find((v) => v.documentVersionId === versionId)
    } else {
      return document.versions[0]
    }
  }
}
