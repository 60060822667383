import {INITIAL_STATE, TariffPageProps, TariffPageState} from '../TariffPageContainer'
import {TariffApi} from './TariffApi'
import {
  InvoiceDto,
  PaymentPeriodDto,
  PaymentSystemEnum,
  ResponseError,
  TariffAndPeriodsDto,
  WalletDto,
} from 'ac-brand-share'
import {PaymentApi} from '../../payment/services/PaymentApi'
import {getAuthPageUrl} from '../../../utils/getAuthPageUrl'
import {CONFIG} from '../../../constants'
import {SubjectApi} from './SubjectApi'
import {ExternalPaymentDto, InvoiceBoxPaymentDto, PaymentTypeEnum, SubjectDto} from 'ac-brand-auth-share'

class TariffPageManager {
  private setState: (state: TariffPageState) => void
  private state: TariffPageState
  private props: TariffPageProps
  private tariffApi: TariffApi
  private paymentApi: PaymentApi
  private subjectApi: SubjectApi

  constructor(setState: (state: TariffPageState) => void) {
    this.setState = setState
    this.state = {...INITIAL_STATE}
    this.tariffApi = new TariffApi()
    this.paymentApi = new PaymentApi()
    this.subjectApi = new SubjectApi()
  }

  private setLocalState(newState: TariffPageState, isDispatch = true): void {
    this.state = {...this.state, ...newState}
    if (isDispatch) {
      this.dispatchState()
    }
  }

  private dispatchState(): void {
    this.setState({...this.state})
  }

  private redirectToAuth(): void {
    window.location.replace(getAuthPageUrl(CONFIG.crmSiteWeb + '/tariff'))
  }

  public initialize(props: TariffPageProps): void {
    this.props = props
  }

  public async loadData(): Promise<void> {
    const {user} = this.props
    const tariffsAndPeriods: TariffAndPeriodsDto = await this.tariffApi.findTariffsAndPeriods()
    const enabledPaymentTypes: PaymentTypeEnum[] = await this.paymentApi.getEnabledPaymentTypes()
    const selectedPeriodId: string = tariffsAndPeriods?.periods[0]?.subscriptionPeriodId
    const selectedTariffId: string = tariffsAndPeriods?.tariffs[0]?.tariffId
    const isHasDemo: boolean = await this.tariffApi.isHasDemo()
    let wallet: WalletDto = null
    let isAutoPaymentBonus: boolean = false
    let subjects: SubjectDto[] = null
    if (user) {
      wallet = await this.tariffApi.findMyWallet()
      subjects = await this.subjectApi.getMyExecutors()
      isAutoPaymentBonus = wallet.isAutoPayment
    }

    this.setLocalState({
      ...this.state,
      tariffsAndPeriods,
      enabledPaymentTypes,
      selectedPeriodId,
      selectedTariffId,
      isHasDemo,
      wallet,
      isAutoPaymentBonus,
      subjects,
    })
  }

  public onChangeState(key: string, value: any): void {
    const newState = {...this.state}
    newState[key] = value
    this.setLocalState(newState)
  }

  public onSubmitTariffSelectFormModal = async (data: {
    isFree: boolean
    type: PaymentTypeEnum
    payment: PaymentPeriodDto
    invoice: InvoiceDto
  }): Promise<void> => {
    const {isFree, type, payment, invoice} = data
    if (isFree) {
      throw new ResponseError(500, 'Бесплатные тарифы не поддерживаются')
    } else {
      if (type === PaymentTypeEnum.ONLINE) {
        const newState = {...this.state}
        const externalPayment: ExternalPaymentDto = await this.paymentApi.pay(payment)
        if (externalPayment.paymentSystem === PaymentSystemEnum.INVOICEBOX) {
          window.location.href = (externalPayment.payment as InvoiceBoxPaymentDto).paymentUrl
        }
        newState.externalPayment = externalPayment
        newState.isOpenTariffSelectModal = false
        this.setLocalState(newState)
      } else if (type === PaymentTypeEnum.INVOICE) {
        await this.createInvoice(invoice)
      }
    }
  }

  public async createInvoice(invoice: InvoiceDto): Promise<InvoiceDto> {
    const createdInvoice = await this.paymentApi.createInvoice(invoice)
    this.setLocalState({
      ...this.state,
      invoice: createdInvoice,
    })
    return createdInvoice
  }

  public async createDemo(): Promise<void> {
    const {user} = this.props
    if (user) {
      await this.tariffApi.createDemo()
      window.location.replace(CONFIG.executorAuthWeb)
    } else {
      this.redirectToAuth()
    }
  }

  public openPaymentModal(tariffId: string) {
    const {user} = this.props
    if (user) {
      this.setLocalState({
        ...this.state,
        selectedTariffId: tariffId,
        isOpenTariffSelectModal: true,
      })
    } else {
      this.redirectToAuth()
    }
  }

  public closePaymentModal() {
    this.setLocalState({
      ...this.state,
      isOpenTariffSelectModal: false,
    })
  }
}

export {TariffPageManager}
