import React, {memo, ReactNode} from 'react'
import {ButtonLink, Container, Loading} from 'ac-brand-ui'
import {Footer} from '../../components/footer/Footer'
import {PaymentSuccessSVG} from './components/PaymentSuccessSVG'
import {PaymentPendingSVG} from './components/PaymentPendingSVG'
import {PaymentFailureSVG} from './components/PaymentFailureSVG'
import Header from '../../components/header/Header'
import {CONFIG} from '../../constants'
import {PaymentStatusEnum} from 'ac-brand-auth-share'

interface PaymentFinishProps {
  status: PaymentStatusEnum | null
}

const PaymentFinishPage: React.FC<PaymentFinishProps> = memo(({status}) => {
  const renderPaymentSuccess = (): React.ReactNode => {
    return (
      <>
        {PaymentSuccessSVG}
        <h4 className={'text-center mb-x2'}>
          Поздравляем, вы <br /> оформили подписку!
        </h4>
        <p className={'text-body-2 text-center mb-x6'}>
          Теперь вы можете в полной <br />
          мере воспользоваться <br />
          функциями платформы
        </p>
        <ButtonLink href={CONFIG.executorAuthWeb} className={'mb-x6'}>
          В профиль
        </ButtonLink>
      </>
    )
  }

  const renderPaymentPending = (): React.ReactNode => {
    return (
      <>
        {PaymentPendingSVG}
        <h4 className={'text-center mb-x2'}>
          Платеж в обработке, <br />
          ожидайте…
        </h4>
        <p className={'text-body-2 text-center mb-x6'}>
          После обработки платежа <br />
          подписка автоматически
          <br /> активируется
        </p>
        <ButtonLink href={CONFIG.executorAuthWeb} className={'mb-x6'}>
          В профиль
        </ButtonLink>
      </>
    )
  }

  const renderPaymentFailure = (): React.ReactNode => {
    return (
      <>
        {PaymentFailureSVG}
        <h4 className={'text-center mb-x2'}>
          Что-то пошло не так,
          <br /> попробуйте еще раз
        </h4>
        <p className={'text-body-2 text-center mb-x6'}>
          После обработки платежа <br />
          подписка автоматически
          <br /> активируется
        </p>
        <ButtonLink className={'mb-x6'} href={'/site/tariff'}>
          Попробовать снова
        </ButtonLink>
      </>
    )
  }

  const renderContent = (): ReactNode => {
    if (!status) {
      return <Loading />
    }

    switch (status) {
      case PaymentStatusEnum.succeeded: {
        return renderPaymentSuccess()
      }
      case PaymentStatusEnum.pending: {
        return renderPaymentPending()
      }
      default: {
        return renderPaymentFailure()
      }
    }
  }

  return (
    <div className={'content'}>
      <Header />
      <Container>
        <div className={'payment-info'}>{renderContent()}</div>
      </Container>
      <Footer />
    </div>
  )
})

export {PaymentFinishPage}
