import React, {memo, useRef} from 'react'
import {UserDto} from 'ac-brand-share'
import {Footer} from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import {CanTellMore} from './components/CanTellMore'
import {WeAlsoHave} from './components/WeAlsoHave'
import {SuitableForWhom} from './components/SuitableForWhom'
import {MainInfo} from './components/MainInfo'
import {ContactForm} from './components/ContactForm'

interface LandingProps {
  user: UserDto
}

const Landing: React.FC<LandingProps> = memo(({user}): React.ReactElement => {
  const canTellMoreRef: any = useRef(null)
  const contactFormRef: any = useRef(null)

  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({behavior: 'smooth'})
    }
  }

  return (
    <main className={'content landing-content'}>
      <Header
        onTry={(e) => {
          e.preventDefault()
          scrollToRef(contactFormRef)
        }}
      />
      <MainInfo
        onTry={(e) => {
          e.preventDefault()
          scrollToRef(contactFormRef)
        }}
        onGetMore={(e) => {
          e.preventDefault()
          scrollToRef(canTellMoreRef)
        }}
      />
      <CanTellMore canTellMoreRef={canTellMoreRef} />
      <WeAlsoHave />
      <SuitableForWhom />
      {/*<Reviews />*/}
      <ContactForm contactFormRef={contactFormRef} />
      <Footer />
    </main>
  )
})

export {Landing}
