import React from 'react'

export const SocialTelegramSvg: React.ReactNode = (
  <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.97577 15.6781C8.36256 16.1298 9.17756 16.4494 9.94219 16.7492C10.9369 17.1391 12.1986 17.6603 13.208 17.1012C15.0086 16.0605 16.6745 14.8041 18.3823 13.6199C20.0411 12.5526 16.3441 16.3568 15.5638 17.1305C15.1264 17.4881 14.6722 18.2073 15.162 18.7081C16.6572 19.7612 18.1834 20.7805 19.698 21.8101C20.3282 22.3164 21.3132 21.9068 21.4517 21.1157C21.9072 18.4249 22.3599 15.7342 22.7573 13.0332C22.8701 12.2657 22.9832 11.4973 23.0185 10.7257C22.9275 9.95566 21.9996 10.125 21.4832 10.2981C17.2865 11.9049 12.9353 13.4836 8.97577 15.6781Z"
      fill="#EAEEF5"
    />
    <rect
      x="5.46631"
      y="4.96571"
      width="22.069"
      height="22.069"
      rx="4"
      stroke="#8A94A6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
