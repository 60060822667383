declare var EXECUTOR_AUTH_WEB: string
declare var EXECUTOR_CRM_WEB: string
declare var CDN_WEB: string
declare var CRM_SITE_WEB: string
declare var API_URL: string
declare var HELP_WEB: string


const CONFIG = {
  executorAuthWeb: EXECUTOR_AUTH_WEB,
  executorCrmWeb: EXECUTOR_CRM_WEB,
  crmUrlApi: EXECUTOR_CRM_WEB + '/api',
  cdnUrl: CDN_WEB,
  apiUrl: API_URL + '/api',
  crmSiteWeb: CRM_SITE_WEB,
}

export {CONFIG}
