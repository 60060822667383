import React from 'react'
import {connectComponent, errorActions} from 'ac-brand-share-client'
import {Landing} from './Landing'
import {UserDto} from 'ac-brand-share'

interface LandingContainerProps {
  user: UserDto
}

const LandingContainer: React.FC<LandingContainerProps> = ({user}): React.ReactElement => {
  return <Landing user={user} />
}

export default connectComponent(
  (state) => ({
    user: state.auth.user,
  }),
  (dispatch: any) => ({
    errorActions: errorActions(dispatch),
  }),
  LandingContainer,
)
