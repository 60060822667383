import React from 'react'

export const TaskSvg: React.ReactNode = (
  <svg width="201" height="200" viewBox="0 0 201 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M91.0391 33.1078C91.0391 29.0029 94.3667 25.6753 98.4715 25.6753C102.576 25.6753 105.904 29.0029 105.904 33.1078C105.904 37.2126 102.576 40.5402 98.4715 40.5402C94.3667 40.5402 91.0391 37.2126 91.0391 33.1078ZM98.4715 28.378C95.8593 28.378 93.7418 30.4956 93.7418 33.1078C93.7418 35.7199 95.8593 37.8375 98.4715 37.8375C101.084 37.8375 103.201 35.7199 103.201 33.1078C103.201 30.4956 101.084 28.378 98.4715 28.378Z"
      fill="#43468F"
    />
    <path
      d="M80.5123 71.967C81.064 72.4697 81.1038 73.3244 80.6011 73.8761L76.2048 78.7013C75.7228 79.2303 74.9119 79.2918 74.3556 78.8415L72.1039 77.0186C71.5238 76.549 71.4342 75.6981 71.9038 75.118C72.3734 74.538 73.2243 74.4484 73.8044 74.918L75.0654 75.9388L78.6033 72.0558C79.1059 71.5042 79.9606 71.4644 80.5123 71.967Z"
      fill="#43468F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M71.4445 66.2162C69.2055 66.2162 67.3904 68.0312 67.3904 70.2702V79.7297C67.3904 81.9687 69.2055 83.7837 71.4445 83.7837H80.9039C83.1429 83.7837 84.958 81.9687 84.958 79.7297V70.2702C84.958 68.0312 83.1429 66.2162 80.9039 66.2162H71.4445ZM70.0931 70.2702C70.0931 69.5239 70.6981 68.9189 71.4445 68.9189H80.9039C81.6503 68.9189 82.2553 69.5239 82.2553 70.2702V79.7297C82.2553 80.476 81.6503 81.081 80.9039 81.081H71.4445C70.6981 81.081 70.0931 80.476 70.0931 79.7297V70.2702Z"
      fill="#43468F"
    />
    <path
      d="M89.6877 70.2702C89.6877 69.1507 90.5952 68.2432 91.7147 68.2432H130.228C131.348 68.2432 132.255 69.1507 132.255 70.2702C132.255 71.3897 131.348 72.2972 130.228 72.2972H91.7147C90.5952 72.2972 89.6877 71.3897 89.6877 70.2702Z"
      fill="#43468F"
    />
    <path
      d="M91.7147 76.3513C90.5952 76.3513 89.6877 77.2588 89.6877 78.3783C89.6877 79.4978 90.5952 80.4053 91.7147 80.4053H111.985C113.105 80.4053 114.012 79.4978 114.012 78.3783C114.012 77.2588 113.105 76.3513 111.985 76.3513H91.7147Z"
      fill="#43468F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M71.4445 91.8918C69.2055 91.8918 67.3904 93.7069 67.3904 95.9459V105.405C67.3904 107.644 69.2055 109.459 71.4445 109.459H80.9039C83.1429 109.459 84.958 107.644 84.958 105.405V95.9459C84.958 93.7069 83.1429 91.8918 80.9039 91.8918H71.4445ZM70.0931 95.9459C70.0931 95.1996 70.6981 94.5945 71.4445 94.5945H80.9039C81.6503 94.5945 82.2553 95.1996 82.2553 95.9459V105.405C82.2553 106.152 81.6503 106.757 80.9039 106.757H71.4445C70.6981 106.757 70.0931 106.152 70.0931 105.405V95.9459Z"
      fill="#43468F"
    />
    <path
      d="M89.6877 95.9459C89.6877 94.8264 90.5952 93.9189 91.7147 93.9189H110.634C111.753 93.9189 112.661 94.8264 112.661 95.9459C112.661 97.0654 111.753 97.9729 110.634 97.9729H91.7147C90.5952 97.9729 89.6877 97.0654 89.6877 95.9459Z"
      fill="#43468F"
    />
    <path
      d="M91.7147 102.027C90.5952 102.027 89.6877 102.934 89.6877 104.054C89.6877 105.173 90.5952 106.081 91.7147 106.081H123.471C124.591 106.081 125.499 105.173 125.499 104.054C125.499 102.934 124.591 102.027 123.471 102.027H91.7147Z"
      fill="#43468F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.3904 121.622C67.3904 119.383 69.2055 117.568 71.4445 117.568H80.9039C83.1429 117.568 84.958 119.383 84.958 121.622V131.081C84.958 133.32 83.1429 135.135 80.9039 135.135H71.4445C69.2055 135.135 67.3904 133.32 67.3904 131.081V121.622ZM71.4445 120.27C70.6981 120.27 70.0931 120.875 70.0931 121.622V131.081C70.0931 131.827 70.6981 132.432 71.4445 132.432H80.9039C81.6503 132.432 82.2553 131.827 82.2553 131.081V121.622C82.2553 120.875 81.6503 120.27 80.9039 120.27H71.4445Z"
      fill="#43468F"
    />
    <path
      d="M91.7147 119.595C90.5952 119.595 89.6877 120.502 89.6877 121.622C89.6877 122.741 90.5952 123.649 91.7147 123.649H122.12C123.24 123.649 124.147 122.741 124.147 121.622C124.147 120.502 123.24 119.595 122.12 119.595H91.7147Z"
      fill="#43468F"
    />
    <path
      d="M89.6877 129.73C89.6877 128.61 90.5952 127.703 91.7147 127.703H103.201C104.321 127.703 105.228 128.61 105.228 129.73C105.228 130.849 104.321 131.757 103.201 131.757H91.7147C90.5952 131.757 89.6877 130.849 89.6877 129.73Z"
      fill="#43468F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M71.4445 141.892C69.2055 141.892 67.3904 143.707 67.3904 145.946V155.405C67.3904 157.644 69.2055 159.459 71.4445 159.459H80.9039C83.1429 159.459 84.958 157.644 84.958 155.405V145.946C84.958 143.707 83.1429 141.892 80.9039 141.892H71.4445ZM70.0931 145.946C70.0931 145.2 70.6981 144.595 71.4445 144.595H80.9039C81.6503 144.595 82.2553 145.2 82.2553 145.946V155.405C82.2553 156.152 81.6503 156.757 80.9039 156.757H71.4445C70.6981 156.757 70.0931 156.152 70.0931 155.405V145.946Z"
      fill="#43468F"
    />
    <path
      d="M89.6877 145.946C89.6877 144.826 90.5952 143.919 91.7147 143.919H120.093C121.213 143.919 122.12 144.826 122.12 145.946C122.12 147.065 121.213 147.973 120.093 147.973H91.7147C90.5952 147.973 89.6877 147.065 89.6877 145.946Z"
      fill="#43468F"
    />
    <path
      d="M91.7147 152.027C90.5952 152.027 89.6877 152.934 89.6877 154.054C89.6877 155.173 90.5952 156.081 91.7147 156.081H107.931C109.05 156.081 109.958 155.173 109.958 154.054C109.958 152.934 109.05 152.027 107.931 152.027H91.7147Z"
      fill="#43468F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M128.179 31.7566H111.918C111.24 24.9277 105.479 19.5942 98.4715 19.5942C91.4642 19.5942 85.7026 24.9277 85.0247 31.7566H68.7642L68.7418 31.7564L68.7193 31.7566H61.3093C54.5924 31.7566 49.1472 37.2018 49.1472 43.9187V166.892C49.1472 173.609 54.5924 179.054 61.3093 179.054H135.634C142.351 179.054 147.796 173.609 147.796 166.892V43.9187C147.796 37.2018 142.351 31.7566 135.634 31.7566H128.224L128.201 31.7564L128.179 31.7566ZM85.9715 34.4593C86.029 34.4593 86.0856 34.4557 86.1411 34.4487C86.1962 34.4556 86.2524 34.4591 86.3093 34.4591C87.0557 34.4591 87.6607 33.8541 87.6607 33.1078C87.6607 27.1371 92.5009 22.2969 98.4715 22.2969C104.442 22.2969 109.282 27.1371 109.282 33.1078C109.282 33.1543 109.285 33.2005 109.289 33.2459C109.294 33.2968 109.302 33.3469 109.313 33.396C109.445 34.0039 109.986 34.4593 110.634 34.4593H126.85V39.1888C126.85 44.04 122.917 47.9726 118.066 47.9726H78.8769C74.0258 47.9726 70.0931 44.04 70.0931 39.1888V34.4593H85.9715ZM129.553 39.1888V34.4593H135.634C140.858 34.4593 145.093 38.6944 145.093 43.9187V166.892C145.093 172.116 140.858 176.351 135.634 176.351H61.3093C56.085 176.351 51.8499 172.116 51.8499 166.892V43.9187C51.8499 38.6944 56.085 34.4593 61.3093 34.4593H67.3904V39.1888C67.3904 45.5326 72.5331 50.6753 78.8769 50.6753H118.066C124.41 50.6753 129.553 45.5326 129.553 39.1888Z"
      fill="#43468F"
    />
    <path
      d="M41.0391 43.9189C41.0391 33.0971 49.8119 24.3243 60.6337 24.3243C61.38 24.3243 61.985 24.9294 61.985 25.6757C61.985 26.422 61.38 27.027 60.6337 27.027C51.3045 27.027 43.7418 34.5898 43.7418 43.9189V59.375C43.7418 60.1213 43.1367 60.7264 42.3904 60.7264C41.6441 60.7264 41.0391 60.1213 41.0391 59.375V43.9189Z"
      fill="#43468F"
    />
    <path
      d="M134.958 25.6757C134.958 24.9294 135.563 24.3243 136.309 24.3243C147.131 24.3243 155.904 33.0971 155.904 43.9189C155.904 44.6653 155.299 45.2703 154.553 45.2703C153.806 45.2703 153.201 44.6653 153.201 43.9189C153.201 34.5898 145.638 27.027 136.309 27.027C135.563 27.027 134.958 26.422 134.958 25.6757Z"
      fill="#43468F"
    />
    <path
      d="M42.3904 65.7517C43.1367 65.7517 43.7418 66.3567 43.7418 67.1031V74.8311C43.7418 75.5774 43.1367 76.1824 42.3904 76.1824C41.6441 76.1824 41.0391 75.5774 41.0391 74.8311V67.1031C41.0391 66.3567 41.6441 65.7517 42.3904 65.7517Z"
      fill="#43468F"
    />
    <path
      d="M42.3904 135.304C43.1367 135.304 43.7418 135.909 43.7418 136.655V167.568C43.7418 176.897 51.3045 184.459 60.6337 184.459C61.38 184.459 61.985 185.064 61.985 185.811C61.985 186.557 61.38 187.162 60.6337 187.162C49.8119 187.162 41.0391 178.389 41.0391 167.568V136.655C41.0391 135.909 41.6441 135.304 42.3904 135.304Z"
      fill="#43468F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M158.944 63.5135C156.519 63.5135 154.553 65.4798 154.553 67.9054V153.276C154.553 153.635 154.624 153.99 154.762 154.32L157.698 161.333C157.908 161.835 158.4 162.162 158.944 162.162C159.489 162.162 159.981 161.835 160.191 161.333L163.127 154.32C163.265 153.99 163.336 153.635 163.336 153.277V67.9054C163.336 65.4798 161.37 63.5135 158.944 63.5135ZM157.255 67.9054C157.255 66.9725 158.012 66.2162 158.944 66.2162C159.877 66.2162 160.634 66.9725 160.634 67.9054V74.5989H157.255V67.9054ZM157.255 153.276V77.3016H160.634V153.277L158.944 157.311L157.255 153.276Z"
      fill="#43468F"
    />
  </svg>
)
