import React, {useCallback, useMemo} from 'react'
import {Col, Container, Row} from 'ac-brand-ui'
import {NewMailSvg} from './svg/NewMailSvg'
import {IpoSvg} from './svg/IpoSvg'
import {WarningSvg} from './svg/WarningSvg'
import {WelcomeSvg} from './svg/WelcomeSvg'
import {FeeSvg} from './svg/FeeSvg'
import {ApplicationSvg} from './svg/ApplicationSvg'
import {EmptySvg} from './svg/EmptySvg'
import {LinkApplicationSvg} from './svg/LinkApplicationSvg'
import {EmptyNotificationSvg} from './svg/EmptyNotificationSvg'
import {RecommendationsSvg} from './svg/RecommendationsSvg'
import {RecommendationsSmallSvg} from './svg/RecommendationsSmallSvg'
import {NewMailSmallSvg} from './svg/NewMailSmallSvg'

interface CanTellMoreProps {
  canTellMoreRef: any
}

interface CanTellMoreItemType {
  id: string
  iconReactElement: React.ReactNode
  text: string
}

interface CanTellMoreType {
  id: string
  img: string
  svgPosition: 'left' | 'right'
  title: string
  description: string
  items: CanTellMoreItemType[]
}

const LIST: CanTellMoreType[] = [
  {
    id: '1',
    img: 'IllustrRospatent.png',
    svgPosition: 'right',
    title: 'Синхронизация с Роспатентом',
    description:
      'Работая на платформе, вы получаете всю актуальную информацию по вашей заявке с сайта Роспатента и больше нет необходимости в переключении между разными системами.',
    items: [
      {
        id: '1',
        iconReactElement: NewMailSvg,
        text: 'Уведомления о новых письмах',
      },
      {
        id: '2',
        iconReactElement: IpoSvg,
        text: 'Быстрый доступ к извещениям по ОИС',
      },
      {
        id: '3',
        iconReactElement: WarningSvg,
        text: 'Проверка на ошибки при вводе данных',
      },
    ],
  },
  {
    id: '2',
    img: 'IllustrDoc.png',
    svgPosition: 'left',
    title: 'Гибкая работа с документами',
    description:
      'Платформа возьмет на себя хранение и генерацию документов. В них уже будут внесены данные о заказчиках, которые вам останется только проверить.',
    items: [
      {
        id: '1',
        iconReactElement: WelcomeSvg,
        text: 'Хранение документов на платформе',
      },
      {
        id: '2',
        iconReactElement: FeeSvg,
        text: 'Шаблоны счета, акта, договора',
      },
      {
        id: '3',
        iconReactElement: ApplicationSvg,
        text: 'Шаблоны по форме Роспатента',
      },
    ],
  },
  {
    id: '3',
    img: 'IllustrMKTU.png',
    svgPosition: 'right',
    title: 'Удобная работа с классами МКТУ',
    description:
      'Мы взяли привычную модель работы с классами из АРМ Регистратора и улучшили ее.  Дополнительная система проверки терминов позволит уменьшить количество ошибок при ручном вводе.',
    items: [
      {
        id: '1',
        iconReactElement: IpoSvg,
        text: 'Актуальный справочник МКТУ',
      },
      {
        id: '2',
        iconReactElement: EmptySvg,
        text: 'Удобный поиск и выбор терминов',
      },
      {
        id: '3',
        iconReactElement: LinkApplicationSvg,
        text: 'Сравнение МКТУ между заявками',
      },
    ],
  },
  {
    id: '4',
    img: 'IllustrNotification.png',
    svgPosition: 'left',
    title: 'Контроль всего и сразу',
    description:
      'Платформа не позволит ничего забыть. Контроль сроков поддержания охранных документов в силе, контроль сроков уплаты пошлин, напоминание о совершении действий, например, ответы на запросы Роспатента.',
    items: [
      {
        id: '1',
        iconReactElement: EmptyNotificationSvg,
        text: 'Напоминания о продлении ОИС',
      },
      {
        id: '2',
        iconReactElement: RecommendationsSmallSvg,
        text: 'Напоминания об уплате пошлин',
      },
      {
        id: '3',
        iconReactElement: NewMailSmallSvg,
        text: 'Уведомления об изменениях в ФНС',
      },
    ],
  },
]

const CanTellMore: React.FC<CanTellMoreProps> = ({canTellMoreRef}): React.ReactElement => {
  const renderItems = useCallback((items: CanTellMoreItemType[]) => {
    let tsxItems: React.ReactElement[] = []
    for (let i = 0; i < items.length; i++) {
      let item: CanTellMoreItemType = items[i]
      tsxItems.push(
        <div key={item.id} className={'can-tell-more-block-item ' + (i !== items.length - 1 ? 'margin' : '')}>
          <div className={'can-tell-more-block-item-img'}>{item.iconReactElement}</div>
          <p className={'can-tell-more-block-item-text'}>{item.text}</p>
        </div>,
      )
    }
    return <div className={'can-tell-more-block-items'}>{tsxItems}</div>
  }, [])

  const renderBlocks = useCallback(
    (list: CanTellMoreType[]): React.ReactNode => {
      let items: React.ReactElement[] = []
      for (let i = 0; i < list.length; i++) {
        const item: CanTellMoreType = list[i]
        if (item.svgPosition === 'right') {
          items.push(
            <Row key={item.id} className={i !== list.length - 1 ? 'can-tell-more-margin' : ''}>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className={'can-tell-more-block ' + item.svgPosition}>
                  <p className={'can-tell-more-block-title'}>{item.title}</p>
                  <p className={'can-tell-more-block-description'}>{item.description}</p>
                  {renderItems(item.items)}
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <img
                  src={'/site/img/landing/' + item.img}
                  alt={'Изображение'}
                  className={'can-tell-more-img'}
                  width={'100%'}
                />
              </Col>
            </Row>,
          )
        } else {
          items.push(
            <Row key={item.id} className={i !== list.length - 1 ? 'can-tell-more-margin' : ''}>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <img
                  src={'/site/img/landing/' + item.img}
                  alt={'Изображение'}
                  className={'can-tell-more-img'}
                  width={'100%'}
                />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className={'can-tell-more-block ' + item.svgPosition}>
                  <p className={'can-tell-more-block-title'}>{item.title}</p>
                  <p className={'can-tell-more-block-description'}>{item.description}</p>
                  {renderItems(item.items)}
                </div>
              </Col>
            </Row>,
          )
        }
      }
      return <div>{items}</div>
    },
    [renderItems],
  )

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <section className={'can-tell-more'} ref={canTellMoreRef}>
        <Container>
          <h2 className={'can-tell-more-h2'}>Рассказать подробнее?</h2>
          {renderBlocks(LIST)}
        </Container>
      </section>
    )
  }, [canTellMoreRef, renderBlocks])

  return <>{renderContent}</>
}

export {CanTellMore}
