import {DocumentDto, IResponseError} from 'ac-brand-share'
import {apiGet} from 'ac-brand-share-client'
import {CONFIG} from '../../../constants'

export class LegalApi {
  private setError: (error: IResponseError) => void

  constructor(setError: (error: IResponseError) => void) {
    this.setError = setError
  }

  public async getDocument(documentId: string): Promise<DocumentDto> {
    return await apiGet(CONFIG.crmUrlApi + '/legal/' + documentId, null, this.setError)
  }

  public async getAllDocuments(): Promise<DocumentDto[]> {
    return await apiGet(CONFIG.crmUrlApi + '/legal/', null, this.setError)
  }
}
