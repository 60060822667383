import React from 'react'

export const EmptySvg: React.ReactNode = (
  <svg width="87" height="86" viewBox="0 0 87 86" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.0586 46.3412C26.0586 35.831 34.5788 27.3108 45.089 27.3108C55.5992 27.3108 64.1194 35.831 64.1194 46.3412C64.1194 56.8514 55.5992 65.3716 45.089 65.3716C34.5788 65.3716 26.0586 56.8514 26.0586 46.3412ZM45.089 28.473C35.2206 28.473 27.2208 36.4728 27.2208 46.3412C27.2208 56.2096 35.2206 64.2094 45.089 64.2094C54.9574 64.2094 62.9572 56.2096 62.9572 46.3412C62.9572 36.4728 54.9574 28.473 45.089 28.473Z"
      fill="#43468F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.1289 46.3413C30.1289 38.0775 36.828 31.3784 45.0917 31.3784C53.3555 31.3784 60.0546 38.0775 60.0546 46.3413C60.0546 54.605 53.3555 61.3041 45.0917 61.3041C36.828 61.3041 30.1289 54.605 30.1289 46.3413ZM45.0917 32.5406C37.4698 32.5406 31.2911 38.7194 31.2911 46.3413C31.2911 53.9632 37.4698 60.1419 45.0917 60.1419C52.7136 60.1419 58.8924 53.9632 58.8924 46.3413C58.8924 38.7194 52.7136 32.5406 45.0917 32.5406Z"
      fill="#43468F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M76.2514 22.2747C75.6363 22.2747 75.1376 22.7734 75.1376 23.3885C75.1376 24.0036 75.6363 24.5022 76.2514 24.5022C76.8665 24.5022 77.3651 24.0036 77.3651 23.3885C77.3651 22.7734 76.8665 22.2747 76.2514 22.2747ZM72.9102 23.3885C72.9102 21.5432 74.4061 20.0472 76.2514 20.0472C78.0967 20.0472 79.5926 21.5432 79.5926 23.3885C79.5926 25.2338 78.0967 26.7297 76.2514 26.7297C74.4061 26.7297 72.9102 25.2338 72.9102 23.3885Z"
      fill="#A8B2C4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.544 38.3514C16.2231 38.3514 15.9629 38.6115 15.9629 38.9325C15.9629 39.2534 16.2231 39.5135 16.544 39.5135C16.8649 39.5135 17.1251 39.2534 17.1251 38.9325C17.1251 38.6115 16.8649 38.3514 16.544 38.3514ZM14.8008 38.9325C14.8008 37.9697 15.5813 37.1892 16.544 37.1892C17.5068 37.1892 18.2873 37.9697 18.2873 38.9325C18.2873 39.8952 17.5068 40.6757 16.544 40.6757C15.5813 40.6757 14.8008 39.8952 14.8008 38.9325Z"
      fill="#A8B2C4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.6104 8.71624C63.2895 8.71624 63.0294 8.9764 63.0294 9.29732C63.0294 9.61824 63.2895 9.8784 63.6104 9.8784C63.9314 9.8784 64.1915 9.61824 64.1915 9.29732C64.1915 8.9764 63.9314 8.71624 63.6104 8.71624ZM61.8672 9.29732C61.8672 8.33455 62.6477 7.55408 63.6104 7.55408C64.5732 7.55408 65.3537 8.33455 65.3537 9.29732C65.3537 10.2601 64.5732 11.0406 63.6104 11.0406C62.6477 11.0406 61.8672 10.2601 61.8672 9.29732Z"
      fill="#A8B2C4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.70143 54.1802C10.3556 52.8319 12.2765 52.8319 12.9307 54.1802L13.3068 54.9553L14.0819 55.3313C15.4302 55.9855 15.4302 57.9064 14.0819 58.5606L13.3068 58.9367L12.9307 59.7117C12.2765 61.0601 10.3556 61.0601 9.70143 59.7117L9.32538 58.9367L8.55033 58.5606C7.20198 57.9064 7.20197 55.9855 8.55033 55.3313L9.32538 54.9553L9.70143 54.1802ZM11.6921 55.7387L11.3161 54.9636L10.94 55.7387C10.7641 56.1012 10.4713 56.394 10.1088 56.5699L9.33374 56.946L10.1088 57.322C10.4713 57.4979 10.7641 57.7907 10.94 58.1533L11.3161 58.9283L11.6921 58.1533C11.868 57.7907 12.1608 57.4979 12.5234 57.322L13.2984 56.946L12.5234 56.5699C12.1608 56.394 11.868 56.1012 11.6921 55.7387Z"
      fill="#FFC107"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79.2479 46.2697C79.6716 45.3966 80.9155 45.3966 81.3391 46.2697L81.5826 46.7716L82.0845 47.0151C82.9577 47.4388 82.9577 48.6827 82.0845 49.1063L81.5826 49.3498L81.3391 49.8517C80.9155 50.7249 79.6716 50.7249 79.2479 49.8517L79.0044 49.3498L78.5025 49.1063C77.6294 48.6827 77.6294 47.4388 78.5025 47.0151L79.0044 46.7716L79.2479 46.2697ZM80.537 47.2789L80.2935 46.777L80.05 47.2789C79.9361 47.5137 79.7465 47.7033 79.5117 47.8172L79.0098 48.0607L79.5117 48.3042C79.7465 48.4181 79.9361 48.6078 80.05 48.8425L80.2935 49.3444L80.537 48.8425C80.6509 48.6078 80.8405 48.4181 81.0753 48.3042L81.5772 48.0607L81.0753 47.8172C80.8405 47.7033 80.6509 47.5137 80.537 47.2789Z"
      fill="#FFC107"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M54.7395 12.58L67.6453 27.8857L67.7133 70.6014C67.7159 72.2294 66.3972 73.5503 64.769 73.5528L25.3621 73.6147C24.4347 73.6162 23.6062 73.189 23.0654 72.5222L23.9681 71.7902C24.2971 72.1959 24.7984 72.4535 25.3603 72.4526L64.7672 72.3907C65.7545 72.3891 66.5527 71.5884 66.5511 70.6033L66.4844 28.6803L56.8623 28.6955C55.2341 28.698 53.9113 27.3813 53.9087 25.7533L53.8896 13.7435L25.2669 13.7885C24.2796 13.79 23.4815 14.5908 23.483 15.5759L23.5597 63.7726L22.3975 63.7744L22.3209 15.5777C22.3183 13.9498 23.637 12.6289 25.2651 12.6263L54.7395 12.58ZM55.0534 14.7551L55.0709 25.7515C55.0724 26.7366 55.8732 27.5348 56.8605 27.5333L65.8162 27.5192L55.0534 14.7551Z"
      fill="#43468F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.7461 19.4663C28.7461 19.1454 29.0063 18.8853 29.3272 18.8853H45.3069C45.6278 18.8853 45.888 19.1454 45.888 19.4663C45.888 19.7873 45.6278 20.0474 45.3069 20.0474H29.3272C29.0063 20.0474 28.7461 19.7873 28.7461 19.4663ZM29.0366 23.8244C29.0366 23.5035 29.2968 23.2434 29.6177 23.2434H39.4961C39.817 23.2434 40.0772 23.5035 40.0772 23.8244C40.0772 24.1454 39.817 24.4055 39.4961 24.4055H29.6177C29.2968 24.4055 29.0366 24.1454 29.0366 23.8244ZM29.0366 27.892C29.0366 27.5711 29.2968 27.3109 29.6177 27.3109H34.5569C34.8778 27.3109 35.138 27.5711 35.138 27.892C35.138 28.2129 34.8778 28.4731 34.5569 28.4731H29.6177C29.2968 28.4731 29.0366 28.2129 29.0366 27.892Z"
      fill="#43468F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.3659 58.106L27.5561 61.9158C27.2043 62.2676 26.7324 62.3775 26.3264 62.358C25.6295 62.3244 24.9238 62.5734 24.3926 63.1047L13.8064 73.6909C12.8068 74.6904 12.8068 76.3111 13.8064 77.3107C14.806 78.3102 16.4266 78.3102 17.4262 77.3107L28.0124 66.7245C28.5434 66.1934 28.7924 65.488 28.7591 64.7913C28.7397 64.3855 28.8497 63.9138 29.2013 63.5621L33.0117 59.7517L33.8335 60.5735L30.0231 64.3839C29.9635 64.4435 29.9116 64.5607 29.9199 64.7358C29.9683 65.7458 29.6064 66.774 28.8341 67.5463L18.248 78.1324C16.7945 79.5859 14.438 79.5859 12.9846 78.1324C11.5312 76.679 11.5312 74.3225 12.9846 72.8691L23.5708 62.2829C24.3433 61.5104 25.372 61.1485 26.3823 61.1972C26.5575 61.2056 26.6747 61.1537 26.7343 61.094L30.5442 57.2842L31.3659 58.106Z"
      fill="#43468F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.1602 15.1082C19.1602 12.0594 21.6317 9.58789 24.6804 9.58789H34.8493C35.1703 9.58789 35.4304 9.84805 35.4304 10.169C35.4304 10.4899 35.1703 10.7501 34.8493 10.7501H24.6804C22.2735 10.7501 20.3223 12.7012 20.3223 15.1082C20.3223 15.4291 20.0622 15.6892 19.7412 15.6892C19.4203 15.6892 19.1602 15.4291 19.1602 15.1082ZM39.3527 10.169C39.3527 9.84805 39.6129 9.58789 39.9338 9.58789H45.0183C45.3392 9.58789 45.5993 9.84805 45.5993 10.169C45.5993 10.4899 45.3392 10.7501 45.0183 10.7501H39.9338C39.6129 10.7501 39.3527 10.4899 39.3527 10.169ZM70.2953 46.2323C70.6162 46.2323 70.8764 46.4925 70.8764 46.8134V50.3362C70.8764 50.6571 70.6162 50.9173 70.2953 50.9173C69.9744 50.9173 69.7142 50.6571 69.7142 50.3362V46.8134C69.7142 46.4925 69.9744 46.2323 70.2953 46.2323ZM70.2953 53.2779C70.6162 53.2779 70.8764 53.5381 70.8764 53.859V71.473C70.8764 74.5218 68.4049 76.9933 65.3561 76.9933H55.1872C54.8663 76.9933 54.6061 76.7331 54.6061 76.4122C54.6061 76.0913 54.8663 75.8311 55.1872 75.8311H65.3561C67.763 75.8311 69.7142 73.8799 69.7142 71.473V53.859C69.7142 53.5381 69.9744 53.2779 70.2953 53.2779Z"
      fill="#43468F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.86233 75.3953C10.1833 75.3953 10.4434 75.6554 10.4434 75.9763C10.4434 78.624 12.5897 80.7703 15.2373 80.7703C15.5583 80.7703 15.8184 81.0304 15.8184 81.3513C15.8184 81.6723 15.5583 81.9324 15.2373 81.9324C11.9479 81.9324 9.28125 79.2658 9.28125 75.9763C9.28125 75.6554 9.54141 75.3953 9.86233 75.3953Z"
      fill="#43468F"
    />
  </svg>
)
