import {PaymentFinishPage} from './PaymentFinishPage'
import {PaymentStatusEnum} from 'ac-brand-auth-share'
import React from 'react'

interface PaymentSuccessPageProps {}

const PaymentSuccessPage: React.FC<PaymentSuccessPageProps> = ({}) => {
  return <PaymentFinishPage status={PaymentStatusEnum.succeeded} />
}

export {PaymentSuccessPage}
