import React from 'react'
import {connectComponent, errorActions} from 'ac-brand-share-client'
import {DiscountCategoryDto, StepDto, UserDto} from 'ac-brand-share'
import {CalcFeePage} from './CalcFeePage'

interface ICalcFeePageContainerProps {
  user: UserDto
  steps: StepDto[]
  discountCategories: DiscountCategoryDto[]
  errorActions: any
}

const CalcFeePageContainer: React.FC<ICalcFeePageContainerProps> = ({
  steps,
  discountCategories,
  errorActions,
}): React.ReactElement => {
  return <CalcFeePage steps={steps} discountCategories={discountCategories} errorActions={errorActions} />
}

export default connectComponent(
  (state) => ({
    steps: state.calcFee?.steps,
    discountCategories: state.calcFee?.discountCategories,
    documents: state.legal.documents,
  }),
  (dispatch: any) => ({
    errorActions: errorActions(dispatch),
  }),
  CalcFeePageContainer,
)
