import React from 'react'
import {Footer} from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import {Container} from 'ac-brand-ui'
import {CalcFeeBlockContainer} from './CalcFeeBlockContainer'
import {DiscountCategoryDto, StepDto} from 'ac-brand-share'

interface ICalcFeePageProps {
  steps: StepDto[]
  discountCategories: DiscountCategoryDto[]
  errorActions: any
}

const CalcFeePage: React.FC<ICalcFeePageProps> = ({steps, discountCategories, errorActions}): React.ReactElement => {
  return (
    <main className={'content'}>
      <Header />
      <div className={'background-calc-fee'}>
        <Container>
          <h1 className={'calc-fee-h1 mb-0'}>Калькулятор патентных пошлин</h1>
        </Container>
      </div>
      <Container className={'calc-fee-content'}>
        <CalcFeeBlockContainer steps={steps} discountCategories={discountCategories} errorActions={errorActions} />
      </Container>
      <Footer />
    </main>
  )
}

export {CalcFeePage}
