import React, {memo, ReactNode, useState} from 'react'
import {Container, Divider} from 'ac-brand-ui'
import {FeedbackFormContainer} from 'ac-brand-share-client'
import {WhiteLogoSvg} from './WhiteLogoSvg'
import {SocialTelegramSvg} from './SocialTelegramSvg'
import {CONFIG} from '../../constants'
import {AuthSubsystemIdEnum, LegalDocumentUrl} from 'ac-brand-share'

interface FooterProps {}

const Footer: React.FC<FooterProps> = memo(({}): React.ReactElement => {
  const [isOpenFeedbackForm, setIsOpenFeedbackForm] = useState<boolean>(false)

  const renderFeedbackForm = (): ReactNode => {
    if (isOpenFeedbackForm) {
      return (
        <FeedbackFormContainer
          authSubsystem={AuthSubsystemIdEnum.CRM_EXECUTOR}
          onClose={() => {
            setIsOpenFeedbackForm(false)
          }}
        />
      )
    }
  }

  const renderInfo = (): React.ReactNode => {
    return (
      <div className={'footer-content-info'}>
        <div className={'footer-content-info-logo'}>{WhiteLogoSvg}</div>
        <div className={'footer-content-info-telegram'}>
          <span className={'footer-content-info-telegram-text'}>Мы в соцсетях</span>
          <a className={'footer-content-info-telegram-icon'} href={'https://t.me/CRMPatentOfficial'}>
            {SocialTelegramSvg}
          </a>
        </div>
      </div>
    )
  }

  const renderLinks = (): React.ReactNode => {
    return (
      <div className={'footer-content-links'}>
        <a href={CONFIG.crmSiteWeb + LegalDocumentUrl.PRIVACY} className={'footer-content-link'}>
          Политика конфиденциальности
        </a>{' '}
        <span className={'footer-content-link l-divider'}> | </span>{' '}
        <a href={CONFIG.crmSiteWeb + LegalDocumentUrl.LICENSE} className={'footer-content-link'}>
          Правила использования
        </a>
        <span className={'footer-content-link l-divider'}> | </span>
        <p
          className={'footer-content-link cursor-pointer'}
          onClick={() => {
            setIsOpenFeedbackForm(true)
          }}>
          Написать в поддержку
        </p>
      </div>
    )
  }

  const renderDate = (): React.ReactNode => {
    return (
      <div className={'footer-content-date'}>
        <p className={'mb-0'}>© {new Date().getFullYear()} CRMPATENT. Все права защищены.</p>
      </div>
    )
  }

  return (
    <>
      <div className={'footer-site'}>
        <Container>
          <div className={'footer-content'}>
            {renderInfo()}
            {renderLinks()}
            <Divider className={'footer-content-divider'} />
            {renderDate()}
          </div>
        </Container>
      </div>
      {renderFeedbackForm()}
    </>
  )
})

export {Footer}
