import React from 'react'
import {createStore} from 'redux'
import {createReducers} from 'ac-brand-share-client'
import legalReducers from './redux/legal/reducers'
import calcFeeReducers from './redux/calcFee/reducers'
import App from './App'
import MainLayout from './MainLayout'
import '../scss/style.scss'

declare var URL_PREFIX: string

declare var __STORE__: any

const store = createStore(createReducers({legal: legalReducers, calcFee: calcFeeReducers}), __STORE__)

const Spa: React.FC = ({}): React.ReactElement => {
  return (
    <App store={store} urlPrefix={URL_PREFIX}>
      <MainLayout />
    </App>
  )
}

export default Spa
