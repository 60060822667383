import React, {useCallback, useMemo, useState} from 'react'
import {Button, Checkbox, Container, Input, InputPhone} from 'ac-brand-ui'
import {CONFIG} from '../../../constants'
import {AuthSubsystemIdEnum, regExp, testPhoneYup} from 'ac-brand-share'
import {MailSuccessSvg} from './svg/MailSuccessSvg'
import {apiPost, FormLegalLinks} from 'ac-brand-share-client'

interface ContactFormProps {
  contactFormRef: any
}

interface ContactFormType {
  name: string
  email: string
  phoneNumber: string
  isConfirm: boolean
}

const ContactForm: React.FC<ContactFormProps> = ({contactFormRef}): React.ReactElement => {
  const [isSent, setSent] = useState<boolean>(false)
  const [form, setForm] = useState<ContactFormType>({
    name: null,
    email: null,
    phoneNumber: '7',
    isConfirm: false,
  })

  const onChangeForm = useCallback(
    (key: 'name' | 'email' | 'phoneNumber' | 'isConfirm', value: string | boolean) => {
      form[key as string] = value
      setForm({...form})
    },
    [form],
  )

  const isSubmitDisabled = useCallback(() => {
    const {phoneNumber, email, isConfirm, name} = form
    if (!isConfirm || isSent) {
      return true
    }
    if (!testPhoneYup(phoneNumber)) {
      return true
    }
    if (email) {
      return !regExp.checkMail.test(email)
    }
    return !name || !email
  }, [form, isSent])

  const onSend = useCallback(async (): Promise<void> => {
    setSent(true)
    await apiPost(CONFIG.crmUrlApi + '/support/send-contacts', form)
  }, [form])

  const renderCardForm: React.ReactElement = useMemo(() => {
    if (isSent) {
      return (
        <div className={'contact-form-content'}>
          <div className={'d-flex flex-column align-items-center'}>
            <div className={'d-flex mb-x4'}>{MailSuccessSvg}</div>
            <p className={'sent-success-text'}>Письмо успешно отправлено!</p>
          </div>
        </div>
      )
    }
    return (
      <div className={'contact-form-content'}>
        <Input
          type={'text'}
          value={form.name}
          onChange={(value) => {
            onChangeForm('name', value)
          }}
          placeholder={'Введите имя'}
          label={'Имя'}
          size={'l'}
          required
        />
        <Input
          id={'email'}
          type={'text'}
          name={'email'}
          value={form.email}
          label={'Email'}
          placeholder={'Введите email'}
          valid={!!form.email && !regExp.checkMail.test(form.email) ? false : undefined}
          feedback={!!form.email && !regExp.checkMail.test(form.email) ? 'Введите корректный email' : undefined}
          required
          size={'l'}
          onChange={(value: string) => {
            onChangeForm('email', value)
          }}
        />

        <InputPhone
          value={form.phoneNumber}
          size={'l'}
          onChange={(value) => {
            onChangeForm('phoneNumber', value)
          }}
          valid={!testPhoneYup(form.phoneNumber.length) ? false : undefined}
          feedback={
            form.phoneNumber.length > 2 && form.phoneNumber.length !== 16
              ? 'Введите корректный номер телефона'
              : undefined
          }
          className={'mb-x8'}
          label={'Номер телефона'}
          required
        />
        <Checkbox
          id={'proofLicense'}
          name={'proofLicense'}
          type={'checkbox'}
          className={'mb-x8'}
          checked={!!form.isConfirm}
          controlPosition={'start'}
          label={
            <span>
              Соглашаюсь <FormLegalLinks authSubsystem={AuthSubsystemIdEnum.CRM_EXECUTOR} />
            </span>
          }
          onChange={(checked) => {
            onChangeForm('isConfirm', checked)
          }}
        />
        <Button
          color={'primary'}
          size={'md'}
          disabled={isSubmitDisabled()}
          onClick={async (e) => {
            e.preventDefault()
            await onSend()
          }}>
          Отправить
        </Button>
      </div>
    )
  }, [form.email, form.isConfirm, form.name, form.phoneNumber, isSent, isSubmitDisabled, onChangeForm, onSend])

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <section className={'contact-form'} ref={contactFormRef} id={'landing-contact-form'}>
        <Container>
          <div className={'d-flex flex-column'}>
            <h2 className={'contact-form-h2'}>
              Еще думаете?
              <br />
              Просто попробуйте.
            </h2>
            <p className={'contact-form-description'}>
              Оставьте свои контактные данные.
              <br />
              Мы свяжемся с вами, чтобы предоставить доступ к платформе.
            </p>
            {renderCardForm}
            <div className={'d-flex flex-column align-items-center'}>
              <a href={CONFIG.crmSiteWeb + '/tariff'} className={'sec-subtitle1 pt-link-color info '}>
                Цены на тарифы
              </a>
            </div>
          </div>
        </Container>
      </section>
    )
  }, [renderCardForm, contactFormRef])

  return <>{renderContent}</>
}

export {ContactForm}
