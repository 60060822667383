import React, {memo, ReactNode, useEffect, useRef, useState} from 'react'
import {TariffPageManager} from './services/TariffPageManager'
import {TariffPageState} from './TariffPageContainer'
import {Alert, Button, Col, Container, Loading, Modal, ModalBody, Row} from 'ac-brand-ui'
import {TariffCard} from './components/TariffCard'
import {
  AuthSubsystemIdEnum,
  getItemByKey,
  PaymentSystemEnum,
  SUPPORT_MAIL_MENU_NAME,
  TariffDto,
  YandexPaymentDto,
} from 'ac-brand-share'
import {mdiEmail} from '@mdi/js'
import {ConfirmDemoModal} from './components/ConfirmDemoModal'
import Header from '../../components/header/Header'
import {Footer} from '../../components/footer/Footer'
import {FeedbackFormContainer, FormLegalLinks} from 'ac-brand-share-client'
import {CONFIG} from '../../constants'
import TariffPlanOptions from './components/TariffPlanOptions'
import TariffSelectFormModal from './components/TariffSelectFormModal'
import {InvoiceBoxPaymentDto} from 'ac-brand-auth-share'

interface TariffPageProps {
  state: TariffPageState
  tariffPageManager: TariffPageManager
}

const TariffPage: React.FC<TariffPageProps> = memo(({state, tariffPageManager}) => {
  const PAYMENT_FORM_ID = 'yandexModal'

  const {
    tariffsAndPeriods,
    selectedPeriodId,
    selectedTariffId,
    externalPayment,
    error,
    isHasDemo,
    isOpenConfirmDemoModal,
    wallet,
    isAutoPaymentBonus,
    enabledPaymentTypes,
  } = state

  const [isOpenFeedbackForm, setIsOpenFeedbackForm] = useState<boolean>(false)

  const [headerHeight, setHeaderHeight] = useState<number>(48)

  const containerRef = useRef<HTMLDivElement>()
  const [width, setWidth] = useState<number>(null)

  useEffect(() => {
    if (!width) {
      setTimeout(() => {
        setWidth(containerRef.current?.scrollWidth)
      }, 500)
    }
  }, [containerRef.current?.scrollWidth])

  useEffect(() => {
    if (externalPayment) {
      const {paymentSystem} = externalPayment
      if (paymentSystem === PaymentSystemEnum.YOOKASSA) {
        if ((window as any).YooMoneyCheckoutWidget) {
          const {payment} = externalPayment
          const yandexPayment = payment as YandexPaymentDto
          const checkout = new (window as any).YooMoneyCheckoutWidget({
            confirmation_token: yandexPayment.confirmation.confirmation_token,
            return_url: CONFIG.crmSiteWeb + '/payment/finish/' + yandexPayment.id,
            error_callback: (error) => {
              //Обработка ошибок инициализации
              console.error(error)
              tariffPageManager.onChangeState('error', error)
            },
          })
          checkout.render(PAYMENT_FORM_ID)
        } else {
          console.error('Error loading YooMoney widget')
        }
      } else if (paymentSystem === PaymentSystemEnum.INVOICEBOX) {
        console.log('redirect to page', (externalPayment.payment as InvoiceBoxPaymentDto).paymentUrl)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalPayment])

  const renderFeedbackForm = (): ReactNode => {
    if (isOpenFeedbackForm) {
      return (
        <FeedbackFormContainer
          authSubsystem={AuthSubsystemIdEnum.CRM_EXECUTOR}
          onClose={() => {
            setIsOpenFeedbackForm(false)
          }}
        />
      )
    }
  }

  const renderPeriods = (): ReactNode => {
    const {periods} = tariffsAndPeriods
    const items = periods.map((item) => (
      <Button
        size={'sm'}
        className={'mr-x4'}
        key={item.subscriptionPeriodId}
        style={selectedPeriodId !== item.subscriptionPeriodId ? 'text' : null}
        onClick={() => {
          tariffPageManager.onChangeState('selectedPeriodId', item.subscriptionPeriodId)
        }}>
        {item.name}
      </Button>
    ))
    return (
      <div className={'subscription-periods mb-x8'}>
        <p className={'subtitle2 caption-show-price'}>Показать цены за:</p>
        <div>{items}</div>
      </div>
    )
  }

  const renderPaymentModal = (): React.ReactNode => {
    if (externalPayment) {
      const {paymentSystem} = externalPayment
      if (paymentSystem === PaymentSystemEnum.YOOKASSA) {
        return (
          <Modal
            isOpen={true}
            onClose={() => {
              tariffPageManager.onChangeState('externalPayment', null)
            }}>
            <ModalBody>
              <div id={PAYMENT_FORM_ID} />
              {error && (
                <Alert
                  message={
                    <p className={'mb-0'}>
                      Произошла ошибка при инициализации платежа. Пожалуйста, повторите попытку позже или
                      <span className={'pt-link info'} onClick={() => {}}>
                        свяжитесь с нами
                      </span>
                    </p>
                  }
                  color={'danger'}
                />
              )}
              {!(window as any).YooMoneyCheckoutWidget && (
                <Alert
                  message={
                    <p className={'mb-0'}>
                      Произошла ошибка загрузки виджета оплаты. Пожалуйста, проверьте доступность https://yookassa.ru
                      или
                      <span className={'pt-link info'} onClick={() => {}}>
                        свяжитесь с нами
                      </span>
                    </p>
                  }
                  color={'danger'}
                />
              )}
            </ModalBody>
          </Modal>
        )
      } else if (paymentSystem === PaymentSystemEnum.INVOICEBOX) {
        return (
          <Modal
            isOpen={true}
            onClose={() => {
              tariffPageManager.onChangeState('externalPayment', null)
            }}>
            <ModalBody>
              <p>Пожалуйста, подождите...</p>
              <Loading />
            </ModalBody>
          </Modal>
        )
      }
    } else {
      return null
    }
  }

  const renderTariffSelectModal = (): React.ReactNode => {
    const {isOpenTariffSelectModal, tariffsAndPeriods, selectedPeriodId, selectedTariffId, wallet, invoice} = state
    if (isOpenTariffSelectModal && selectedPeriodId && selectedTariffId) {
      const filteredTariffs = (tariffsAndPeriods.tariffs || []).filter((t) => !t.isPersonal)
      return (
        <TariffSelectFormModal
          tariffs={filteredTariffs}
          periods={state.tariffsAndPeriods.periods}
          selectedPeriod={state.tariffsAndPeriods.periods.find(
            (p) => p.subscriptionPeriodId === state.selectedPeriodId,
          )}
          selectedTariff={state.tariffsAndPeriods.tariffs.find((t) => t.tariffId === state.selectedTariffId)}
          subjects={state.subjects || []}
          onSetPeriod={(newSubscriptionPeriod) => {
            tariffPageManager.onChangeState('selectedPeriodId', newSubscriptionPeriod.subscriptionPeriodId)
          }}
          onSetTariff={(newTariff) => {
            tariffPageManager.onChangeState('selectedTariffId', newTariff.tariffId)
          }}
          onClose={() => {
            tariffPageManager.closePaymentModal()
          }}
          onSubmit={async (data) => {
            await tariffPageManager.onSubmitTariffSelectFormModal(data)
          }}
          isSendingRequest={false}
          invoiceResponse={invoice}
          wallet={wallet}
          enabledPaymentTypes={enabledPaymentTypes}
        />
      )
    } else {
      return <></>
    }
  }

  const renderTariffsPlanAndPrice = (): React.ReactNode => {
    const {tariffs, periods} = tariffsAndPeriods
    const monthPeriodId: string = periods.find((item) => item.countMonth === 1)?.subscriptionPeriodId
    const items = tariffs.map((item: TariffDto) => (
      <TariffCard
        key={item.tariffId}
        name={item.name}
        description={item.description}
        fullPriceMonth={item.prices[selectedPeriodId]?.price}
        priceYear={item.prices[monthPeriodId]?.price * 12}
        salePrice={item.prices[selectedPeriodId]?.salePrice}
        onPay={async () => {
          tariffPageManager.openPaymentModal(item.tariffId)
        }}
        wallet={wallet}
        isAutoPaymentBonus={isAutoPaymentBonus}
        setIsAutoPaymentBonus={(value) => {
          tariffPageManager.onChangeState('isAutoPaymentBonus', value)
        }}
      />
    ))
    if (isHasDemo === false) {
      items.unshift(
        <TariffCard
          key={'tariff-is-demo'}
          isDemoTariff
          name={'Пробный'}
          description={'14 дневный период с неограниченными функциями'}
          fullPriceMonth={'Бесплатно'}
          commentPrice={'первые 14 дней с неограниченными функциями'}
          onPay={async () => {
            tariffPageManager.onChangeState('isOpenConfirmDemoModal', true)
          }}
          wallet={wallet}
        />,
      )
    }

    return (
      <div className={'spacer-blocks'} id={'tariff-block'}>
        <h1 className={'tariff-title'}>Тарифные планы и цены</h1>
        {renderPeriods()}
        <Row className={'justify-content-center'}>{items}</Row>
        <div className={'d-flex align-items-center justify-content-center'}>
          <p className={'subtitle2 text-gray-700 font-weight-normal mb-0 text-center'}>
            Активируя или оплачивая тарифный план, вы соглашаетесь{' '}
            <FormLegalLinks authSubsystem={AuthSubsystemIdEnum.CRM_EXECUTOR} />.
          </p>
        </div>
      </div>
    )
  }

  const renderBlockFAQ = (): React.ReactNode => {
    return (
      <div className={'tariff-block-faq'}>
        <h2 className={'tariff-title spacer-questions-block'}>Частые вопросы</h2>
        {/*<div className={'spacer-questions-block'}>*/}
        {/*  <h3 className={'text-gray-800 mb-x4'}>В чем отличие тарифных планов?</h3>*/}
        {/*  <h4 className={'text-gray-900 font-weight-normal'}>*/}
        {/*    Ответ на вопрос. Условия будут меняться, поэтому текст лучше в базе хранить*/}
        {/*  </h4>*/}
        {/*</div>*/}
        <div className={'spacer-questions-block'}>
          <h3 className={'text-gray-800 mb-x4'}>
            Если я решил прервать подписку через какое то время, но средства уже списались и доступ уже предоставили,
            могу я отказаться от подписки и вернуть деньги?
          </h3>
          <h4 className={'text-gray-900 font-weight-normal'}>Мы вернем деньги за неиспользованный период подписки.</h4>
        </div>
        <div>
          <h3 className={'text-gray-800 mb-x4'}>Остались еще вопросы?</h3>
          {/*<h4 className={'text-gray-900 font-weight-normal'}>*/}
          {/*  Напишите письмо в службу поддержки с темой «Вопросы о тарифах».*/}
          {/*</h4>*/}
          <Button
            size={'sm'}
            iconPath={mdiEmail}
            style={'outline'}
            onClick={() => {
              setIsOpenFeedbackForm(true)
            }}>
            {SUPPORT_MAIL_MENU_NAME}
          </Button>
        </div>
      </div>
    )
  }

  const renderTariffPlanOptions = (): React.ReactNode => {
    if (tariffsAndPeriods) {
      const selectedPeriod = getItemByKey('subscriptionPeriodId', tariffsAndPeriods.periods || [], selectedPeriodId)
      const selectedTariff = getItemByKey('tariffId', tariffsAndPeriods.tariffs || [], selectedTariffId)
      return (
        <TariffPlanOptions
          tariffs={tariffsAndPeriods.tariffs}
          selectedPeriod={selectedPeriod}
          selectedTariff={selectedTariff}
          onSetTariff={(newTariff) => {
            tariffPageManager.onChangeState('selectedTariffId', newTariff.tariffId)
          }}
          headerHeight={headerHeight}
          personalFeatureCount={{}}
          onSetPersonalFeatureCount={() => {}}
          onClickButton={async (newTariff) => {
            tariffPageManager.openPaymentModal(newTariff.tariffId)
          }}
        />
      )
    } else {
      return <></>
    }
  }

  if (tariffsAndPeriods) {
    return (
      <>
        <Header
          onChangeHeaderHeight={(height: number) => {
            setHeaderHeight(height)
          }}
        />
        <div className={'background-tariff spacer-blocks'} ref={containerRef}>
          <Container>
            <Row>
              <Col md={5}>
                <h1 className={'subscription-price-caption mb-x6'}>Сколько это стоит?</h1>
                {isHasDemo === false ? (
                  <p className={'description-tariff'}>
                    Вы можете бесплатно начать использовать систему прямо сейчас в рамках пробного периода: 14 дней
                  </p>
                ) : (
                  <p className={'description-tariff'}>
                    Вы можете ознакомиться с опциями и стоимостью тарифных планов и выбрать подходящий
                  </p>
                )}
                <div className={'d-flex align-items-center'}>
                  {isHasDemo === false && (
                    <Button
                      style={'inversion'}
                      isReduceInMobile
                      className={'mr-x6'}
                      onClick={() => {
                        tariffPageManager.onChangeState('isOpenConfirmDemoModal', true)
                      }}>
                      Пробный период
                    </Button>
                  )}
                  <Button
                    style={'outline'}
                    color={'white'}
                    isReduceInMobile
                    onClick={(e) => {
                      let tariffsBlock = document.getElementById('tariff-block')
                      e.preventDefault() // Stop Page Reloading
                      tariffsBlock && tariffsBlock.scrollIntoView({behavior: 'smooth'})
                    }}>
                    Посмотреть тарифы
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Container>{renderTariffsPlanAndPrice()}</Container>
        {renderTariffPlanOptions()}
        <Container>
          {renderBlockFAQ()}
          {renderTariffSelectModal()}
          {renderPaymentModal()}
        </Container>
        {renderFeedbackForm()}
        <Footer />
        {isOpenConfirmDemoModal && (
          <ConfirmDemoModal
            onClose={() => {
              tariffPageManager.onChangeState('isOpenConfirmDemoModal', false)
            }}
            onSubmit={async () => {
              await tariffPageManager.createDemo()
            }}
          />
        )}
      </>
    )
  } else {
    return <Loading />
  }
})

export {TariffPage}
