import React, {useMemo} from 'react'
import {Col, Container} from 'ac-brand-ui'

interface SuitableForWhomProps {}

const LIST: string[] = [
  'Патентные поверенные',
  'Юристы',
  'Маркетологи',
  'PR агенства',
  'Компании по сертификации',
  'Технопарки',
  'Университеты',
  'Школы',
  'Юридические компании',
  'Оценщики',
  'Правообладатели (физ. лица)',
  'Правообладатели (юр. лица)',
  'Авторы и физ. лица',
]

const SuitableForWhom: React.FC<SuitableForWhomProps> = ({}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    let items: React.ReactElement[] = []
    for (let item of LIST) {
      items.push(
        <div key={item} className={'suitable-for-whom-card'}>
          <span className={'suitable-for-whom-card-text'}>{item}</span>
        </div>,
      )
    }
    return (
      <section className={'suitable-for-whom'}>
        <Container>
          <h2 className={'suitable-for-whom-h2 mb-x18'}>Кому подойдет?</h2>
          <div className={'suitable-for-whom-list'}>{items}</div>
        </Container>
      </section>
    )
  }, [])

  return <>{renderContent}</>
}

export {SuitableForWhom}
