import React, {useMemo} from 'react'
import {Button, Col, Container, Icon, Row} from 'ac-brand-ui'
import {MainImage} from './svg/MainImage'
import {mdiArrowDown} from '@mdi/js'

interface MainInfoProps {
  onTry: (e: any) => void
  onGetMore: (e: any) => void
}

const MainInfo: React.FC<MainInfoProps> = ({onTry, onGetMore}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <section className={'main-info'}>
        <Container>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className={'main-info-image'}>{MainImage}</div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className={'main-info-content'}>
                <h2 className={'main-info-content-title'}>CRMPATENT</h2>
                <p className={'main-info-content-description'}>
                  Автоматизация работы патентных поверенных. Просто, удобно, онлайн.
                </p>
                <div className={'main-info-content-buttons'}>
                  <Button className={'main-info-button'} isCircleButton size={'lg'} onClick={onTry}>
                    Попробовать
                  </Button>
                  <div
                    className={'d-flex flex-row align-items-center pt-link-color secondary mb-0'}
                    onClick={onGetMore}>
                    <p className={'h3 font-weight-normal pt-link-color secondary mb-0'}>Узнать больше</p>
                    <Icon path={mdiArrowDown} size={'20px'} className={'ml-x1'} />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }, [onTry, onGetMore])

  return <>{renderContent}</>
}

export {MainInfo}
