import React from 'react'

export const Logo: React.ReactNode = (
  <svg width="140" height="32" viewBox="0 0 140 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 1.77783C8.14528 1.77783 1.77777 8.14534 1.77777 16.0001C1.77777 22.014 5.51045 27.156 10.7854 29.2359V23.0199C10.7854 22.9728 10.8042 22.9275 10.8375 22.8942L16.8114 16.9253C16.9235 16.8134 17.1149 16.8927 17.1149 17.051V19.5255C17.1149 19.7219 17.2741 19.8811 17.4704 19.8811H23.4837C26.8253 19.8811 28.5158 18.7027 28.5158 16.2673C28.5158 13.7926 26.7467 12.4963 23.4837 12.4963H17.4704C17.2741 12.4963 17.1149 12.6555 17.1149 12.8519V15.4654C17.1149 15.6238 16.9235 15.7031 16.8114 15.5912L10.8375 9.6223C10.8042 9.58895 10.7854 9.54371 10.7854 9.49654V8.13825C10.7854 7.94188 10.9446 7.78269 11.141 7.78269H23.8375C25.3263 7.78269 26.6812 7.92354 27.8925 8.19718C25.3504 4.33054 20.9733 1.77783 16 1.77783ZM11.2528 19.8011C11.1398 19.9134 10.9474 19.8318 10.9497 19.6724L11.0491 12.7842C11.0513 12.6273 11.2409 12.5501 11.3521 12.6607L14.6404 15.9293C14.7802 16.0684 14.7802 16.2947 14.6404 16.4337L11.2528 19.8011Z"
      fill="#43468F"
    />
    <path
      d="M27.5004 24.3691C26.6497 24.4929 25.7296 24.5554 24.7417 24.5554H17.4704C17.2741 24.5554 17.1149 24.7146 17.1149 24.911V30.1792C21.3811 29.8484 25.1195 27.6352 27.5004 24.3691Z"
      fill="#43468F"
    />
    <path
      d="M45.852 21.668C47.56 21.668 48.974 21.052 49.898 19.918L48.442 18.574C47.784 19.344 46.958 19.736 45.978 19.736C44.144 19.736 42.842 18.448 42.842 16.6C42.842 14.752 44.144 13.464 45.978 13.464C46.958 13.464 47.784 13.856 48.442 14.612L49.898 13.268C48.974 12.148 47.56 11.532 45.866 11.532C42.814 11.532 40.546 13.646 40.546 16.6C40.546 19.554 42.814 21.668 45.852 21.668ZM61.1096 21.5L58.9116 18.35C60.1856 17.804 60.9276 16.726 60.9276 15.256C60.9276 13.058 59.2896 11.7 56.6716 11.7H52.4296V21.5H54.6976V18.77H56.6716H56.7836L58.6736 21.5H61.1096ZM58.6316 15.256C58.6316 16.32 57.9316 16.964 56.5456 16.964H54.6976V13.548H56.5456C57.9316 13.548 58.6316 14.178 58.6316 15.256ZM74.7705 21.5L74.7425 11.7H72.8805L69.2685 17.79L65.6005 11.7H63.7245V21.5H65.8525V15.746L68.7225 20.464H69.7445L72.6285 15.62L72.6425 21.5H74.7705ZM82.3376 11.7H78.0956V21.5H80.3636V18.798H82.3376C84.9556 18.798 86.5936 17.44 86.5936 15.256C86.5936 13.058 84.9556 11.7 82.3376 11.7ZM82.2116 16.95H80.3636V13.548H82.2116C83.5976 13.548 84.2976 14.178 84.2976 15.256C84.2976 16.32 83.5976 16.95 82.2116 16.95ZM96.0426 21.5H98.4226L94.0406 11.7H91.8006L87.4326 21.5H89.7566L90.6246 19.4H95.1746L96.0426 21.5ZM91.3526 17.678L92.9066 13.926L94.4606 17.678H91.3526ZM101.977 21.5H104.245V13.548H107.381V11.7H98.8412V13.548H101.977V21.5ZM111.855 19.68V17.412H116.405V15.648H111.855V13.52H117.007V11.7H109.601V21.5H117.189V19.68H111.855ZM126.742 11.7V17.65L121.87 11.7H119.994V21.5H122.234V15.55L127.12 21.5H128.982V11.7H126.742ZM134.331 21.5H136.599V13.548H139.735V11.7H131.195V13.548H134.331V21.5Z"
      fill="#43468F"
    />
  </svg>
)
