import React, {useCallback, useMemo} from 'react'
import {IpoSvg} from './svg/IpoSvg'
import {EmptySvg} from './svg/EmptySvg'
import {LinkApplicationSvg} from './svg/LinkApplicationSvg'
import {Col, Container, Row} from 'ac-brand-ui'
import {OrganizationSvg} from './svg/OrganizationSvg'
import {TaskSvg} from './svg/TaskSvg'
import {RecommendationsSvg} from './svg/RecommendationsSvg'

interface WeAlsoHaveProps {}

interface WeAlsoHaveType {
  id: string
  iconReactElement: React.ReactNode
  title: string
  description: string
}

const LIST: WeAlsoHaveType[] = [
  {
    id: '1',
    iconReactElement: IpoSvg,
    title: 'ОИС',
    description:
      'Загрузите все ваши ОИС с помощью шаблона и получайте актуальную информацию об изменениях прямо на платформе.',
  },
  {
    id: '2',
    iconReactElement: LinkApplicationSvg,
    title: 'Связь заявок',
    description:
      'Установите связь между заявками, сохраняя историю работы с клиентом. Сравнивайте информацию между связанными заявками.',
  },
  {
    id: '3',
    iconReactElement: OrganizationSvg,
    title: 'Контрагенты',
    description:
      'Загрузите своих контрагентов заполнив только ИНН, а дополнительная информация будет получена платформой из ФНС.',
  },
  {
    id: '4',
    iconReactElement: TaskSvg,
    title: 'Планирование задач',
    description:
      'Ставьте задачи, чтобы не упустить ничего важного. Оставляйте комментарии, прикрепляйте файлы, планируйте сроки и отслеживайте фактическое время выполнения задачи.',
  },
  {
    id: '5',
    iconReactElement: EmptySvg,
    title: 'Глобальный поиск',
    description:
      'Введите поисковый запрос, чтобы получить информацию по всем объектам платформы (ОИС, заявки, документы, задачи, заказчики, группы компаний). Уточните результат поиска с помощью фильтров.',
  },
  {
    id: '6',
    iconReactElement: RecommendationsSvg,
    title: 'Рекомендации и уведомления',
    description:
      'Получите готовый алгоритм действий по работе с заявкой.  Уведомления напомнят о необходимости продления охранных документов.',
  },
]

const WeAlsoHave: React.FC<WeAlsoHaveProps> = ({}): React.ReactElement => {
  const renderItems = useCallback((items: WeAlsoHaveType[]) => {
    let tsxItems: React.ReactElement[] = []
    for (let i = 0; i < items.length; i++) {
      let item: WeAlsoHaveType = items[i]
      tsxItems.push(
        <Col key={item.id} className={'we-also-have-card-margin'} xs={12} sm={12} md={4} lg={4} xl={4}>
          <div className={'we-also-have-card'}>
            <div className={'we-also-have-card-img'}>{item.iconReactElement}</div>
            <p className={'we-also-have-card-title'}>{item.title}</p>
            <p className={'we-also-have-card-description'}>{item.description}</p>
          </div>
        </Col>,
      )
    }
    return <Row>{tsxItems}</Row>
  }, [])

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <section className={'we-also-have'}>
        <Container>
          <h2 className={'we-also-have-h2 mb-x18'}>Еще у нас есть</h2>
          {renderItems(LIST)}
        </Container>
      </section>
    )
  }, [renderItems])

  return <>{renderContent}</>
}

export {WeAlsoHave}
