import React, {useState} from 'react'
import {FeeApi} from './services/FeeApi'
import {DiscountCategoryDto, DiscountCategoryEnum, FeeCalcResultDto, StepDto, StepItemDto} from 'ac-brand-share'
import {CalcFeeBlock} from 'ac-brand-share-client'

interface ICalcFeeBlockContainerProps {
  steps: StepDto[]
  discountCategories: DiscountCategoryDto[]
  className?: string
  headerClassName?: string
  contentClassName?: string
  errorActions: any
}

const CalcFeeBlockContainer: React.FC<ICalcFeeBlockContainerProps> = ({
  steps,
  discountCategories,
  className,
  headerClassName,
  contentClassName,
}): React.ReactElement => {
  const [discountCategory, setDiscountCategory] = useState<DiscountCategoryEnum | undefined>(undefined)
  const [itemNameValue, setItemNameValue] = useState<number | string | undefined>(undefined)
  const [secondaryItemNameValue, setSecondaryItemNameValue] = useState<number | undefined>(undefined)
  const [isElectronicSubmissionValue, setIsElectronicSubmissionValue] = useState<boolean | undefined>(undefined)
  const [isOriginalCertificatePriceValue, setIsOriginalCertificatePriceValue] = useState<boolean | undefined>(undefined)
  const [originalCertificateIsSeveralValue, setOriginalCertificateIsSeveralValue] = useState<number | undefined>(
    undefined,
  )

  const [isProcessingCalc, setIsProcessingCalc] = useState<boolean>(false)
  const [isNotRelevant, setIsNotRelevant] = useState<boolean>(false)
  const [feeCalcResult, setFeeCalcResult] = useState<FeeCalcResultDto | undefined>(undefined)

  const [activeStepIds, setActiveStepIds] = useState<string[]>([])
  const [activeStepItemIds, setActiveStepItemIds] = useState<string[]>([])
  const [activeFeeTypeId, setActiveFeeTypeId] = useState<string | undefined>(undefined)

  const onFeeCalc = async (
    isElectronicSubmissionValueProp: boolean,
    isOriginalCertificatePriceValueProp: boolean,
    originalCertificateIsSeveralValueProp: number,
    isNotCalc: boolean,
    stepItem: StepItemDto,
  ): Promise<void> => {
    if (activeFeeTypeId) {
      if (isNotCalc) {
        const feeTypeInfo = stepItem.feeTypeInfo
        const isElectronicSubmission: boolean = isElectronicSubmissionValueProp
        const isOriginalCertificatePrice: boolean = isOriginalCertificatePriceValueProp
        let resultPrice: number = feeTypeInfo.fixPrice

        if (isElectronicSubmission) {
          resultPrice = resultPrice * 0.7
        }
        if (isOriginalCertificatePrice) {
          resultPrice = resultPrice + (feeTypeInfo.originalCertificatePrice || 0)
        }
        if (resultPrice) {
          resultPrice = Math.round(resultPrice)
        }
        setFeeCalcResult({
          feeTypeId: stepItem.feeTypeId,
          code: feeTypeInfo.code,
          resultPrice: resultPrice,
        })
      } else {
        setIsProcessingCalc(true)
        try {
          const res = await new FeeApi().feeCalc({
            feeTypeId: activeFeeTypeId,
            discountCategory: discountCategory || undefined,
            itemNameValue: itemNameValue || undefined,
            secondaryItemNameValue: secondaryItemNameValue || undefined,
            isElectronicSubmissionValue:
              isElectronicSubmissionValueProp !== undefined
                ? isElectronicSubmissionValueProp
                : isElectronicSubmissionValue,
            isOriginalCertificatePriceValue:
              isOriginalCertificatePriceValueProp !== undefined
                ? isOriginalCertificatePriceValueProp
                : isOriginalCertificatePriceValue,
            originalCertificateIsSeveralValue:
              originalCertificateIsSeveralValueProp !== undefined
                ? originalCertificateIsSeveralValueProp
                : originalCertificateIsSeveralValue,
          })

          setFeeCalcResult({...res})
        } catch (e) {
          setFeeCalcResult(undefined)
        } finally {
          setIsProcessingCalc(false)
          setIsNotRelevant(false)
        }
      }
    }
  }

  const clearInputs = (): void => {
    setDiscountCategory(undefined)
    setItemNameValue(undefined)
    setSecondaryItemNameValue(undefined)
    setIsElectronicSubmissionValue(undefined)
    setIsOriginalCertificatePriceValue(undefined)
    setOriginalCertificateIsSeveralValue(undefined)
    setIsNotRelevant(false)
    setFeeCalcResult(undefined)
    setActiveFeeTypeId(undefined)
  }

  const onStepItem = (stepItem: StepItemDto) => {
    let newActiveStepItemIds = [...activeStepItemIds]
    newActiveStepItemIds.push(stepItem.stepItemId)
    setActiveStepItemIds(newActiveStepItemIds)

    if (stepItem.nextStepId) {
      let newActiveStepIds = [...activeStepIds]
      newActiveStepIds.push(stepItem.nextStepId)
      setActiveStepIds(newActiveStepIds)
    } else if (stepItem.feeTypeId) {
      const feeTypeInfo = stepItem.feeTypeInfo
      const isShowInputs =
        feeTypeInfo?.itemName?.length || feeTypeInfo?.secondaryItemName?.length || feeTypeInfo?.isShowDiscountCategory
      if (!isShowInputs) {
        let resultPrice = feeTypeInfo.fixPrice
        if (resultPrice) {
          resultPrice = Math.round(resultPrice)
        }
        setFeeCalcResult({
          feeTypeId: stepItem.feeTypeId,
          code: feeTypeInfo.code,
          resultPrice: resultPrice,
        })
      }
      setActiveFeeTypeId(stepItem.feeTypeId)
    }
  }

  const onCloseStepItem = (stepItem: StepItemDto) => {
    let newActiveStepItemIds = [...activeStepItemIds]
    const index = newActiveStepItemIds.findIndex((si) => si === stepItem.stepItemId)
    if (index !== -1) {
      newActiveStepItemIds.splice(index)
      setActiveStepItemIds(newActiveStepItemIds)

      let newActiveStepIds = [...activeStepIds]
      newActiveStepIds.splice(index)
      setActiveStepIds(newActiveStepIds)
    }
    clearInputs()
  }

  const onChangeValue = async (
    key:
      | 'itemNameValue'
      | 'secondaryItemNameValue'
      | 'discountCategory'
      | 'isElectronicSubmissionValue'
      | 'originalCertificateIsSeveralValue'
      | 'isOriginalCertificatePriceValue',
    value: any,
    isShowInputs: boolean | undefined,
    lastStepItem: StepItemDto | undefined,
  ): Promise<void> => {
    if (key === 'itemNameValue') {
      setItemNameValue(value as string)
      if (feeCalcResult) {
        setIsNotRelevant(true)
      }
    } else if (key === 'secondaryItemNameValue') {
      setSecondaryItemNameValue(value)
      if (feeCalcResult) {
        setIsNotRelevant(true)
      }
    } else if (key === 'discountCategory') {
      setDiscountCategory(value as DiscountCategoryEnum)
      if (feeCalcResult) {
        setIsNotRelevant(true)
      }
    } else if (key === 'isElectronicSubmissionValue') {
      setIsElectronicSubmissionValue(value)
      if (feeCalcResult) {
        await onFeeCalc(value, isOriginalCertificatePriceValue, undefined, !isShowInputs, lastStepItem)
      }
    } else if (key === 'originalCertificateIsSeveralValue') {
      setOriginalCertificateIsSeveralValue(value)
      if (feeCalcResult) {
        await onFeeCalc(undefined, undefined, value !== undefined ? value : 0, false, undefined)
      }
    } else if (key === 'isOriginalCertificatePriceValue') {
      setIsOriginalCertificatePriceValue(value)
      if (feeCalcResult) {
        await onFeeCalc(isElectronicSubmissionValue, value, undefined, !isShowInputs, lastStepItem)
      }
    }
  }

  return (
    <CalcFeeBlock
      activeStepIds={activeStepIds}
      activeStepItemIds={activeStepItemIds}
      steps={steps}
      discountCategories={discountCategories}
      isNotRelevant={isNotRelevant}
      feeCalcResult={feeCalcResult}
      isProcessingCalc={isProcessingCalc}
      discountCategory={discountCategory}
      itemNameValue={itemNameValue}
      secondaryItemNameValue={secondaryItemNameValue}
      isElectronicSubmissionValue={isElectronicSubmissionValue}
      isOriginalCertificatePriceValue={isOriginalCertificatePriceValue}
      originalCertificateIsSeveralValue={originalCertificateIsSeveralValue}
      onStepItem={onStepItem}
      onCloseStepItem={onCloseStepItem}
      onChangeValue={onChangeValue}
      onFeeCalc={onFeeCalc}
      className={className}
      headerClassName={headerClassName}
      contentClassName={contentClassName}
    />
  )
}

export {CalcFeeBlockContainer}
