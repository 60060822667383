import React, {useEffect, useState} from 'react'
import {FormLegalLinks, ModalWrapper, RUB} from 'ac-brand-share-client'
import {Alert, Checkbox, Divider, FormGroupLabel, Select} from 'ac-brand-ui'
import {
  AuthSubsystemIdEnum,
  formatDate,
  InvoiceDto,
  InvoiceStatusEnum,
  numberWithSpaces,
  PaymentPeriodDto,
  ResponseError,
  round,
  SubscriptionPeriodDto,
  TariffDto,
  WalletDto,
} from 'ac-brand-share'
import classNames from 'classnames'
import moment from 'moment'
import {PaymentTypeEnum, SubjectDto} from 'ac-brand-auth-share'
import {SelectIsAllLkSubject} from '../../../components/select/SelectIsAllLkSubject'

interface MenuCategoryCreateFormModalProps {
  tariffs: TariffDto[]
  periods: SubscriptionPeriodDto[]
  selectedPeriod: SubscriptionPeriodDto
  selectedTariff: TariffDto
  onSetTariff: (newTariff: TariffDto) => void
  onSetPeriod: (newPeriod: SubscriptionPeriodDto) => void
  onClose: () => void
  onSubmit: (data: any) => Promise<void>
  isSendingRequest: boolean
  subjects: SubjectDto[]
  invoiceResponse: InvoiceDto | undefined
  wallet: WalletDto
  enabledPaymentTypes: PaymentTypeEnum[]
}

const TariffSelectFormModal: React.FC<MenuCategoryCreateFormModalProps> = ({
  tariffs,
  periods,
  selectedPeriod,
  selectedTariff,
  onSetTariff,
  onSetPeriod,
  onClose,
  onSubmit,
  isSendingRequest,
  subjects,
  invoiceResponse,
  wallet,
  enabledPaymentTypes,
}): React.ReactElement => {
  const {price} = selectedTariff.prices[selectedPeriod.subscriptionPeriodId]
  const isFree: boolean = price === 0

  const [isAutoPaymentBonus, setIsAutoPaymentBonus] = useState<boolean>(false)
  const [isOnlinePayment, setIsOnlinePayment] = useState<boolean>(false)
  const [subjectId, setSubjectId] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (subjects?.length === 1) {
      setSubjectId(subjects[0].subjectId)
    }
  }, [subjects])

  const isSubmitDisabled = (): boolean => {
    return isSendingRequest || (!isFree && (subjects || []).length === 0)
  }

  const renderSelectPeriod = (): React.ReactNode => {
    if (isFree) {
      return <></>
    } else {
      const items: React.ReactNode[] = []
      for (let i = 0; i < periods.length; i++) {
        const period = periods[i]
        items.push(
          <Checkbox
            key={period.subscriptionPeriodId}
            id={period.subscriptionPeriodId}
            name={period.subscriptionPeriodId}
            type={'radio'}
            checked={period.subscriptionPeriodId === selectedPeriod?.subscriptionPeriodId}
            label={period.name}
            className={classNames(i === 0 ? 'mb-x4 mt-x2' : i === periods.length - 1 ? 'mb-0' : 'mb-x4')}
            onChange={() => {
              if (period.subscriptionPeriodId !== selectedPeriod?.subscriptionPeriodId) {
                onSetPeriod(period)
              }
            }}
          />,
        )
      }
      return (
        <FormGroupLabel
          valid={undefined}
          feedback={undefined}
          label={'Период оплаты'}
          className={'d-flex flex-column align-items-start mb-x6'}>
          {items}
        </FormGroupLabel>
      )
    }
  }

  const renderPaymentType = (): React.ReactNode => {
    if (isFree) {
      return <></>
    } else {
      const canOnline = enabledPaymentTypes?.includes(PaymentTypeEnum.ONLINE)
      const canInvoice = enabledPaymentTypes?.includes(PaymentTypeEnum.INVOICE)
      return (
        <FormGroupLabel
          valid={undefined}
          feedback={undefined}
          label={'Способ оплаты'}
          className={'d-flex flex-column align-items-start mb-x6'}>
          {canOnline && (
            <Checkbox
              key={'onlinePayment'}
              id={'onlinePayment'}
              name={'onlinePayment'}
              type={'radio'}
              checked={isOnlinePayment}
              label={'Онлайн оплата'}
              className={'mb-0 mt-x2'}
              onChange={() => {
                setIsOnlinePayment(true)
              }}
            />
          )}
          {canInvoice && (subjects || []).length > 0 && (
            <Checkbox
              key={'invoicePayment'}
              id={'invoicePayment'}
              name={'5'}
              type={'radio'}
              checked={!isOnlinePayment}
              label={'Оплата по счету'}
              className={'mt-x4 mb-0'}
              onChange={() => {
                setIsOnlinePayment(false)
              }}
            />
          )}
        </FormGroupLabel>
      )
    }
  }

  const renderIsAutoPaymentBonus = (): React.ReactNode => {
    if (!isFree && wallet && (wallet?.amount || 0) > 0) {
      return (
        <div className={'d-flex flex-column align-items-start'}>
          <Checkbox
            key={'isAutoPayment'}
            id={'isAutoPayment'}
            name={'isAutoPayment'}
            type={'checkbox'}
            checked={isAutoPaymentBonus}
            className={'mb-x6'}
            label={`Списать ${wallet.amount?.toString()} бонусов`}
            onChange={(value: boolean) => {
              setIsAutoPaymentBonus(value)
            }}
          />
        </div>
      )
    } else {
      return <></>
    }
  }

  const renderPrice = (): React.ReactNode => {
    const {salePrice, price} = selectedTariff.prices[selectedPeriod.subscriptionPeriodId]
    const isFree: boolean = price === 0
    const newPrice = price
    let newSalePrice
    if (!isFree) {
      newSalePrice = salePrice > 0 ? salePrice : price
      if (wallet && isAutoPaymentBonus) {
        newSalePrice = newSalePrice - (wallet.amount || 0)
      }
    } else {
      newSalePrice = 0
    }

    let intervalText: string = `${selectedTariff.name} с ${formatDate(moment().toDate())} до ${formatDate(
      moment().add(selectedPeriod.countMonth, 'months').toDate(),
    )}`
    if (isFree) {
      intervalText = `${selectedTariff.name} с ${formatDate(moment().toDate())}`
    }

    //После покупки тарифа перености в профиль после покупки бесплатного тарифа переносит в профиль
    return (
      <>
        <p className={'tariff-select-interval'}>{intervalText}</p>
        <p className={'tariff-select-price-wrapper'}>
          <span className={'tariff-select-price'}>
            {numberWithSpaces(newSalePrice)} {RUB}
          </span>
          {newSalePrice < newPrice && (
            <span className={'tariff-select-sale'}>
              {numberWithSpaces(newPrice)}
              {RUB}
            </span>
          )}
        </p>
        <p className={'caption text-gray-700 font-weight-normal mb-0'}>
          Нажимая на кнопку «{isFree ? 'Активировать' : isOnlinePayment ? 'Купить' : 'Выставить счет'}» вы соглашаетесь{' '}
          <FormLegalLinks authSubsystem={AuthSubsystemIdEnum.CRM_EXECUTOR} isLinkColor />.
        </p>
      </>
    )
  }

  return (
    <ModalWrapper
      header={invoiceResponse ? 'Спасибо, что вы с нами' : 'Активация тарифа'}
      onClose={() => {
        onClose()
      }}
      isScrollBody
      closeText={'Отменить'}
      onSubmit={
        invoiceResponse
          ? async () => {
              onClose()
            }
          : async () => {
              if (!isOnlinePayment && !subjectId) {
                throw new ResponseError(409, 'Заполните поле "Личный кабинет организации"')
              }

              const type: PaymentTypeEnum = isOnlinePayment ? PaymentTypeEnum.ONLINE : PaymentTypeEnum.INVOICE
              const isUseBonus: boolean = isAutoPaymentBonus && ((wallet && wallet.amount) || 0) > 0
              let summa: number = selectedTariff.prices[selectedPeriod.subscriptionPeriodId].salePrice
                ? selectedTariff.prices[selectedPeriod.subscriptionPeriodId].salePrice
                : selectedTariff.prices[selectedPeriod.subscriptionPeriodId].price
              let bonusAmount: number = 0
              if (isUseBonus && wallet) {
                if (wallet.maxPercentPayment) {
                  bonusAmount = round((summa * wallet.maxPercentPayment) / 100)
                }
                if (bonusAmount > (wallet.amount || 0)) {
                  bonusAmount = wallet.amount || 0
                }
                summa = summa - bonusAmount
              }

              const payment: PaymentPeriodDto = {
                tariffId: selectedTariff.tariffId,
                subscriptionPeriodId: selectedPeriod.subscriptionPeriodId,
                summa,
                bonusAmount,
              }

              const invoice: InvoiceDto = {
                invoiceId: undefined as any,
                subjectId: subjectId as string,
                tariffId: selectedTariff.tariffId,
                subscriptionPeriodId: selectedPeriod.subscriptionPeriodId,
                summa,
                bonusAmount,
                status: InvoiceStatusEnum.WAITING_CREATE,
              }

              await onSubmit({
                payment,
                invoice,
                isFree,
                type: type,
              })
            }
      }
      size={'sm'}
      isCloseButton={!invoiceResponse}
      submitText={invoiceResponse ? 'Понятно' : isFree ? 'Активировать' : isOnlinePayment ? 'Купить' : 'Выставить счет'}
      isShowIsLoading
      isSubmitDisabled={isSubmitDisabled()}>
      {invoiceResponse ? (
        <div className={'d-flex flex-column w-100'}>
          <img
            src={'/site/img/tariffPage/timer.svg'}
            width={'100%'}
            height={'auto'}
            alt={'Таймер'}
            className={'mb-x6'}
          />
          <p className={'sec-subtitle1 text-gray-900 mb-0'}>
            Счет на оплату будет выставлен на {invoiceResponse?.subject?.shortName} и отправлен вам на электронную почту
            в течение 24 часов.
          </p>
        </div>
      ) : (
        <div className={'tariff-select'}>
          <Select
            key={'tariff'}
            id={'tariff'}
            name={'tariff'}
            isSquareSelect
            disabled={false}
            value={selectedTariff?.tariffId}
            label={'Тариф'}
            onChange={(value) => {
              const newTariff = tariffs.find((t) => t.tariffId === value)
              if (newTariff) {
                onSetTariff(newTariff)
              }
            }}
            className={'w-100 mb-x6'}
            options={
              tariffs &&
              tariffs.map((t) => ({
                value: t.tariffId,
                label: t.name,
              }))
            }
          />
          {renderSelectPeriod()}
          {(subjects || []).length > 0 && renderPaymentType()}
          {/*{renderIsAutoPaymentBonus()}*/}

          {!isFree && (subjects || []).length === 0 && (
            <Alert
              color={'warning'}
              message={'Для выставления счета необходимо завести свою организацию на платформе.'}
            />
          )}
          <Divider className={'w-100 mb-x6'} />
          {!isFree && !isOnlinePayment && (subjects || []).length > 0 ? (
            <>
              <SelectIsAllLkSubject
                setSubjectId={setSubjectId}
                subjects={subjects}
                isAllLkSubject={(subjects || []).length !== 1}
                subjectId={subjectId}
              />
              <Divider className={'w-100 mb-x6'} />
            </>
          ) : (
            <></>
          )}
          {renderPrice()}
        </div>
      )}
    </ModalWrapper>
  )
}

export default TariffSelectFormModal
